import React, { useMemo, useCallback, useEffect } from "react";
import { observer } from 'mobx-react-lite'
import { MenuItem, Button, Spinner, Icon } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import deviceProtocolsStore from 'stores/deviceProtocolsStore'

// searches for an element that has the specified id, or returns the first element if no id is specified
function findActiveItemById(items, id) {
  if (id === null) return items[0];
  return items?.find(item => item.id === id);
}

function renderDeviceProtocol (deviceProtocol, { handleClick, modifiers }) {
  if (!modifiers.matchesPredicate) return null;
  
  return (
    <MenuItem key={deviceProtocol.id}
      active={modifiers.active}
      onClick={handleClick}
      text={deviceProtocol.displayName}
    />
  );
};

function filterDeviceProtocol(query, deviceProtocol) {
  const _query = query.toLowerCase();
  return deviceProtocol.lowerCaseName.includes(_query);
};

const DeviceProtocolSelect = observer(props => {
  return (
    <DeviceProtocolSelectView
      fetchDeviceProtocols={deviceProtocolsStore.fetchDeviceProtocols}
      allDeviceProtocols={deviceProtocolsStore.allDeviceProtocols}
      isFetched={deviceProtocolsStore.isFetched}
      isLoading={deviceProtocolsStore.isLoading}
      error={deviceProtocolsStore.error}
      {...props}
    />
  )
})

function DeviceProtocolSelectView({ value, onChange, allDeviceProtocols, isFetched, isLoading, error, fetchDeviceProtocols }) {

  // loads the list of all devices protocols, if not already loaded
  useEffect(() => {
    if (isFetched || isLoading) return;
     fetchDeviceProtocols().then(() => {
       const activeItem = findActiveItemById(allDeviceProtocols, value);
       if (onChange) onChange(activeItem.id);
     });
  });

  const activeItem = useMemo(() => findActiveItemById(allDeviceProtocols, value), [allDeviceProtocols, value]);

  const onItemSelect = useCallback(item => onChange && onChange(item.id), [onChange]);

  return (
    <Select className="select"
      filterable={true}
      itemRenderer={renderDeviceProtocol}
      itemPredicate={filterDeviceProtocol}
      onItemSelect={onItemSelect}
      items={allDeviceProtocols}
      activeItem={activeItem}
    >
      <Button disabled={Boolean(isLoading || error)} rightIcon="caret-down">

        {isLoading && <div className="spinner-container">
          <Spinner size={20} /> идет загрузка
        </div>}

        {error && <div className="error-container">
          <Icon icon="error" /> ошибка
        </div>}

        {activeItem?.displayName}

      </Button>
    </Select>
  );
};

export default DeviceProtocolSelect;
