import { makeAutoObservable, runInAction } from "mobx"
import { showToasterD, showToasterS } from 'tools/AppToaster';
import {
    createControlDevice,
    getControlDevice as _getControlDevice,
    deleteControlDevice as _deleteControlDevice,
    putControlDevice as _putControlDevice,
    replaceControlDevice as _replaceControlDevice
} from 'api/appAPI';

const setErrorById = (arr, targetId) => {
    const targetIndex = arr.findIndex(item => item.id === targetId)
    const newArr = [
        ...arr.slice(0, targetIndex - 1),
        {
            ...arr[targetIndex],
            id: targetId,
            error: true
        },
        ...arr.slice(targetIndex + 1),
    ]
    return newArr
}

export class ControlDevicesStore {
    constructor() {
        makeAutoObservable(this)
    }

    loadedControlDevices = []
    isLoading = false
    error = null

    addControlDevice = async (data, refreshObject, handleClose) => {
        runInAction(() => this.isLoading = true)
        try {
            await createControlDevice(data);
            showToasterS('Устройство успешно добавлено');
            refreshObject();
            handleClose();
        } catch (error) {
            runInAction(() => this.error = error)
            showToasterD('Не удалось создать контроллер');
        }
        runInAction(() => this.isLoading = false)
    };

    putControlDevice = async (id, data, refreshObject, handleClose) => {
        runInAction(() => this.isLoading = true)
        try {
            await _putControlDevice(id, data);
            showToasterS('Устройство успешно обновлено');
            refreshObject();
            handleClose && handleClose();
        } catch (error) {
            runInAction(() => this.error = error)
            showToasterD('Не удалось обновить контроллер');
        }
        runInAction(() => this.isLoading = false)
    };


    // получение данных о контроллере
    getControlDevice = async (id, fields) => {
        runInAction(() => this.isLoading = true)
        try {
            const res = await _getControlDevice(id, fields);
            const updateIndex = this.loadedControlDevices.findIndex(item => item.id === res.id)
            if (updateIndex > -1) {
                runInAction(() => {
                    this.loadedControlDevices = [
                        ...this.loadedControlDevices.slice(0, updateIndex),
                        res,
                        ...this.loadedControlDevices.slice(updateIndex + 1),
                    ]
                })
            } else {
                runInAction(() => {
                    this.loadedControlDevices = [...this.loadedControlDevices, res]
                })
            }
            return res
        }
        catch (error) {
            runInAction(() =>{
                this.error = error
                this.loadedControlDevices = setErrorById(this.loadedControlDevices, id)
            })
            showToasterD('Не удалось загрузить данные');
        }
        runInAction(() => this.isLoading = false)
    };

    // удаление контроллера
    deleteControlDevice = async (id, refreshObject, handleClose) => {
        runInAction(() => this.isLoading = true)
        try {
            await _deleteControlDevice(id);
            await refreshObject();
            handleClose();
            showToasterS('Контроллер удалён');
        }
        catch (error) {
            runInAction(() => this.error = error)
            showToasterD('Не удалось удалить контроллер');
        }
        runInAction(() => this.isLoading = false)
    };

    // замена контроллера
    replaceControlDevice = async (id, data, refreshObject, handleClose) => {
        runInAction(() => this.isLoading = true)
        try {
            await _replaceControlDevice(id, data);
            await refreshObject();
            handleClose();
            showToasterS('Контроллер заменён');
        }
        catch {
            showToasterD('Не удалось заменить контроллер');
        }
        runInAction(() => this.isLoading = false)
    };
}

export default new ControlDevicesStore()