import React from 'react';
import { divIcon } from 'leaflet'
import { renderToString } from 'react-dom/server'
import { Icon } from '@blueprintjs/core'

import Cloudy from 'assets/img/wheatherMarkers/Cloudy.png';
import LightRain from 'assets/img/wheatherMarkers/LightRain.png';
import LightSnow from 'assets/img/wheatherMarkers/LightSnow.png';
import PartlySunny from 'assets/img/wheatherMarkers/PartlySunny.png';
import Rain from 'assets/img/wheatherMarkers/Rain.png';
import Thunder from 'assets/img/wheatherMarkers/Thunder.png';
import Snow from 'assets/img/wheatherMarkers/Snow.png';
import Sunny from 'assets/img/wheatherMarkers/Sunny.png';

export const getWeatherMarkerByType = (type, temp) => {
  switch(type) {
    case 0:
      return divIcon({
        className: '',
        iconSize: [40, 40],
        html: renderToString((
          <div style={{ textAlign: 'center' }}>
            <Icon icon="help" intent="warning" />
            <div className="span-temp">{temp} &#176;C</div>
          </div>
        ))
      })
    case 1:
      return divIcon({
        className: '',
        iconSize: [40, 40],
        html: renderToString((
          <div style={{ textAlign: 'center' }}>
            <img src={Sunny} alt="sunny" />
            <div className="span-temp">{temp} &#176;C</div>
          </div>
        ))
      })
    case 2:
      return divIcon({
        className: '',
        iconSize: [40, 40],
        html: renderToString((
          <div style={{ textAlign: 'center' }}>
            <img src={PartlySunny} alt="partly sunny" />
            <div className="span-temp">{temp} &#176;C</div>
          </div>
        ))
      })
    case 3:
      return divIcon({
        className: '',
        iconSize: [40, 40],
        html: renderToString((
          <div style={{ textAlign: 'center' }}>
            <img src={Cloudy} alt="cloudy" />
            <div className="span-temp">{temp} &#176;C</div>
          </div>
        ))
      })
    case 4:
      return divIcon({
        className: '',
        iconSize: [40, 40],
        html: renderToString((
          <div style={{ textAlign: 'center' }}>
            <img src={Snow} alt="snow" />
            <div className="span-temp">{temp} &#176;C</div>
          </div>
        ))
      })
    case 5:
      return divIcon({
        className: '',
        iconSize: [40, 40],
        html: renderToString((
          <div style={{ textAlign: 'center' }}>
            <img src={LightSnow} alt="light snow" />
            <div className="span-temp">{temp} &#176;C</div>
          </div>
        ))
      })
    case 6:
      return divIcon({
        className: '',
        iconSize: [40, 40],
        html: renderToString((
          <div style={{ textAlign: 'center' }}>
            <img src={Rain} alt="rain" />
            <div className="span-temp">{temp} &#176;C</div>
          </div>
        ))
      })
    case 7:
      return divIcon({
        className: '',
        iconSize: [40, 40],
        html: renderToString((
          <div style={{ textAlign: 'center' }}>
            <img src={LightRain} alt="light rain" />
            <div className="span-temp">{temp} &#176;C</div>
          </div>
        ))
      })
    case 8:
      return divIcon({
        className: '',
        iconSize: [40, 40],
        html: renderToString((
          <div style={{ textAlign: 'center' }}>
            <img src={Thunder} alt="thunder" />
            <div className="span-temp">{temp} &#176;C</div>
          </div>
        ))
      })

    default:
      return null

  }
}