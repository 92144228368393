import React, { useState, useEffect, useCallback } from 'react';
import { Slider, Icon } from '@blueprintjs/core';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';

// Import tools
import { onMouseDown } from 'tools/dragNDrop';
import { appendChildToMap } from "../tools/appendChild";

const EditPopup = ({
 handleSetTogglePopup,
 className,
 map,
 color,
 editPopupRef,
 setColor,
 setActiveCreateZone,
 setCreateCompletePopup,
 positions,
 editedPopupIsHidden,
}) => {
  // Boolean value changes the state of display of SketchPicker
  const [activeColorPicker, setActiveColorPicker] = useState(false);

  // Destructuring prop color
  const { r, g, b, a } = color;

  // The handler changes color of zone
  const handleSetColor = value => {
    if(typeof value === 'object') {
      setColor(color => ({
        ...color,
        r: value.rgb.r,
        g: value.rgb.g,
        b: value.rgb.b
      }));
    } else if(typeof value === 'number') {
      setColor(color => ({
        ...color,
        a: +value.toFixed(2),
      }));
    }
  };

  // The handler that enable events on map with using useCallback
  const mapHandlingEnable = useCallback(() => {
    map.dragging.enable();
    map.doubleClickZoom.enable();
    setActiveCreateZone(false);
  }, [map.dragging, map.doubleClickZoom, setActiveCreateZone])

  // The handler that disable events on map with using useCallback
  const mapHandlingDisable = useCallback(() => {
    map.dragging.disable();
    map.doubleClickZoom.disable()
    setActiveCreateZone(true);
  }, [map.dragging, map.doubleClickZoom, setActiveCreateZone])

  // The handler event by mouse over of EditPopup
  const onMouseEnter = () => mapHandlingDisable();

  // The handler event by mouse out of EditPopup
  const onMouseLeave = () => mapHandlingEnable();

  useEffect(() => {
    const ref = editPopupRef.current;

    return () => {
      appendChildToMap(map._container, ref);
      mapHandlingEnable();
    }
  }, [mapHandlingEnable, map, editPopupRef]);

  // The function of going to the finish of creating a zone
  const completeZone = () => {
    appendChildToMap(map._container, editPopupRef.current);
    setCreateCompletePopup(true);
    map.fitBounds(positions);
  }

  return (
    <div
      className={className ? `edit-popup ${className}` : "edit-popup"}
      data-draggable={true}
      style={{ display: editedPopupIsHidden ? 'none' : 'block' }}
      ref={editPopupRef}
      onMouseDown={onMouseDown}
      onMouseEnter={map ? onMouseEnter : null}
      onMouseLeave={map ? onMouseLeave : null}
      onDragStart={() => false}
    >
      <button
        className="edit-popup-close-btn"
        onClick={handleSetTogglePopup}
      >
        <Icon icon="cross" />
      </button>
      <div className="edit-zone">
        <h2>Добавление зоны</h2>
        <div
          className="choice-color"
          style={{ background: `rgba(${r}, ${g}, ${b}, ${a})` }}
          onClick={() => setActiveColorPicker(toggle => !toggle)}
        >
        </div>
        {
          activeColorPicker &&
          <SketchPicker
            color={color}
            onChangeComplete={handleSetColor}
          />
        }
        <Slider
          min={0}
          max={1}
          value={color.a}
          onChange={handleSetColor}
          labelStepSize={0.2}
          stepSize={0.01}
        />
      </div>

      <button
        className="complete"
        onClick={completeZone}
        disabled={positions[1] ? false : true}
      >
        Завершить
      </button>
    </div>
  );
}

EditPopup.propTypes = {
  handleSetTogglePopup: PropTypes.func,
  className: PropTypes.string,
  map: PropTypes.object,
  color: PropTypes.object,
  setColor: PropTypes.func,
  setActiveCreateZone: PropTypes.func,
  setCreateCompletePopup: PropTypes.func,
  positions: PropTypes.array.isRequired,
}

EditPopup.defaultProps = {
  className: '',
  color: {
    r: 240,
    g: 128,
    b: 128,
    a: 0.6
  }
}

export default EditPopup;
