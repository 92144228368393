import React, { useState, useEffect} from 'react';
import { getZones } from 'api/zonesService'

import { createZone, createNameMarkerZone } from 'components/AppMap/tools/createZone'

const Zones = ({ showAllZones, map, zoom }) => {
  const [zones, setZones] = useState([]);
  const [zonesMarkers, setZonesMarkers] = useState(new Set());
  const [zonesNameMarkers, setZonesNameMarkers] = useState(new Set());

  const renderMarkers = () => {
    if(zonesMarkers.size && zonesNameMarkers.size) {
      [...zonesMarkers].forEach(markers => {
        if(markers.zoneMarkerRef) {
          markers.zoneMarkerRef.addTo(map)
        }

        if(markers.circleMarkerRef) {
          markers.circleMarkerRef.addTo(map)
        }
      })
    }
  }

  const deleteMarkers = () => {
    if(zonesMarkers.size && zonesNameMarkers.size) {
      [...zonesMarkers].forEach(markers => {
        if(markers.zoneMarkerRef) {
          map.removeLayer(markers.zoneMarkerRef);
        }

        if(markers.circleMarkerRef) {
          map.removeLayer(markers.circleMarkerRef);
        }
      })
    }
  }

  useEffect(() => {
    (async () => {
      if(showAllZones && zones.length < 1) {
        const zoneFields = 'id,color,states' +
          '[radius,points,width,center,id,dateCreate,dateDelete,type],' +
          'description,name,attributes';

        let zones = await getZones(zoneFields);

        zones = zones.filter(zone => {
          if(zone?.states?.length) {
            zone.states.forEach(state => {
              if(state?.center?.length) {
                if((state.center[1] >= -90 || state.center[1] <= 90) && (state.center[0] >= -180 || state.center[0] <= 180)) {
                  return false
                }
              } else {
                state.points.forEach(point => {
                  if((point[1] >= -90 || point[1] <= 90) && (point[0] >= -180 || point[0] <= 180)) {
                    return false
                  }
                })
              }
            })
          }

          return true;
        });

        zones = zones.map(zone => {
          zone = {
            ...zone,
            states: zone.states.map(state => {
              if(state.center) {
                return {
                  ...state,
                  center: [...state.center].reverse()
                }
              } else {
                return {
                  ...state,
                  points: state.points.map(point => [...point].reverse())

                }
              }
            })
          };

          setZonesMarkers(markers => markers.add(createZone(zone, map, false)));
          setZonesNameMarkers(markers => markers.add(createNameMarkerZone(zone, map, zoom, false)));

          return zone;
        })

        setZones(zones);

        renderMarkers()
      }
    })()


    if(!showAllZones) {
      deleteMarkers();
    } else {
      renderMarkers()
    }
  // eslint-disable-next-line
  }, [showAllZones])


  return <></>;
}

export default Zones;