import React from 'react';
import { MenuItem, Button } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select';
import { useQuery } from 'react-query'

import { getRoles } from 'api/rolesService'
 
const UserRolesSelect = ({ value, onChange }) => {

    const { data, isFetching } = useQuery('rolesList', () => getRoles())
    
    return (
        <Select
            id='role'
            items={isFetching ? [] : data}
            itemRenderer={renderRole}
            noResults={<MenuItem disabled={true} text='No results.' />}
            onItemSelect={onChange}
            loading={isFetching}
        >
            <Button
                text={value?.displayName || 'Выберите роль'}
                rightIcon='caret-down'
            />
        </Select>
    );
}

const renderRole = (item, { handleClick }) => {
    return (
        <MenuItem
            key={item.id}
            label={item.displayName}
            onClick={handleClick}
            shouldDismissPopover={true}
        />
    );
}

export default UserRolesSelect;

