import React, { useState } from 'react';
import { Select } from "@blueprintjs/select";
import { Alert, MenuItem, Button, InputGroup } from "@blueprintjs/core";

import { markersToAdd } from 'constants/mapMarkers';

export const renderMarker = (marker, needTitle = false) => (
  <>
    {
      typeof marker.icon === 'string' ?
      <img src={marker.icon} alt={marker.title} /> : marker.icon
    }
    {
      needTitle &&
      <span className="ml-2">{marker.title}</span>
    }
  </>
)

const AddMarkerPopup = ({ addMarkerPopup, setAddMarkerPopup, setActiveButton, setNewMapDot }) => {
  const [activeMarker, setActiveMarker] = useState(markersToAdd[0]);
  const [text, setText] = useState('');

  const onCancel = () => {
    setAddMarkerPopup(false);
    setActiveButton('');
  }

  const itemRenderer = (marker, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }

    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={marker.type}
        onClick={handleClick}
        text={renderMarker(marker, true)}
      />
    );
  };
  const filterMarker = (query, marker) => String(marker.title.toLowerCase()).includes(query.toLowerCase());

  const onConfirm = () => {
    setNewMapDot(prevState => ({
      ...prevState,
      text,
      marker: activeMarker,
    }));
    setAddMarkerPopup(false);
    setText('');
    setActiveMarker(markersToAdd[0])
  }

  return (
    <Alert
      isOpen={addMarkerPopup}
      cancelButtonText="Отмена"
      canOutsideClickCancel={true}
      onCancel={onCancel}
      onConfirm={onConfirm}
    >
      <h4>Добавление новой точки</h4>

      <h5 className="mt-2">Название</h5>
      <InputGroup
        value={text}
        placeholder="Введите название изображения"
        autoFocus={addMarkerPopup}
        fill={true}
        onChange={e => setText(e.target.value)}
      />

      <h5 className="mt-2">Тип</h5>
      <Select 
        activeItem={activeMarker}
        items={markersToAdd}
        itemRenderer={itemRenderer}
        onItemSelect={item => setActiveMarker(item)}
        itemPredicate={filterMarker}
        inputProps={{
          placeholder: 'Поиск...'
        }}
      >
        <Button 
          fill={true} 
          rightIcon="caret-down"
        >
          {renderMarker(activeMarker, true)}
        </Button>
      </Select>
    </Alert>
  )
}

export default AddMarkerPopup;
