import React from 'react';

import { Styled } from './SelectTileLayer.styled'

const SelectTileLayer = ({
  activeTileLayer,
  tileLayers,
  handleSetActiveTileLayer
}) => (
  <Styled.SelectTileLayer>
    <div className="bp3-select modifier">
      <select
        value={activeTileLayer.title}
        onChange={({ target: { value } }) => handleSetActiveTileLayer(value)}
      >
        {tileLayers.map(tilelayer =>
          <option
            key={tilelayer.title}
            value={tilelayer.title}
          >
            {tilelayer.title}
          </option>
        )}
      </select>
    </div>
  </Styled.SelectTileLayer>
)

export default SelectTileLayer;
