import React from 'react'

import { Styled } from './MobileObjectGroups.styled'

export const MobileObjectGroups = ({ groups }) => {

    if (groups?.length <= 0) {
        return <p>Нет групп</p>
    }

    return (
        <Styled.List>
            {groups.map(item => (
                <Styled.Item key={item.id}>
                    {item.displayName}
                </Styled.Item>
            ))}
        </Styled.List>
    )
}

export default MobileObjectGroups