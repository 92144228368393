import axios from 'axios'
import { showToasterD } from 'tools/AppToaster'

export const getMobjectModels = async (fields = '') => {
    try {
        const res = await axios.get(`/api/mobject-models${fields}`);
        return res.data.result
    } catch (e) {
        console.error('Ошибка при получении списка моделей: ', e)
        showToasterD('Ошибка при получении списка моделей')
    }
};

export const postMobjectModels = async data => {
    try {
        await axios.post(`/api/mobject-models`, data);
    } catch (e) {
        console.error('Ошибка при добавлении модели: ', e)
        showToasterD('Ошибка при добавлении модели')
    }
    
}

export const putMobjectModels = async (id, data) => {
    try {
        await axios.put(`/api/mobject-models/${id}`, data);
    } catch (e) {
        console.error('Ошибка при изменении модели: ', e)
        showToasterD('Ошибка при изменении модели')
    }
}

export const deleteMobjectModels = async id => {
    try {
        await axios.delete(`/api/mobject-models/${id}`);
    } catch (e) {
        console.error('Ошибка при удалении модели: ', e)
        showToasterD('Ошибка при удалении модели')
    }
}
