import axios from 'axios'
import { showToasterD } from 'tools/AppToaster'

// Получение списка предприятий
export const getEnterprises = async (fields = '') => {
    try {
        const res = await axios.get(`/api/enterprises${fields}`);
        return res.data.result
    } catch (e) {
        console.error('Ошибка при загрузке списка предприятий: ', e)
        showToasterD('Ошибка при загрузке списка предприятий')
    }
};

// Получение информации о предприятии по его идентификатору
export const getEnterprise = async (id) => {
    try {
        const res = await axios.get(`/api/enterprises/${id}`);
        return res.data.result
    } catch (e) {
        console.error('Ошибка при загрузке информации о предприятии: ', e)
        showToasterD('Ошибка при загрузке информации о предприятии')
    }
};

// Получение списка дочерних предприятий по идентификатору родителя
export const getEnterprisesByParentId = async (id) => {
    try {
        const res = await axios.get(`/api/enterprises/${id}/childs`);
        return res.data.result
    } catch (e) {
        console.error('Ошибка при загрузке списка предприятий: ', e)
        showToasterD('Ошибка при загрузке списка предприятий')
    }
};

// Получение списка мобильных объектов предприятия по его идентификатору
export const getEnterpriseMobjectsByEnterpriseId = async (id) => {
    try {
        const res = await axios.get(`/api/enterprises/${id}/mobjects`);
        return res.data.result
    } catch (e) {
        console.error('Ошибка при загрузке списка мобильных объектов предприятия: ', e)
        showToasterD('Ошибка при загрузке списка мобильных объектов предприятия')
    }
};

// Получение списка пользователей предприятия по его идентификатору
export const getEnterpriseUsersByEnterpriseId = async (id) => {
    try {
        const res = await axios.get(`/api/enterprises/${id}/users`);
        return res.data.result
    } catch (e) {
        console.error('Ошибка при загрузке списка пользователей предприятий: ', e)
        showToasterD('Ошибка при загрузке списка пользователей предприятий')
    }
};

// Получение списка предприятий сгруппированных по родителю
export const getEnterprisesGroupedByParentId = async () => {
    try {
        const res = await axios.get('/api/enterprises?fields=id,name,parent[id,name]&groupBy=parent.id');
        return res.data.result
    } catch (e) {
        console.error('Ошибка при загрузке списка предприятий: ', e)
        showToasterD('Ошибка при загрузке списка предприятий')
    }
}

// Добавление нового предприятия
export const postEnterprise = async body => {
    try {
        await axios.post('/api/enterprises', body);
    } catch (e) {
        console.error('Ошибка при добавлении предприятия: ', e)
        showToasterD('Ошибка при добавлении предприятия')
    }
}

// Изменение предприятия по его идентификатору
export const putEnterprise = async (id, body) => {
    try {
        await axios.put(`/api/enterprises/${id}`, body);
    } catch (e) {
        console.error('Ошибка при обновлении предприятия: ', e)
        showToasterD('Ошибка при обновлении предприятия')
    }
}

// Удаление предприятия по его идентификатору
export const deleteEnterprise = async (id) => {
    try {
        await axios.delete(`/api/enterprises/${id}`);
    } catch (e) {
        console.error('Ошибка при удалении предприятия: ', e)
        showToasterD('Ошибка при удалении предприятия')
    }
}

// Закрепление за предприятием мобильных объектов по его идентификатору
export const postEnterpriseMobjects = async (id, objectsIds) => {
    try {
        await axios.post(`/api/enterprises/${id}/mobjects`, { toAdd: objectsIds });
    } catch (e) {
        console.error('Ошибка при прикреплении мобильного объекта к предприятию: ', e)
        showToasterD('Ошибка при прикреплении мобильного объекта к предприятию')
    }
}

// Открепление от предприятия мобильных объектов по его идентификатору
export const removeEnterpriseMobjects = async (id, objectsIds) => {
    try {
        await axios.post(`/api/enterprises/${id}/mobjects`, { toRemove: objectsIds });
    } catch (e) {
        console.error('Ошибка при прикреплении мобильного объекта к предприятию: ', e)
        showToasterD('Ошибка при прикреплении мобильного объекта к предприятию')
    }
}