import React, { useMemo } from 'react'
import { Column, Table, Cell, SelectionModes } from "@blueprintjs/table";
import styles from "./Сonverters.module.scss";
import Chart from './Chart'

const TarTable = ({ rows }) => {

    const analogValuesRenderer = rowIndex => (
        <Cell>{rows[rowIndex]?.analogValue}</Cell>
    )

    const tarValuesRenderer = rowIndex => (
        <Cell>{rows[rowIndex]?.tarValue}</Cell>
    )

    const chartXArray = useMemo(() => {
        return rows.map(item => item.analogValue)
    }, [rows])

    const chartYArray = useMemo(() => {
        return rows.map(item => item.tarValue)
    }, [rows])

    return (
        <>
            <Table
                className={styles.table}
                numRows={rows.length}
                enableMultipleSelection={false}
                defaultRowHeight={30}
                selectionModes={SelectionModes.NONE}
                fill
            >
                <Column name="Показания датчика" cellRenderer={analogValuesRenderer} />
                <Column name="Значения по тарировке" cellRenderer={tarValuesRenderer} />
            </Table>
            <Chart
                title='График тарировочной таблицы'
                xArray={chartXArray}
                yArray={chartYArray}
            />
        </>
    )
}

export default TarTable;