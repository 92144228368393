import React, { useEffect } from 'react';
import { Spinner } from '@blueprintjs/core';
import { observer } from 'mobx-react-lite'

// ws
import { socketConnection } from 'tools/socketManager';
import { ON_NAV_OBJECT_CHANGED } from 'constants/socketManager';

// store
import { zonesListStore } from 'stores/objectsObserverListStore'
import SocketStore from 'stores/socketStore'

// global components
import VirtualList from 'components/VirtualList';

// constants 
import WSChannelsNames from 'constants/WSChannelsNames'

// local components
import MobileObjectBreadcrumbs from '../components/Breadcrumbs';

// styles
import { Styled } from '../LeftPanel.styled'

const MobileObjectsList = observer(() => {
  const {
    isLoading,
    isErrorred,
    list,
    dataLength,
    breadCrumbs,
    loadStep,
    searchedObjectIndex,
    loadMObjects,
    loadMoreRows,
    selectedObjectIds,
    setSelectedObjectIds,
    handleRowDblClick,
    handleBreadcrumbClick,
    changeListItem
  } = zonesListStore

  const updatedItem = SocketStore.getChannelData(ON_NAV_OBJECT_CHANGED, WSChannelsNames.Zone);

  useEffect(() => {
    loadMObjects();
  }, [loadMObjects, loadStep]);

  useEffect(() => {
    if(updatedItem) changeListItem(updatedItem)
  }, [updatedItem, changeListItem]);

  useEffect(() => {
    if (!socketConnection) return

    if(!SocketStore.getChannelData(ON_NAV_OBJECT_CHANGED, WSChannelsNames.Zone)) {
      SocketStore.addSocketChannel(ON_NAV_OBJECT_CHANGED, WSChannelsNames.Zone, '')
    }

    return () => SocketStore.removeSocketChannel(ON_NAV_OBJECT_CHANGED, WSChannelsNames.Zone, '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnection]);

  return (
    <>
      <MobileObjectBreadcrumbs
        breadcrumbs={breadCrumbs}
        onBreadcrumbClick={handleBreadcrumbClick}
      />
      <Styled.ListContainer>
        {isLoading ? (
          <Spinner />
        ) : isErrorred ? (
          <p>Произошла ошибка, попробуйте перезагрузить страницу</p>
        ) : (
          <VirtualList
            loadMoreRows={loadMoreRows}
            list={list}
            needSelectObj={false}
            loadStep={loadStep}
            rowCount={dataLength}
            onRowDblClick={handleRowDblClick}
            selectedObjectIds={selectedObjectIds}
            setSelectedObjectIds={setSelectedObjectIds}
            searchedObjectIndex={searchedObjectIndex}
          />
        )}
      </Styled.ListContainer>
    </>
  );
});

export default MobileObjectsList;
