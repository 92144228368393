import React from 'react';
import { Button, ControlGroup, InputGroup } from "@blueprintjs/core";

const FindTextInput = ({ value, onChange, onKeyDown }) => (
  <ControlGroup fill={true} className="search-map-input" label='Найти: '>
    <InputGroup
      fill={true}
      value={value}
      onChange={({target: { value }}) => onChange(value)}
      onKeyDown={onKeyDown}
      placeholder="Введите текст для поиска"
    />
    <Button
      icon={value.length > 0 ? 'cross' : 'arrow-right'}
      onClick={() => onChange('')}
      minimal={false}
    />
  </ControlGroup>
);

export default FindTextInput;
