import React, { useState, useCallback } from "react";
import {
  FormGroup,
  InputGroup,
  NumericInput,
  Button,
  ButtonGroup,
  Divider,
  Tooltip,
  Popover,
  Position,
  Intent,
} from "@blueprintjs/core";
import { NavigationObjectsMobileObject } from 'constants/attributesTypes'
import AdditionalObjectAttributes from 'components/AdditionalObjectAttributes'
import ModelsSelect from "components/Selects/ModelsSelect";
import ClipButton from "components/ClipButton";

import ShiftCollapse from "./ShiftCollapse";

import { Grid, Indent } from 'styled'
import { Styled } from './MobileObjectProps.styled'

const MobileObjectProps = ({
  object,
  refreshObject,
  onMobjectPropsUpdate,
  showInList
}) => {

  const [name, setName] = useState(object && object.name ? object.name : '')
  const [model, setModel] = useState(object && object.model ? object.model : '')
  const [garageNum, setGarageNum] = useState(object && object.garageNum ? object.garageNum : '')
  const [stateNum, setStateNum] = useState(object && object.stateNum ? object.stateNum : '')
  const [vin, setVin] = useState(object && object.vin ? object.vin : '')
  const [marker, setMarker] = useState(object && object.marker ? object.marker : 0)
  const [periodsSettings, setPeriodsSettings] = useState(object && object.periodsSettings ? object.periodsSettings : null)
  const [currentAttributes, setCurrentAttributes] = useState(object && object.attributes ? object.attributes : {})

  const onPeriodsSettingsChange = useCallback(newSettings => setPeriodsSettings(newSettings), [])

  const onSubmit = () => {
    const newObject = {
      name,
      model: model.id,
      garageNum,
      stateNum,
      vin,
      marker,
      periodsSettings,
      attributes: currentAttributes
    }
    onMobjectPropsUpdate(newObject)
  }

  return (
    <div>
      <ButtonGroup minimal>
        <Button icon="floppy-disk" intent={Intent.SUCCESS} onClick={onSubmit}>Сохранить</Button>
        <Button icon="refresh" intent={Intent.WARNING} onClick={() => refreshObject(object.id)}>
          Обновить
        </Button>
        {object.groups.length > 1 ? (
          <Popover>
            <Tooltip content="Поиск в обозревателе" position={Position.RIGHT}>
              <Button icon="search" />
            </Tooltip>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {object.groups.map(group => (
                <Button icon="search" key={group.id} onClick={() => showInList(group.id, object.id)}>Показать в папке: {group.displayName}</Button>
              ))}
            </div>
          </Popover>
        ) : (
          <Tooltip content="Поиск в обозревателе" position={Position.RIGHT}>
            <Button icon="search" onClick={() => showInList(object.groups.length ? object.groups[0].id : '', object.id)} />
          </Tooltip>
        )}
        <Tooltip
          content="Режим автопересчета для объекта"
          position={Position.RIGHT}
        >
          <Popover>
            <Button icon="calculator" />
            <Styled.Warning>
              <p>
                Если выставлено, каждые сутки в 01:00 ночи по серверному времени
                события объекта будут автоматически пересчитываться за
                предыдущие сутки.
              </p>
              <p>
                Если выставлен атрибут "Время автопересчета", пересчет быдует
                производиться на период в 26 часов каждые сутки, отступая от
                указанного в атрибуте времени. Пересчет начинается спустя час
                после его добавления в планировщик.
              </p>
              <Divider />
              <b>
                Опция необходима для редких случаев, когда вследствие
                некорректной работы оборудования, есть проблемы с пересчетом
                некоторых видов событий по черным ящикам (напр. расчет рейсов по
                датчикм подъема кузова).
              </b>
              <b>
                Не используйте, если у объекта нет проблем с пересчетом событий,
                так как опция увеличивает нагрузку на сервер
              </b>
              <Indent marginTop={15}>
                <ButtonGroup>
                  <Button>Отмена</Button>
                  <Button intent={"danger"}>Продолжить</Button>
                </ButtonGroup>
              </Indent>
            </Styled.Warning>
          </Popover>
        </Tooltip>
      </ButtonGroup>
      <Divider />
      <Grid columnsRatio={[1, 2]}>
        <Grid columns={1} paddingLeft={10}>
          <FormGroup label="Имя объекта:" labelFor="name">
            <InputGroup
              id="name"
              placeholder="Имя объекта"
              defaultValue={name}
              onChange={e => setName(String(e.target.value))}
            />
          </FormGroup>
          <FormGroup label="Модель" labelFor="modelSelect">
            <ModelsSelect currentModel={model} onModelChange={newModel => setModel(newModel)} />
          </FormGroup>
          <FormGroup label="Гаражный номер:" labelFor="garageNum">
            <InputGroup
              id="garageNum"
              placeholder="Гаражный номер"
              defaultValue={garageNum}
              onChange={e => setGarageNum(String(e.target.value))}
            />
          </FormGroup>
          <FormGroup label="Гос. номер:" labelFor="stateNum">
            <InputGroup
              id="stateNum"
              placeholder="Гос. номер"
              defaultValue={stateNum}
              onChange={e => setStateNum(String(e.target.value))}
            />
          </FormGroup>
          <FormGroup label="VIN-код:" labelFor="vin">
            <InputGroup
              id="vin"
              placeholder="VIN-код"
              defaultValue={vin}
              onChange={e => setVin(String(e.target.value))}
            />
          </FormGroup>
          <FormGroup label="Метка:" labelFor="marker">
            <NumericInput
              fill
              id="marker"
              placeholder="Метка"
              defaultValue={marker}
              onValueChange={value => setMarker(value)}
            />
          </FormGroup>
          <FormGroup label="ID:" labelFor="id">
            <InputGroup
              id="id"
              disabled={true}
              placeholder="---"
              defaultValue={object.id}
              rightElement={<ClipButton text={object.id} />}
            />
          </FormGroup>
          <FormGroup label="Организация:" labelFor="firm">
            <InputGroup id="firm" placeholder="---" disabled={true} />
          </FormGroup>
          <ShiftCollapse
            periodsSettings={periodsSettings}
            onSettingsChange={onPeriodsSettingsChange}
          />
        </Grid>
        <div>
          <AdditionalObjectAttributes
            targetType={NavigationObjectsMobileObject}
            entityAttributes={currentAttributes}
            onChange={setCurrentAttributes}
          />
        </div>
      </Grid>
    </div>
  );
};

export default MobileObjectProps;
