
export const NavigationRoutesRoute =  "Navigation.Routes.Route"
export const NavigationObjectsZone =  "Navigation.Objects.Zone"
export const NavigationObjectsZoneGroup =  "Navigation.Objects.ZoneGroup"
export const NavigationObjectsWorker =  "Navigation.Objects.Worker"
export const NavigationObjectsEnterprise =  "Navigation.Objects.Enterprise"
export const NavigationObjectsUser =  "Navigation.Objects.User"
export const NavigationObjectsNavCargo =  "Navigation.Objects.NavCargo"
export const NavigationObjectsMobileObject =  "Navigation.Objects.MobileObject"
export const NavigationObjectsMobileGroup =  "Navigation.Objects.MobileGroup"
export const NavigationObjectsMobileObjectModel =  "Navigation.Objects.MobileObjectModel"
export const NavigationObjectsMobileObjectType =  "Navigation.Objects.MobileObjectType"
export const NavigationNavDataSocialSign =  "Navigation.NavData.SocialSign"
export const NavigationObjectsUserRole =  "Navigation.Objects.UserRole"