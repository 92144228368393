import React, { useMemo } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const Chart = ({ title, xArray, yArray }) => {

    const options = useMemo(() => ({
        title: {
            text: title
        },
        yAxis: {
            title: {
                text: 'Значения по тарировке'
            }
        },
        xAxis: {
            categories: xArray,
            gridLineWidth: 1,
            title: {
                text: 'Показания датчика'
            }
        },
        legend: {
            enabled: false
        },
        series: [{name: 'Значение по тарировке', data: yArray }]
    }), [title, xArray, yArray])

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    )
}

export default Chart;