import React, { useState, useCallback } from 'react';
import {
  Checkbox,
  FileInput,
  Icon,
  InputGroup,
  Label,
  TextArea,
} from "@blueprintjs/core";
import {DateInput, TimePrecision} from '@blueprintjs/datetime';

// Import tools
import { onMouseDown } from 'tools/dragNDrop';
import { appendChildToMap } from "../tools/appendChild";
import { showToasterD, showToasterS } from 'tools/AppToaster';
import axios from 'axios';

// Import styles
import { FormContainer } from './CompleteCreateZone.styled'
import dateFormatString from 'constants/locales';

/**
 * _variable_ is assigned a value that never used but will be in the future
 * */
const CompleteCreateZone = ({
  clearZonesById,
  map,
  setActiveCreateZone,
  createInitialZone,
  className,
  setCreateCompletePopup,
  center,
  radius,
  editPopupRef,
  zoneColor,
  width,
  points,
  handleSetTogglePopup,
  editedPopupIsHidden,
}) => {
  // Boolean handler changes value of disabled button when query sending
  const [sendQuery, setSendQuery] = useState(false);
  // Boolean handler changes value of checkbox to display input
  const [endTime, setEndTime] = useState(false);
  // The special string for display name of zone
  const [nameZone, setNameZone] = useState('');
  // The special string for display description of zone
  const [descriptionZone, setDescriptionZone] = useState('');
  // The whole path of picture
  const [_picturePathZone_, setPicturePathZone] = useState(false);
  // The name of picture
  const [pictureNameZone, setPictureNameZone] = useState(false);
  // The values DateInputs
  const [startTimeZone, setStartTimeZone] = useState('');
  const [endTimeZone, setEndTimeZone] = useState('');

  // ESLINT DISABLE WARNING TODO
  console.log(_picturePathZone_);

  // The handler that enable events on map with using useCallback
  const mapHandlingEnable = useCallback(() => {
    map.dragging.enable();
    map.doubleClickZoom.enable();
    setActiveCreateZone(false);
  }, [map.dragging, map.doubleClickZoom, setActiveCreateZone])

  // The handler that disable events on map with using useCallback
  const mapHandlingDisable = useCallback(() => {
    map.dragging.disable();
    map.doubleClickZoom.disable()
    setActiveCreateZone(true);
  }, [map.dragging, map.doubleClickZoom, setActiveCreateZone])

  // The handler event by mouse over of EditPopup
  const onMouseEnter = () => mapHandlingDisable();

  // The handler event by mouse out of EditPopup
  const onMouseLeave = () => mapHandlingEnable();

  // Handlers
  const handleSetEndTime = e => setEndTime(e.target.checked);
  const handleChangeNameZone = e => setNameZone(e.target.value);
  const handleChangeDescriptionZone = e => setDescriptionZone(e.target.value);
  const handleChangePictureZone = e => {
    const pathArray = e.target.value.split('\\');
    const name = pathArray[pathArray.length - 1];
    setPicturePathZone(e.target.value);
    setPictureNameZone(name);
  }
  const handleChangeStartTime = date => {
    const startTime = new Date(date).toISOString();
    setStartTimeZone(startTime);
  }
  const handleChangeEndTime = date => {
    const endTime = new Date(date).toISOString()
    setEndTimeZone(endTime);
  }

  const cancelCompleteCreateZone = () => {
    appendChildToMap(map._container, editPopupRef.current);
    setCreateCompletePopup(false);
  }

  const onFormSubmit = e => {
    e.preventDefault();
    const createdZone = {
      ...createInitialZone,
    }

    if(!nameZone.trim()) {
      showToasterD('Введите название зоны!');
    } else if(!startTimeZone.trim()) {
      showToasterD('Введите время начала действия зоны');
    } else if(endTime && !endTimeZone.trim()) {
      showToasterD('Введите время окончания действия зоны');
    } else {
      createdZone.name = nameZone;
      createdZone.state.dateCreate = startTimeZone;
      createdZone.color = [zoneColor.r, zoneColor.g, zoneColor.b, Math.floor(255 * zoneColor.a)];

      if(createdZone.state.type === 0) {
        createdZone.state.radius = radius;
        createdZone.state.center = [center.lng, center.lat];
      }

      if(createdZone.state.type === 2 || createdZone.state.type === 1) {
        createdZone.state.width = width;
        let positions = [];

        points.forEach(item => {
          positions.push([item.lng, item.lat]);
        });

        createdZone.state.points = positions;
      }

      if(descriptionZone.trim()) {
        createdZone.description = descriptionZone;
      }

      if(endTimeZone.trim()) {
        createdZone.state.dateDelete = endTimeZone;
      } else {
        delete createdZone.state.dateDelete
      }


      setSendQuery(true);
      axios.post('/api/zones', createdZone)
        .then(() => {
          showToasterS('Зона успешна создана!');
          handleSetTogglePopup();
        })
        .catch(() => {
          showToasterD('Неудалось создать зону, повторите позже!')
          setSendQuery(false);
        });
    }
  }

  const _jsDateFormatter = {
    // native implementation of Date functions differs between browsers
    formatDate: date =>
      date.toLocaleString('ru-RU', {
        year: 'numeric',
        month: '2-digit',
        day: 'numeric'
      }),
    parseDate: str => new Date(str),
    placeholder: 'М/ДД/ГГГГ'
  };

  return (
    <div
      className={className ? `edit-popup ${className}` : "edit-popup"}
      data-draggable={true}
      style={{ display: editedPopupIsHidden ? 'none' : 'block' }}
      ref={editPopupRef}
      onMouseDown={onMouseDown}
      onDragStart={() => false}
      onMouseEnter={map ? onMouseEnter : null}
      onMouseLeave={map ? onMouseLeave : null}
    >
      <form onSubmit={onFormSubmit}>
        <button
          className="edit-popup-close-btn"
          onClick={cancelCompleteCreateZone}
        >
          <Icon icon="cross" />
        </button>
        <h2>Добавление зоны</h2>
        <FormContainer className="form-container">
          <Label>
            <div className="label-container">
              <span>Название зоны:</span>
              <InputGroup
                fill={true}
                value={nameZone}
                name="name"
                onChange={handleChangeNameZone}
                placeholder=""
              />
            </div>
          </Label>
          <Label>
            <div className="label-container">
              <span>Тип зоны:</span>
              <div className="select-tile-layer">
                <div className="bp3-select modifier">
                  <select>
                    <option>Область на карте</option>
                  </select>
                </div>
              </div>
            </div>
          </Label>
          <Label>
            <div className="label-container">
              <span>Группа:</span>
              <div className="select-tile-layer">
                <div className="bp3-select modifier">
                  <select>
                    <option>{/* todo */}</option>
                  </select>
                </div>
              </div>
            </div>
          </Label>
          <Label>
            <div className="label-container">
              <span>Описание:</span>
              <TextArea
                fill={true}
                value={descriptionZone}
                name="description"
                className="description"
                onChange={handleChangeDescriptionZone}
                placeholder=""
              />
            </div>
          </Label>
          <Label>
            <div className="label-container">
              <span>Изображение:</span>
              <FileInput
                text={pictureNameZone ? pictureNameZone : "Выберите файл"}
                fill={true}
                buttonText="Выбрать"
                onInputChange={handleChangePictureZone}
              />
            </div>
          </Label>
          <Label>
            <div className="label-container">
              <span>Время начала действия:</span>
              <DateInput
                dayPickerProps={{
                  locale: 'ru',
                  months: dateFormatString.months,
                  weekdaysLong: dateFormatString.weekDaysLong,
                  weekdaysShort: dateFormatString.weekDaysShort,
                  firstDayOfWeek: 1
                }}
                showActionsBar={true}
                id='mountDate'
                name='mountDate'
                timePrecision={TimePrecision.MINUTE}
                todayButtonText='Текущая дата'
                clearButtonText='Очистить'
                onChange={handleChangeStartTime}
                {..._jsDateFormatter}
              />
            </div>
          </Label>
          <Label>
            <div className="label-container">
              <span className="label-with-checkbox">
                Время окончания действия:
                <Checkbox
                  value={endTime}
                  onChange={handleSetEndTime}
                />
              </span>
              {
                endTime &&
                <DateInput
                  dayPickerProps={{
                    locale: 'ru',
                    months: dateFormatString.months,
                    weekdaysLong: dateFormatString.weekDaysLong,
                    weekdaysShort: dateFormatString.weekDaysShort,
                    firstDayOfWeek: 1
                  }}
                  showActionsBar={true}
                  id='mountDate'
                  name='mountDate'
                  timePrecision={TimePrecision.MINUTE}
                  todayButtonText='Текущая дата'
                  clearButtonText='Очистить'
                  onChange={handleChangeEndTime}
                  {..._jsDateFormatter}
                />
              }
            </div>
          </Label>
        </FormContainer>

        <button
          className="complete"
          type="submit"
          disabled={sendQuery}
        >
          Ок
        </button>
      </form>
    </div>
  );
};

export default CompleteCreateZone;
