export function initialStateZone(type) {
  let typeUnit = {};

  switch (type) {
    case 0:
      typeUnit.radius = 0;
      typeUnit.center = {
        latitude: 0,
        longitude: 0,
        longitudeMercator: 0,
        latitudeMercator: 0,
        isValid: true,
        isEmpty: true
      }
      break;
    case 1:
      typeUnit.points = [{
        latitude: 0,
        longitude: 0,
        longitudeMercator: 0,
        latitudeMercator: 0,
        isValid: true,
        isEmpty: true
      }];
      break;
    case 2:
      typeUnit.width = 0;
      typeUnit.points = [{
        latitude: 0,
        longitude: 0,
        longitudeMercator: 0,
        latitudeMercator: 0,
        isValid: true,
        isEmpty: true
      }];
      break;

    default:
      break;
  }

  return {
    name: '',
    role: 0,
    color: [240, 128, 128, 60],
    description: '',
    state: {
      type: type,
      dateCreate: '',
      ...typeUnit,
    },
  }
}
