import React, { useEffect, useState } from 'react'

import MobileObjectTechServiceView from './MobileObjectTechServiceView';
import MObjectsTechInspectionsService from "api/MObjectsTechInspectionsService";
import { showToasterD } from 'tools/AppToaster';

const _MObjectsTechInspectionsService = new MObjectsTechInspectionsService()

export const MobileObjectTechService = ({ objectId }) => {

    const [techInspections, setTechInspections] = useState([])
    const [loading, setLoading] = useState(true)
    const [needToUpdate, setNeedToUpdate] = useState(true)

    useEffect(() => {

        let dataLoaded = false
        
        if (needToUpdate) {
            _MObjectsTechInspectionsService.get({ objectId })
                .then(res => {
                    if (!dataLoaded) {
                        setTechInspections(res.data.result.techInspections)
                    }
                })
                .catch(() => showToasterD('Ошибка загрузки'))
                .finally(() => {
                    if (!dataLoaded) {
                        setLoading(false)
                        setNeedToUpdate(false)
                    }
                })
        }

        return () => dataLoaded = true

    }, [needToUpdate, objectId])


    const onAdd = ({ beginTime, description }) => {
        setLoading(true)
        _MObjectsTechInspectionsService.post({ objectId, beginTime, description })
            .then(() => setNeedToUpdate(true))
            .catch(() => showToasterD('Ошибка загрузки'))
    }

    const onEdit = ({ inspectionId, beginTime, description }) => {
        setLoading(true)
        _MObjectsTechInspectionsService.put({ objectId, inspectionId, beginTime, description })
            .then(() => setNeedToUpdate(true))
            .catch(() => showToasterD('Ошибка загрузки'))
    }

    const onDelete = (inspectionId) => {
        setLoading(true)
        _MObjectsTechInspectionsService.delete({ objectId, inspectionId })
            .then(() => setNeedToUpdate(true))
            .catch(() => showToasterD('Ошибка загрузки'))
    }

    return <MobileObjectTechServiceView
        techInspections={techInspections}
        onAdd={onAdd}
        onEdit={onEdit}
        onDelete={onDelete}
        loading={loading}
    />
}

export default MobileObjectTechService
