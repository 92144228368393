import axios from 'axios';

export default class MObjectsTechInspectionsService {

    get = ({ objectId }) => {
        return axios.get(`/api/mobjects/${objectId}?fields=techInspections[id,beginTime,endTime,mileage,description]`)
    }

    post = ({ objectId, beginTime, description }) => {
        return axios.post(`/api/tech-inspection`, { object: objectId, beginTime, description })
    }

    put = ({ objectId, inspectionId, beginTime, description }) => {
        return axios.put(`/api/tech-inspection/${inspectionId}`, { object: objectId, beginTime, description })
    }

    delete = ({ objectId, inspectionId }) => {
        return axios.delete(`/api/tech-inspection/by-mobject/${objectId}/${inspectionId}`)
    }
}