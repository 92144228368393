import { showToasterD, showToasterS } from 'tools/AppToaster';
import domtoimage from 'dom-to-image';

function saveLink(dataUrl, fileName, format) {
  const link = document.createElement('a');
  link.download = `${fileName}.${format}`;
  link.href = dataUrl;
  link.click();
  showToasterS('Изображение сохранено!');
}

export function makeScreenshotMap(map, fileName, format) {
  if(map) {
    if(format === 'png') {
      domtoimage.toPng(map)
        .then(dataUrl => saveLink(dataUrl, fileName, format))
        .catch(() => showToasterD('Не удалось сохранить изображение'))
    } else if(format === 'jpeg') {
      domtoimage.toJpeg(map)
        .then(dataUrl => saveLink(dataUrl, fileName, format))
        .catch(() => showToasterD('Не удалось сохранить изображение'))
    }
  } else {
    showToasterD('Не удалось сохранить изображение');
  }
}
