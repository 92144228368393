import { CRS } from 'leaflet'

export const tileLayers = [
  {
    title: 'Open Street Map',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    subdomains: ['a', 'b', 'c'],
    crs: CRS.EPSG3857
  },
  {
    title: 'Google Карта',
    url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
    subdomains: ['mt0','mt1','mt2','mt3'],
    crs: CRS.EPSG3857
  },
  {
    title: 'Google Спутник',
    url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    subdomains: ['mt0','mt1','mt2','mt3'],
    crs: CRS.EPSG3857
  },
  {
    title: 'Google Гибрид',
    url: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
    subdomains: ['mt0','mt1','mt2','mt3'],
    crs: CRS.EPSG3857
  },
  {
    title: 'Яндекс Карты',
    id: 'ynd',
    url: 'http://vec0{s}.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}&lang==ru_RU',
    subdomains: '1234',
    crs: CRS.EPSG3395
  },
  {
    title: 'Яндекс Спутник',
    url: 'http://sat0{s}.maps.yandex.net/tiles?l=sat&x={x}&y={y}&z={z}&lang=ru-RU',
    subdomains: '1234',
    crs: CRS.EPSG3395
  },
  {
    title: '2GIS',
    url: 'http://tile{s}.maps.2gis.com/tiles?v=1&x={x}&y={y}&z={z}',
    subdomains: ['0', '1', '2', '3'],
    crs: CRS.EPSG3857
  },
];
