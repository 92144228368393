import React from "react";
import { showToasterS, showToasterD } from "tools/AppToaster";
import { Tooltip, Position } from "@blueprintjs/core";

import { Styled } from './ClipButton.styled';

const ClipButton = ({ text }) => {
  const copyToClipboard = (value) => {
    if (navigator.clipboard !== undefined) {
      //Chrome
      navigator.clipboard.writeText(value).then(
        function () {
          showToasterS("Скопировано в буфер обмена");
          console.log(`Async: Copying ${value} to clipboard was successful!`);
        },
        function (err) {
          showToasterD("Не удалось скопировать данные");
          console.error("Async: Could not copy text: ", err);
        }
      );
    } else if (window.clipboardData) {
      // Internet Explorer
      window.clipboardData.setData("Text", value);
    }
  };

  return (
    <Tooltip content="Скопировать в буфер обмена" position={Position.RIGHT}>
      <Styled.ClipButton
        icon="floppy-disk"
        onClick={() => copyToClipboard(text)}
      />
    </Tooltip>
  );
};

export default ClipButton;
