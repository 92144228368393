import React, { useState, useEffect } from 'react';
import { InfiniteLoader, AutoSizer, List } from 'react-virtualized';

import ListRow from './ListRow';
import ListRowPlaceholder from './ListRowPlaceholder';

import styles from './VirtualList.module.scss';
import 'react-virtualized/styles.css';

const VirtualList = ({
  list = [],
  rowCount,
  loadStep,
  onRowDblClick,
  onRigthClick,
  loadMoreRows,
  searchedObjectIndex,
  setSelectedObjectIds,
  selectedObjectIds,
  ...restProps
}) => {

  const rowHeight = 20
  const [scroll, setScroll] = useState(0)

  useEffect(() => {
    const windowHeight = window.innerHeight;
    const headerHeight = 60;
    const totalListHeight = windowHeight - headerHeight;
    const currentListheight = rowCount * rowHeight

    if (searchedObjectIndex && currentListheight > totalListHeight) {
      setScroll(searchedObjectIndex * rowHeight)
    }
  }, [searchedObjectIndex, setScroll, rowCount, rowHeight])

  function isRowLoaded({ index }) {
    return !!list[index];
  }

  const rowRenderer = ({ key, index, style }) => {
    let row;

    if (!list[index]) {
      row = (<ListRowPlaceholder key={key} style={style} />)
    } else {

      const icon = list[index].type === 'g'
        ? 'icons/Folder.png'
        : list[index].typeId
          ? `gen/t${list[index].typeId}.png`
          : 'icons/MObject.png';

      const isSelected = selectedObjectIds?.includes(list[index].objectId)

      const rowParams = {
        style,
        icon,
        displayName: list[index].name,
        text: list[index].name,
        objectId: list[index].objectId,
        type: list[index].type,
        isSelected
      };

      row = (
        <ListRow
          style={{}} // added to ignore warning 'Rendered cell should include style property for positioning'
          key={key}
          params={rowParams}
          onRigthClick={(e) => onRigthClick && onRigthClick(e, list[index].objectId, list[index].type, list[index].name)}
          handleRowSelect={e => handleRowSelect(list[index], index, e, isSelected)}
          onRowDblClick={onRowDblClick}
          restProps={restProps}
          selectedObjectIds={selectedObjectIds}
        />
      )
    }

    const handleRowSelect = (row, index, e, isSelected) => {
      if (e.ctrlKey) {
        if (isSelected) {
          const index = selectedObjectIds.findIndex(item => item === row.objectId)
          const newArr = [
            ...selectedObjectIds.slice(0, index),
            ...selectedObjectIds.slice(index + 1),
          ]
          setSelectedObjectIds(newArr);
        }
        else {
          setSelectedObjectIds([...selectedObjectIds, row.objectId]);
        }
      }
      else if (e.shiftKey && selectedObjectIds.length > 0) {
        const firstIndex = list.findIndex(item => item?.objectId === selectedObjectIds[0])
        const lastIndex = index + 1
        const arrOfSelected = list.slice(firstIndex, lastIndex).filter(item => item).map(item => item.objectId)
        setSelectedObjectIds(arrOfSelected);
      }
      else {
        setSelectedObjectIds([row.objectId]);
      }
    };

    return row;
  };

  if (!loadMoreRows) return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          scrollTop={scroll}
          onScroll={({ scrollTop }) => setScroll(scrollTop)}
          className={styles.list}
          height={height}
          width={width}
          rowCount={rowCount}
          rowHeight={rowHeight}
          rowRenderer={rowRenderer}
        />
      )}
    </AutoSizer>
  )

  return (
    <InfiniteLoader
      isRowLoaded={isRowLoaded}
      loadMoreRows={loadMoreRows}
      rowCount={rowCount}
      minimumBatchSize={loadStep}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer>
          {({ height, width }) => (
            <List
              scrollTop={scroll}
              onScroll={({ scrollTop }) => setScroll(scrollTop)}
              onRowsRendered={onRowsRendered}
              ref={registerChild}
              className={styles.list}
              height={height}
              width={width}
              rowCount={rowCount}
              rowHeight={rowHeight}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>

  );
};

export default VirtualList;
