export function getCentroid(arr) {
    let twoTimesSignedArea = 0;
    let cxTimes6SignedArea = 0;
    let cyTimes6SignedArea = 0;

    const length = arr.length;

    const x = function (i) { return arr[i % length][0] };
    const y = function (i) { return arr[i % length][1] };

    for ( let i = 0; i < arr.length; i++) {
      const twoSA = x(i) * y(i+1) - x(i+1) * y(i);
      twoTimesSignedArea += twoSA;
      cxTimes6SignedArea += (x(i) + x(i+1)) * twoSA;
      cyTimes6SignedArea += (y(i) + y(i+1)) * twoSA;
    }
    const sixSignedArea = 3 * twoTimesSignedArea;

    return [ cxTimes6SignedArea / sixSignedArea, cyTimes6SignedArea / sixSignedArea];
}
