import axios from "axios";

// Fix bug with git

export const login = async (data) => {
  return await axios.post("/api/auth/login", data);
};

export const logout = (logoutSuccess, logoutCatch) => {
  axios.get("/api/auth/logout")
    .then(logoutSuccess)
    .catch(logoutCatch)
};

export const getUser = () => {
  return axios.get("/api/auth/profile")
    .then(res => res)
    .catch(e => e.response.data)
};

export const prepareVtable = async (parentId, selectedObjectId, entityName) => {
  let res
  if (parentId && selectedObjectId) {
    res = await axios.get(`/api/${entityName}/VTable/prepare?parent=${parentId}&object=${selectedObjectId}`);
  }
  else if (parentId && !selectedObjectId) {
    res = await axios.get(`/api/${entityName}/VTable/prepare?parent=${parentId}`);
  }
  else if (!parentId && selectedObjectId) {
    res = await axios.get(`/api/${entityName}/VTable/prepare?object=${selectedObjectId}`);
  }
  else {
    res = await axios.get(`/api/${entityName}/VTable/prepare`);
  }
  return res.data.result
};

export const getMobjects = async (counter, step) => {
  const res = await axios.get(`/api/mobjects/VTable/range?from=${counter}&to=${counter + step}`);
  return res
};

export const getMobject = async (id, fields) => {
  const res = await axios.get(`/api/mobjects/${id}${fields || ''}`);
  return res.data
};

export const putMobject = async (id, data) => {
  return await axios.put(`/api/mobjects/${id}`, data);
};

export const getMobjectAttrs = async () => {
  return await axios.get(`/api/additional-object-attributes`);
};

export const getGroup = async (id) => {
  await function () {
    return setTimeout();
  };
};

export const getEnterprises = async (fields = '') => {
  const res = await axios.get(`/api/enterprises${fields}`);
  return res.data.result
};

export const getEnterprisesGroupedByParentId = async () => {
  const res = await axios.get('/api/enterprises?fields=id,name,parent[id,name]&groupBy=parent.id');
  return res.data.result
}

export const getEnterprise = async (id) => {
  return await axios.get(`/api/enterprises/${id}`);
};

export const getZones = async () => {
  return await axios.get('/api/zones/full');
};

export const getSensorTypes = async (fields = '') => {
  if (fields) fields = `?fields=${fields}`
  const res = await axios.get(`/api/sensor-types${fields}`);
  return res.data.result
};

export const postSensorType = async body => {
  await axios.post(`/api/sensor-types`, body);
};

export const putSensorType = async (id, body) => {
  await axios.put(`/api/sensor-types/${id}`, body);
};

export const deleteSensorType = async id => {
  await axios.delete(`/api/sensor-types/${id}`);
};

export const getDeviceProtocols = async () => {
  const responce = await axios.get("/api/device-protocols");
  return responce.data.result;
}

export const getDetailedDeviceProtocol = async (id) => {
  const responce = await axios.get("/api/device-protocols/" + id);
  return responce.data.result;
}

export const createControlDevice = async (data) => {
  const responce = await axios.post("/api/control-device/", data);
  return responce.data.result;
}

export const putControlDevice = async (id, data) => {
  const responce = await axios.put(`/api/control-device/${id}`, data);
  return responce.data.result;
}

export const getControlDevice = async (id, fields = '') => {
  const responce = await axios.get(`/api/control-device/${id}${fields}`);
  return responce.data.result;
}

export const deleteControlDevice = async (id) => {
  return await axios.delete(`/api/control-device/${id}`);
}

export const replaceControlDevice = async (id, data) => {
  return await axios.post(`/api/control-device/${id}/replace`, data);
}

export const getSensor = async (id, fields) => {
  return await axios.get(`/api/sensor/${id}${fields || ''}`);
}

export const createSensor = async (data) => {
  const responce = await axios.post("/api/sensor", data);
  return responce.data.result;
}

export const createSensorByPrototype = async data => {
  const responce = await axios.post("/api/sensor/by-prototype", data);
  return responce.data.result;
}

export const deleteSensor = async (id) => {
  return await axios.delete(`/api/sensor/${id}`);
}

export const putSensor = async (id, data) => {
  return await axios.put(`/api/sensor/${id}`, data);
}

export const postSensorConverter = async (sensorId, converter) => {
  return await axios.post("/api/sensor-converter", { sensor: sensorId, converter });
}

export const putSensorConverter = async (sensorId, beginDate, converter) => {
  return await axios.put(`/api/sensor-converter/${beginDate}`, { sensor: sensorId, converter });
}

export const deleteSensorConverter = async (sensorId, beginDate) => {
  return await axios.delete(`/api/sensor-converter/by-sensor/${sensorId}/${beginDate}`);
}
