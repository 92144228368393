import { divIcon, marker, circleMarker, circle, polygon, polyline } from 'leaflet'

import { getCentroid } from './getCentroid'

export const createZone = (zone, map, needAddToMap, needCenter = false) => {
  let zoneMarkerRef = null;
  let circleMarkerRef = null;

  let alpha = 1;

  if(zone.hasOwnProperty('color')) {
    alpha = +(zone.color[3] / 255).toFixed(2);
  }

  if(zone?.states?.length) {
    zone.states.forEach(state => {
      if(state?.type === 0) {
        zoneMarkerRef = circle(state.center, {
          radius: state.radius,
          color: `
            rgba(
              ${zone.color[0]},
              ${zone.color[1]},
              ${zone.color[2]},
              ${alpha}
            )
          `
        });
    
        circleMarkerRef = circleMarker(state.center, {
          radius: 1,
          color: "red",
          class: "circle-marker"
        });
    
        if(needCenter) {
          map.setView(state.center);
        }
      } else if(state?.type === 1) {
        zoneMarkerRef = polygon(state.points, {
          color: `
            rgba(
              ${zone.color[0]},
              ${zone.color[1]},
              ${zone.color[2]},
              ${alpha}
            )
          `,
          weight: 2,
        });

        if(needCenter) {
          map.setView(zone.states[0].points[0]);
        }
      } else if(state?.type === 2) {
        zoneMarkerRef = polyline(state.points, {
          color: `
            rgba(
              ${zone.color[0]},
              ${zone.color[1]},
              ${zone.color[2]},
              ${alpha}
            )
          `,
          weight: zone.states[0].width,
          dashArray: false,
          lineCap: 'round'
        });
    
        if(needCenter) {
          map.setView(zone.states[0].points[0]);
        }
      }
    })
  }

  if(needAddToMap && zoneMarkerRef) {
    zoneMarkerRef.addTo(map);

    if(circleMarkerRef) {
      circleMarkerRef.addTo(map);
    }
  }

  return {
    zoneMarkerRef,
    circleMarkerRef
  }
}

export const createNameMarkerZone = (zone, map, zoom, needAddToMap) => {
  if(zone.states.length) {
    let center;
    let iconSize;
    let textMarker = null;

    if(zone.states[0].type === 0) {
      center = zone.states[0].center;
      iconSize = [20, 20];
    } else if(zone.states[0].type === 1) {
      center = getCentroid(zone.states[0].points);

      if(isNaN(center[0]) || isNaN(center[0])) {
        center = zone.states[0].points[0]
      }

      iconSize = [100, 20];
    } else {
      center = zone.states[0].points[0]
      iconSize = [100, 20];
    }

    if(zoom > 12) {
      textMarker = marker(center, {
        icon: divIcon({
          className: '',
          iconSize: iconSize,
          html: `<span class="span-temp">${zone.name}</span>`
        })
      })

      if(needAddToMap) {
        textMarker.addTo(map)
      }
    }

    return textMarker;
  } 
  
  return null
}