import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite'
import { useQuery } from 'react-query'
import { Spinner } from '@blueprintjs/core'
import VirtualList from 'components/VirtualList'
import { getWorkers } from 'api/workersService'
import { socketConnection } from 'tools/socketManager';
import { ON_NAV_OBJECT_ADDED, ON_NAV_OBJECT_CHANGED, ON_NAV_OBJECT_REMOVED } from 'constants/socketManager';
import SocketStore from 'stores/socketStore'
import WSChannelsNames from 'constants/WSChannelsNames'
import { Styled } from '../LeftPanel.styled'

const WorkersList = observer(() => {

    const [selectedObjectIds, setSelectedObjectIds] = useState([])

    const { data, isLoading, error, refetch } = useQuery("workersListForObjectsObserver", () => getWorkers())

    const updatedItem = SocketStore.getChannelData(ON_NAV_OBJECT_CHANGED, WSChannelsNames.Worker);
    const removedItem = SocketStore.getChannelData(ON_NAV_OBJECT_REMOVED, WSChannelsNames.Worker);
    const addedItem = SocketStore.getChannelData(ON_NAV_OBJECT_ADDED, WSChannelsNames.Worker);

    useEffect(() => {
        if (!socketConnection) return

        SocketStore.addSocketChannel(ON_NAV_OBJECT_CHANGED, WSChannelsNames.Worker, '')
        SocketStore.addSocketChannel(ON_NAV_OBJECT_REMOVED, WSChannelsNames.Worker, '')
        SocketStore.addSocketChannel(ON_NAV_OBJECT_ADDED, WSChannelsNames.Worker, '')

        return () => {
            SocketStore.removeSocketChannel(ON_NAV_OBJECT_CHANGED, WSChannelsNames.Worker, '')
            SocketStore.removeSocketChannel(ON_NAV_OBJECT_REMOVED, WSChannelsNames.Worker, '')
            SocketStore.removeSocketChannel(ON_NAV_OBJECT_ADDED, WSChannelsNames.Worker, '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    useEffect(() => {
        refetch()
    }, [refetch, removedItem, updatedItem, addedItem])

    return (
        <Styled.ListContainer>
            {isLoading ? (
                <Spinner />
            ) : error ? (
                <p>Произошла ошибка, попробуйте перезагрузить страницу</p>
            ) : (
                <VirtualList
                    list={data?.map(item => ({ objectId: item.id, ...item }))}
                    needSelectObj={false}
                    rowCount={data.length}
                    onRowDblClick={() => { }}
                    selectedObjectIds={selectedObjectIds}
                    setSelectedObjectIds={setSelectedObjectIds}
                    searchedObjectIndex={null}
                />
            )}
        </Styled.ListContainer>
    );
})

export default WorkersList;