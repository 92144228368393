import axios from 'axios'

// Получение адреса по координатам
export const getAddressByCoords = async (lat, lon) => {
    const res = await axios.get(`/api/geodata/address/by-location/${lat}:${lon}`)
    return res.data.result
}

// 
export const getAddressByAddress = async (city, address, leftTopLocation, rightBottomLocation) => {
    let url = `/api/geodata/address/by-address/${city}:${address}`
    
    if(leftTopLocation && rightBottomLocation) {
        url += `?leftTopLocation=${leftTopLocation.lat}:${leftTopLocation.lng}&rightBottomLocation=${rightBottomLocation.lat}:${rightBottomLocation.lng}`
    }

    const res = await axios.get(url)
    return res.data.result
}