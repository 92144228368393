import axios from 'axios';
import { showToasterD } from 'tools/AppToaster';

export const getMobileObjects = async (fiedls) => {
  try {
    const res = await axios.get(`/api/mobjects?fields=${fiedls}`)
    
    return res.data.result;
  } catch {
    showToasterD('Не удалось получить данные мобильного объекта!')
  }
}

export const getMobileObject = async (id, fiedls) => {
  try {
    const res = await axios.get(`/api/mobjects/${id}?fields=${fiedls}`)
    
    return res.data.result;
  } catch {
    showToasterD('Не удалось получить данные мобильного объекта!')
  }
}

export const getMobileObjectsFromGroup = async (id, fields) => {
  try {
    const res = await axios.get(`api/mobject-groups/${id}?fields=${fields}`)
    
    return res.data.result;
  } catch {
    showToasterD('Не удалось получить данные группы мобильных объектов!')
  }
}