import React, { useState, useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { Button } from '@blueprintjs/core';
import { divIcon } from 'leaflet';
import { Marker, Popup } from 'react-leaflet';

import ClipButton from 'components/ClipButton'

import { getMarkers, deleteMarker } from 'api/mapMarkersService';
import { renderMarker } from '../AddMarkerPopup/AddMarkerPopup'

import { markersToAdd } from 'constants/mapMarkers'

const Markers = ({ newMapPoint, setNewMapPoint }) => {
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    (async () => {
      const markers = await getMarkers('id,type,text,location');

      if(markers) {
        setMarkers(markers);
      }
    })()
  }, []);

  useEffect(() => {
    if(newMapPoint.id) {
      setMarkers(prevState => [...prevState, newMapPoint]);
      setNewMapPoint({});
    }
  }, [newMapPoint, setNewMapPoint]);

  const filterMarkers = id => {
    return prevMarkers => {
      let newMarkers = [...prevMarkers];
      newMarkers = newMarkers.filter(marker => marker.id !== id);
      return newMarkers;
    }
  }

  const onDeleteMarker = async id => {
    await deleteMarker(id)
    setMarkers(filterMarkers(id))
  }

  return (
    <>
      {
        markers.length && 
        markers.map(marker => {
          const foundMarker = markersToAdd.find(m => m.type === marker.type);
          const icon = renderMarker(foundMarker);

          return (
            <Marker
              key={marker.id}
              position={[marker.location[1], marker.location[0]]}
              icon={divIcon({
                className: '',
                iconSize: [40, 40],
                html: renderToString((
                  <div style={{ textAlign: 'center' }}>
                    {icon}
                    <div className="span-temp">{marker.text.length > 8 ? `${marker.text.slice(0, 7)}...` : `${marker.text}`}</div>
                  </div>
                ))
              })}
            >
              <Popup>
                <div style={{ fontSize: '13px' }}>
                  
                  <strong>
                    <span style={{ paddingRight: 10 }}>{`${marker.location[1]} : ${marker.location[0]}`}</span>
                    <ClipButton text={`${marker.location[1]} : ${marker.location[0]}`} />
                  </strong>
                  <div style={{ marginTop: 5, marginBottom: 5 }}>{marker.text}</div>
                  <Button intent="danger" onClick={() => onDeleteMarker(marker.id)}>
                    Удалить точку
                  </Button>
                </div>
              </Popup>
            </Marker>
          )}
        )
      }
    </>
  )
}

export default Markers;
