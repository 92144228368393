import React, { useRef } from 'react';
import { Classes, Button, Intent, InputGroup, FormGroup } from '@blueprintjs/core'
import { useForm } from 'react-hook-form'

const PasswordForm = ({ onSubmit, onClose }) => {

    const { register, handleSubmit, watch, errors } = useForm()
    const password = useRef({});
    password.current = watch("password", "");
    
    const submit = data => {
        onSubmit(data)
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <div className={Classes.DIALOG_BODY}>
                <FormGroup label='Новый пароль' helperText={errors.password && errors.password.message} intent={Intent.DANGER}>
                    <InputGroup type='text' name='password' inputRef={register({
                        required: "Ведите новый пароль",
                        minLength: {
                            value: 6,
                            message: "Минималная длина пароля - 6 символа"
                        }
                    })}
                    />
                </FormGroup>
                <FormGroup label='Повторите пароль' helperText={errors.password_repeat && errors.password_repeat.message} intent={Intent.DANGER}>
                    <InputGroup type='text' name='password_repeat' inputRef={register({
                        validate: value =>
                            value === password.current || "Пароли не совпадают"
                    })} />
                </FormGroup>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button type='submit' intent={Intent.SUCCESS} icon='floppy-disk'>Ок</Button>
                    <Button onClick={onClose} intent={Intent.DANGER} icon='undo'>Отмена</Button>
                </div>
            </div>
        </form>
    )
}

export default PasswordForm;
