import React from "react";
import axios from "axios";
import {
  Spinner,
  Dialog,
  Button,
  FormGroup,
  InputGroup,
  Classes,
  Intent,
  Callout,
  Tab,
  Tabs,
  Navbar,
} from "@blueprintjs/core";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { NavTab } from "react-router-tabs";

import ServerSettings from "./ServerSettings";
import LibsView from "./LibsView";

class AuthDialog extends React.Component {
  state = {
    errorMessage: null,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { authCode } = event.target.elements;

    return axios
      .post("/api/sys/init/auth", {
        password: authCode.value,
      })
      .then(() => {
        window.location.reload();
      })
      .catch(({ response }) => {
        this.setState({ errorMessage: response.data.statusDescription });
      });
  };

  render() {
    const { errorMessage } = this.state;

    return (
      <Dialog
        title="Введите код авторизации"
        isOpen={true}
        isCloseButtonShown={false}
      >
        <form onSubmit={this.handleSubmit}>
          <div className={Classes.DIALOG_BODY}>
            {errorMessage && (
              <FormGroup>
                <Callout intent={Intent.DANGER}>{errorMessage}</Callout>
              </FormGroup>
            )}
            <FormGroup labelFor="authCode">
              <InputGroup
                id="authCode"
                placeholder="код авторизации"
                leftIcon="lock"
                type="text"
              />
            </FormGroup>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button icon="log-in" intent={Intent.PRIMARY} type="submit">
                Войти
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    );
  }
}

class TabsBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navbarTabId: "settings",
    };
  }

  render() {
    return (
      <Navbar fixedToTop>
        <Navbar.Group>
          <Navbar.Heading>Настройка сервера</Navbar.Heading>
          <Navbar.Divider />
          <Tabs
            id="navbar"
            large={true}
            onChange={(navbarTabId) => this.setState({ navbarTabId })}
            selectedTabId={this.state.navbarTabId}
          >
            <Tab
              id="settings"
              title={<NavTab to="/settings">Настройки</NavTab>}
            />
            <Tab id="libs" title={<NavTab to="/libs">Модули</NavTab>} />
          </Tabs>
        </Navbar.Group>
      </Navbar>
    );
  }
}

const LibsPanel = () => (
  <div
    style={{
      position: "fixed",
      top: 50,
      bottom: 0,
      width: "100%",
      overflow: "auto",
    }}
  >
    <LibsView />
  </div>
);

const SettingsPanel = () => (
  <div style={{ marginTop: 58 }}>
    <ServerSettings />
  </div>
);

export default class InitConfigApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      isAuth: false,
    };
  }

  componentDidMount() {
    return axios
      .get("/api/sys/init/auth")
      .then(() => this.setState({ isAuth: true, loading: false }))
      .catch(() => this.setState({ isAuth: false, loading: false }));
  }

  render() {
    const { loading, isAuth } = this.state;

    if (loading) return <Spinner />;

    if (!isAuth) return <AuthDialog />;

    return (
      <Router>
        <TabsBar />

        <Switch>
          <Route path="/libs" component={LibsPanel} />
          <Route path="/settings" component={SettingsPanel} />
          <Route path="/">
            <Redirect to="/settings" />
          </Route>
        </Switch>
      </Router>
    );
  }
}
