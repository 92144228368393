export function getArea(latLngs) {
  let pointsCount = latLngs.length,
      area = 0.0,
      d2r = Math.PI / 180,
      p1, p2;

  if(pointsCount > 2) {
    for(let i = 0; i < pointsCount; i++) {
      p1 = latLngs[i];
      p2 = latLngs[(i + 1) % pointsCount];
      area += ((p2.lng - p1.lng) * d2r) *
        (2 + Math.sin(p1.lat * d2r) + Math.sin(p2.lat * d2r));
    }
    area = area * (6378137.0 ** 2) / 2.0;
  }

  return Math.floor(Math.abs(area));
}
