import { polyline } from 'leaflet'
import { createMobileObjectMarker } from '../tools/createMobileObject'

export const createTrack = (track, map, needAddToMap) => {
  const latlngs = track.points.map(point => [...point.location].reverse());
  const trackRef = polyline(latlngs, { color: 'blue' });
  const mobileObjectRef = createMobileObjectMarker(track.firstPoint, false, map, () => {})

  if(needAddToMap && track) {
    trackRef.addTo(map);
    mobileObjectRef.addTo(map)
  }

  return {
    trackRef,
    mobileObjectRef
  }
}