import React, { useState, useMemo, useCallback, useEffect } from 'react';

import { Spinner } from "@blueprintjs/core";

import Header from './Header';
import LinearConverter from './LinearConverter';
import LinearConverterForm from './LinearConverterForm';
import TarTable from './TarTable';
import TarTableForm from './TarTableForm';
import FormHead from './FormHead';

import styles from "./Сonverters.module.scss";

const calculationAlgorithms = [
    {
        index: 0,
        value: 'Navigation.Objects.LinearConverter',
        name: 'Линейная зависимость'
    },
    {
        index: 1,
        value: 'Navigation.Objects.TarTable',
        name: 'Тарировочная таблица'
    }
]

const NoConverter = () => (
    <div className={styles.centerText}>Конвертер не задан</div>
)

const Converters = ({ converters, onPost, onPut, onDelete, loading }) => {
    // изначально actualConverter - элемент из массива converters, у которого нет поля 'end'
    const [actualConverter, setActualConverter] = useState(() => converters?.filter(item => !Object.keys(item).includes('end')).shift())
    const [formMode, setFormMode] = useState(false)
    const [formType, setFormType] = useState('Navigation.Objects.LinearConverter')

    const [currentAlgorithm, setCurrentAlgorithm] = useState(calculationAlgorithms[0])
    const [currentTime, setCurrentTime] = useState(new Date())

    const convertersList = useMemo(() => {
        return converters.map((item, index) => ({ ...item, index }))
    }, [converters])

    // local state changers
    const handleConverterSelect = item => {
        setActualConverter(item)
    }

    const handleAddClick = () => {
        setFormMode(true)
        setFormType('add')
        setCurrentAlgorithm(calculationAlgorithms[0])
        setActualConverter(null)
    }

    const handleEditClick = () => {
        setFormMode(true)
        setFormType('edit')
    }

    const handleDeleteClick = useCallback(() => {
        onDelete(actualConverter.begin)
    }, [actualConverter, onDelete])

    const handleFormClose = useCallback(() => {
        setFormMode(false)
    }, [])

    // form handlers
    const handleSubmit = useCallback((body) => {
        if (formType === 'add') {
            onPost(
                {
                    begin: currentTime,
                    type: currentAlgorithm.value,
                    ...body
                }
            )
        } else if (formType === 'edit') {
            onPut(
                currentTime,
                {
                    begin: currentTime,
                    type: currentAlgorithm.value,
                    ...body
                }
            )
        }
    }, [onPost, currentTime, currentAlgorithm, formType, onPut])

    // effects
    useEffect(() => {
        setCurrentAlgorithm(actualConverter?.type === 'Navigation.Objects.LinearConverter' ? calculationAlgorithms[0] : calculationAlgorithms[1])
        setCurrentTime(actualConverter?.begin || new Date())
    }, [actualConverter])

    useEffect(() => {
        setActualConverter(converters?.filter(item => !Object.keys(item).includes('end')).shift())
    }, [converters])

    return (
        <div className={styles.converters}>
            <Header
                onAddButtonClick={handleAddClick}
                onEditButtonClick={handleEditClick}
                onDeleteButtonClick={handleDeleteClick}
                onSelectListItem={handleConverterSelect}
                convertersList={convertersList}
                actualConverter={actualConverter}
                loading={loading}
            />
            {formMode && (
                <FormHead
                    list={calculationAlgorithms}
                    activeListItem={currentAlgorithm}
                    onSelect={setCurrentAlgorithm}
                    startTime={currentTime}
                    onTimeSelect={setCurrentTime}
                />
            )}
            {loading && <Spinner size={40} />}
            {!actualConverter && !formMode && (
                <NoConverter />
            )}
            {actualConverter && !formMode && actualConverter?.type === 'Navigation.Objects.TarTable' && (
                <TarTable rows={actualConverter?.rows} />
            )}
            {actualConverter && !formMode && actualConverter?.type === 'Navigation.Objects.LinearConverter' && (
                <LinearConverter k={actualConverter?.k} b={actualConverter?.b} />
            )}
            {formMode && currentAlgorithm?.value === 'Navigation.Objects.TarTable' && (
                <TarTableForm
                    rows={actualConverter?.rows}
                    onSubmit={handleSubmit}
                    onClose={handleFormClose}
                />
            )}
            {formMode && currentAlgorithm?.value === 'Navigation.Objects.LinearConverter' && (
                <LinearConverterForm
                    initialK={actualConverter?.k}
                    initialB={actualConverter?.b}
                    onSubmit={handleSubmit}
                    onClose={handleFormClose}
                />
            )}
        </div>
    )
}

export default Converters;