import React, { useState, useEffect, useLayoutEffect, useReducer } from "react";
import { observer } from 'mobx-react-lite'

import { Classes, Tree, Spinner, ContextMenu, Icon, Intent } from "@blueprintjs/core";

import TableRowContextMenu from './TableRowContextMenu';

import controlDevicesStore from 'stores/controlDevicesStore'

import classes from './MobileObjectControllersTable.module.scss'

const requestFields = '?fields=*,sensors[*, childs[*]],devices[*]'

const useForceUpdate = () => useReducer(value => !value, false)[1];

const forEachNode = (nodes, callback) => {
    if (nodes == null) {
        return;
    }

    for (const node of nodes) {
        callback(node);
        forEachNode(node.childNodes, callback);
    }
}

const getIcon = (dataType, aggregation, isActive) => {
    const intent = isActive ? Intent.PRIMARY : Intent.DANGER
    switch (dataType) {
        case 3:
            switch (aggregation) {
                case 1:
                    return <Icon icon="plus" intent={intent} className={Classes.TREE_NODE_ICON} />
                case 2:
                    return <Icon icon="font" intent={intent} className={Classes.TREE_NODE_ICON} />
                case 3:
                    return <Icon icon="asterisk" intent={intent} className={Classes.TREE_NODE_ICON} />
                default:
                    return <Icon icon="cloud" intent={intent} className={Classes.TREE_NODE_ICON} />
            }
        case 4:
            return <Icon icon="calculator" intent={intent} className={Classes.TREE_NODE_ICON} />
        default:
            return <Icon icon="numerical" intent={intent} className={Classes.TREE_NODE_ICON} />
    }
}

const MobileObjectControllersTable = observer(props => {
    return <MobileObjectControllersTableView 
        {...props}
        getController={controlDevicesStore.getControlDevice}
        loadedControlDevices={controlDevicesStore.loadedControlDevices}
    />
})

const MobileObjectControllersTableView = ({
    controllers = [],
    showDemounted,
    activeFilter,
    realTimeMode,
    selectedItem,
    setSelectedItem,
    shouldLoadControllerId,
    setShouldLoadControllerId,
    setControllerFormType,
    setControllerModalOpen,
    setSensorFormType,
    setSensorModalOpen,
    onDeleteClick,
    onReplaceControllerClick,
    onAddSensorClick,
    setLoading,
    getController,
    loadedControlDevices
}) => {

    const [nodes, setNodes] = useState([])
    const forceUpdate = useForceUpdate();

    const mapSensors = sensor => {
        let className = ''
        if (sensor.dateDelete) {
            className = 'demounted'
        }

        if (activeFilter.id > -1 && sensor.type && sensor.type.id !== activeFilter.id) {
            className = className + ' filtered'
        }
        else if (sensor.className) {
            className = className.replace(' filtered', '')
        }
        if (selectedItem?.id === sensor.id) {
            setSelectedItem({ ...selectedItem, ...sensor })
        }
        return {
            ...sensor,
            label: sensor.displayName,
            icon: getIcon(sensor.dataType, sensor.aggregation, sensor.isSetup),
            className,
            aggregation: sensor.aggregation,
            childNodes: sensor.childs?.map(mapSensors),
            entityType: 'sensor',
            isSelected: selectedItem?.id === sensor.id,
            secondaryLabel: sensor.secondaryLabel,
            isExpanded: sensor.isExpanded || false,
            isVirtual: sensor.dataType === 3,
            childsIds: sensor.childs?.map(item => item.id),
        }
    }

    const mapController = controller => {
        let className = ''
        if (controller.dateDelete) {
            className = 'demounted'
        }
        if (selectedItem?.id === controller.id) {
            setSelectedItem({ ...selectedItem, ...controller })
        }
        const intent = controller.isGPSEnable ? Intent.PRIMARY : Intent.DANGER

        const sensors = controller.sensors ? controller.sensors?.map(mapSensors) : []
        const childs = controller.childs ? controller.childs?.map(mapSensors) : []
        const devices = controller.devices?.length > 0 ? controller.devices?.map(x => mapAdditionalDevices(x, controller.id)) : []

        return {
            ...controller,
            entityType: 'controller',
            icon: <Icon icon="cube" intent={intent} className={Classes.TREE_NODE_ICON} />,
            label: controller.displayName,
            childNodes: [...sensors, ...childs, ...devices],
            className,
            isSelected: selectedItem?.id === controller.id,
            isExpanded: controller.isExpanded || false,
            devices: controller.devices?.length ? controller.devices?.map(x => mapAdditionalDevices(x, controller.id)) : []
        }
    }

    const mapAdditionalDevices = (additionalDevice, parentId) => {
        return {
            ...additionalDevice,
            parentId,
            label: additionalDevice.displayName,
            icon: 'add',
            entityType: 'additionalDevice'
        }
    }

    const setLoadingNode = nodeData => {
        nodeData.secondaryLabel = <Spinner size={20} />
        forceUpdate()
    }

    const setExpandedNode = (nodeData, loadedData) => {
        const newSensors = loadedData?.sensors?.map(mapSensors) || []
        const newChilds = loadedData?.childs?.map(mapSensors) || []
        const newDevices = (Array.isArray(loadedData?.devices) && loadedData?.devices?.map(x => mapAdditionalDevices(x, nodeData.id))) || []
        nodeData.childNodes = [
            ...newSensors,
            ...newChilds,
            ...newDevices
        ]
        nodeData.secondaryLabel = null
        nodeData.isExpanded = true
        forceUpdate()
    }

    const handleNodeClick = (nodeData, _nodePath, e) => {
        setSelectedItem(nodeData)

        if (!e.shiftKey) {
            forEachNode(nodes, n => (n.isSelected = false));
        }
        nodeData.isSelected = true;
    };

    const handleNodeCollapse = nodeData => {
        nodeData.isExpanded = false;
        forceUpdate();
    };

    const handleNodeExpand = async nodeData => {
        let currentEntity;
        let getLoadedEntityes;

        if (nodeData.entityType === 'controller') {
            getLoadedEntityes = () => loadedControlDevices;
        } else if (nodeData.entityType === 'sensor') {
            getLoadedEntityes = () => loadedControlDevices.find(item => item.id === nodeData.device.id).sensors;
        }
        currentEntity = getLoadedEntityes().find(item => item.id === nodeData.id);

        if (currentEntity) {
            setExpandedNode(nodeData, currentEntity)
        } else {
            setLoadingNode(nodeData)
            const newController = await getController(nodeData.id, requestFields)
            setExpandedNode(nodeData, newController)
        }
    };

    const handleContextMenu = (nodeData, _nodePath, e) => {
        e.preventDefault();
        handleNodeClick(nodeData, _nodePath, e)
        ContextMenu.show(TableRowContextMenu(
            nodeData,
            setControllerFormType,
            setControllerModalOpen,
            setSensorFormType,
            setSensorModalOpen,
            onDeleteClick,
            onReplaceControllerClick,
            onAddSensorClick
        ), {
            left: e.clientX,
            top: e.clientY,
        });
    }

    // object refresh
    useEffect(() => {
        if (!controllers) return
        const newnodes = controllers.map(item => {
            const prevNode = nodes?.find(node => node?.id === item.id)
            const prevFields = prevNode ? ({
                isExpanded: prevNode?.isExpanded,
                childNodes: prevNode?.childNodes,
                sensors: prevNode?.sensors
            }) : null
            return ({
                ...mapController(item),
                ...prevFields,
            })
        })
        setNodes(newnodes)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controllers])

    // controller refresh
    useLayoutEffect(() => {
        if (shouldLoadControllerId) {
            getController(shouldLoadControllerId, requestFields)
                .then(newController => {
                    const currentControllerIndex = nodes.findIndex(item => item.id === shouldLoadControllerId)
                    const prevNode = nodes.find(item => item.id === shouldLoadControllerId)
                    nodes[currentControllerIndex] = {
                        ...mapController(newController),
                        isExpanded: prevNode?.isExpanded,
                    }
                    setShouldLoadControllerId('')

                    if (selectedItem.entityType === 'controller' && selectedItem.id === newController.id) {
                        setSelectedItem({
                            ...mapController(newController),
                            isExpanded: selectedItem.isExpanded
                        })
                    }
                    if (selectedItem.entityType === 'sensor' && newController.sensors.map(sensor => sensor.id).includes(selectedItem.id)) {
                        setSelectedItem({
                            ...mapSensors(newController.sensors.find(sensor => sensor.id === selectedItem.id)),
                            isExpanded: selectedItem.isExpanded
                        })
                    }
                    setLoading(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldLoadControllerId, setShouldLoadControllerId, nodes])

    // realTimeMode, activeFilter change
    useLayoutEffect(() => {
        forEachNode(nodes, n => {
            if (realTimeMode) {
                n.secondaryLabel = n.lastValue?.dateTime
            }
            else if (!realTimeMode) {
                n.secondaryLabel = ''
            }
            if (activeFilter.id > -1 && n.type && n.type.id !== activeFilter.id) {
                n.className = n.className.includes('filtered') ? n.className : n.className + ' filtered'
            }
            else if (n.className) {
                n.className = n.className.replace(' filtered', '')
            }
        });
        setNodes(nodes)
        forceUpdate()
    }, [realTimeMode, activeFilter, nodes, forceUpdate])

    const demountedClassName = showDemounted ? classes.showDemounted : classes.hideDemounted

    return (
        <div className={classes.wrapper}>
            {nodes && (
                <Tree
                    contents={nodes}
                    onNodeClick={handleNodeClick}
                    onNodeCollapse={handleNodeCollapse}
                    onNodeExpand={handleNodeExpand}
                    onNodeContextMenu={handleContextMenu}
                    className={[Classes.ELEVATION_0, demountedClassName, classes.tree]}
                />
            )}
        </div>
    )
}

export default MobileObjectControllersTable;