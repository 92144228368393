import React from 'react'
import moment from 'moment';

import { MenuItem, Button } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import { DateInput, TimePrecision } from "@blueprintjs/datetime";

import DateFormatString from 'constants/locales';

function renderCalculationAlgorithms(dataType, { handleClick }) {
    return (
        <MenuItem key={dataType.index}
            onClick={e => handleClick(e.target.value)}
            text={dataType.name}
        />
    );
};

const getMomentFormatter = (format) => {
    return {
        formatDate: (date, locale) => moment(date).locale(locale).format(format),
        parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
        placeholder: format,
    }
}

const offset = new Date().getTimezoneOffset() * 60000

const FormHead = ({ list, activeListItem, onSelect, startTime, onTimeSelect }) => {

    const handleTimeChange = time => {
        const newTime = new Date((new Date(time) - offset)).toISOString().slice(0, -5)
        onTimeSelect(newTime)
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px 0' }}>
            <Select className="select"
                filterable={false}
                itemRenderer={renderCalculationAlgorithms}
                onItemSelect={onSelect}
                items={list}
                activeItem={activeListItem}
            >
                <Button rightIcon="caret-down">
                    {activeListItem.name}
                </Button>
            </Select>
            <DateInput
                dayPickerProps={{
                    months: DateFormatString.months,
                    weekdaysLong: DateFormatString.weekDaysLong,
                    weekdaysShort: DateFormatString.weekDaysShort,
                    firstDayOfWeek: 1
                }}
                timePrecision={TimePrecision.MINUTE}
                onChange={time => handleTimeChange(time)}
                value={new Date(startTime)}
                locale="RU-ru"
                {...getMomentFormatter('DD.MM.YYYY : HH.mm')}
            />
        </div>
    )
}

export default FormHead;