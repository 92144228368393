import styled from 'styled-components'

const LeftPanel = styled.div`
    display: ${props => props.isLeftPanelActive ? 'inherit;' : 'none;'}
    grid-area: left;
    min-width: 300px;

    .bp3-tab-list {
        justify-content: space-around;
        -webkit-box-shadow: 0px -6px 6px -3px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 0px -6px 6px -3px rgba(34, 60, 80, 0.2);
        box-shadow: 0px -6px 6px -3px rgba(34, 60, 80, 0.2);
        padding-top: 10px;
    }

    .bp3-tab-list > *:not(:last-child) {
        margin-right: 0;
    }

    .bp3-breadcrumbs {
        padding: 0 8px;
    }

    .bp3-tab {
        flex-grow: 1;
        flex-shrink: 0;
        text-align: center;
        padding-bottom: 5px;
    }
`;

const Tabs = styled.div`
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    .bp3-tab-panel {
        margin-top: 0;
        height: calc(100vh - 80px);
    }
`;

const ListContainer = styled.div`
    height: calc(100vh - 110px);
`;

export const Styled = {
    LeftPanel, Tabs, ListContainer
}