import React, { useState } from "react";
import "moment/locale/ru";
import {
  Dialog,
  Classes,
  FormGroup,
  Button,
  Checkbox,
  InputGroup,
  Intent
} from "@blueprintjs/core";
import { DateInput, TimePrecision } from "@blueprintjs/datetime";

import DateFormatString from "./locales";
import DeviceProtocolSelect from "./DeviceProtocolSelect";
import SensorPrototypeList from "./SensorPrototypeList";
import { useSelect, useTextInput, useCheckbox, useCheckboxArray } from 'tools/inputHandlers';

import styles from "./ControllerModal.module.scss";

const dateInputProps = {
  dayPickerProps: {
    locale: "ru",
    months: DateFormatString.months,
    weekdaysLong: DateFormatString.weekDaysLong,
    weekdaysShort: DateFormatString.weekDaysShort,
    firstDayOfWeek: 1
  },
  formatDate: date =>
    date.toLocaleString("ru-RU", {
      year: "numeric",
      month: "2-digit",
      day: "numeric"
    }),
  parseDate: str => new Date(str),
  placeholder: "М/ДД/ГГГГ",
  timePrecision: TimePrecision.MINUTE,
  showActionsBar: true,
  todayButtonText: "Текущая дата",
  clearButtonText: "Очистить",
};

const ControllerForm = ({ onSubmit, isLoading, currentController, objectId, handleClose, requestType }) => {

  const [protocol, setDeviceProtocol] = useSelect(currentController?.protocol.id || 0);
  const [gpsAddr, setGPSAddress] = useTextInput(currentController?.gpsAddr || "");
  const [sim, setSIM] = useTextInput(currentController?.sim || "");
  const [siM2, setSIM2] = useTextInput(currentController?.siM2 || "");
  const [dateCreate, setDateCreate] = useState(currentController?.dateCreate ? new Date(currentController?.dateCreate) : new Date());
  const [dateDelete, setDateDelete] = useState(currentController?.dateDelete ? new Date(currentController?.dateDelete) : new Date());
  const [demounted, setDemounted] = useCheckbox(Boolean(currentController?.dateDelete));
  const [isGPSEnable, setGPSEnabled] = useCheckbox(currentController ? currentController?.isGPSEnable : true);
  const [sensorPrototypes, setSensorPrototypes] = useCheckboxArray(currentController?.sensorPrototypes || []);
  const [sensorPrototypesWithOptions, setSensorPrototypesWithOptions] = useState([])
  const ID = currentController?.id
  const tid = currentController?.tid

  const onSubmitForm = () => {
    onSubmit({
      mobileObject: objectId,
      protocol: protocol.toString(),
      gpsAddr,
      sim,
      siM2,
      dateCreate,
      demounted,
      dateDelete: demounted ? dateDelete : null,
      isGPSEnable,
      sensorPrototypes: [
        ...sensorPrototypes.map(_ => JSON.parse(_)),
        ...sensorPrototypesWithOptions.map(({ id, number }) => ({ SensorPrototype: id, number }))
      ]
    })
  }

  return <>
    <div className={Classes.DIALOG_BODY}>
      <div className={styles.wrapper}>
        <div className={styles.controls}>
          <FormGroup inline={true} label="Тип" labelFor="deviceProtocolSelect">
            <DeviceProtocolSelect value={protocol} onChange={setDeviceProtocol} />
          </FormGroup>

          <FormGroup inline={true} label="Адрес GPS" labelFor="gpsAdress" helperText="Обязательное поле">
            <InputGroup name="gpsAddr" value={gpsAddr} onChange={setGPSAddress} />
          </FormGroup>

          <FormGroup inline={true} label="SIM карта" labelFor="sim">
            <InputGroup name="sim" value={sim} onChange={setSIM} />
          </FormGroup>

          <FormGroup inline={true} label="SIM карта 2" labelFor="siM2">
            <InputGroup name="siM2" value={siM2} onChange={setSIM2} />
          </FormGroup>

          <FormGroup inline={true} label="Дата установки" labelFor="dateCreate">
            <DateInput {...dateInputProps}
              name="dateCreate"
              value={dateCreate}
              onChange={setDateCreate}
            />
          </FormGroup>

          <FormGroup inline={true}>
            <Checkbox inline={true} label="Демонтирован" checked={demounted} onChange={setDemounted} />
            {demounted && <DateInput {...dateInputProps}
              name="dateDelete"
              value={dateDelete}
              onChange={setDateDelete}
            />}
          </FormGroup>

          <Checkbox inline={true} label="Активен" checked={isGPSEnable} onChange={setGPSEnabled} />

          <FormGroup inline={true} label="ID:" labelFor="controllerId">
            <InputGroup disabled value={ID} id="controllerId" />
          </FormGroup>
          <FormGroup inline={true} label="EGTS TID:" labelFor="egtstID">
            <InputGroup value={tid} disabled id="egtstID" />
          </FormGroup>
        </div>
        {(requestType === 'post' || requestType === 'replace') && (
          <div className={styles['sensor-prototypes']}>

            <div className={styles['sensor-prototypes--label']}>Датчики</div>

            <SensorPrototypeList className={styles['sensor-prototypes--list']}
              deviceProtocolId={protocol}
              selectedItems={sensorPrototypes}
              onChange={setSensorPrototypes}
              sensorPrototypesWithOptions={sensorPrototypesWithOptions}
              setSensorPrototypesWithOptions={setSensorPrototypesWithOptions}
            />

          </div>
        )}
      </div>

    </div>
    <div className={Classes.DIALOG_FOOTER}>
      <div className={Classes.DIALOG_FOOTER_ACTIONS}>
        <Button
          disabled={isLoading || !gpsAddr}
          onClick={onSubmitForm} intent={Intent.SUCCESS}
          icon='floppy-disk'
        >
          Сохранить
          </Button>
        <Button
          disabled={isLoading}
          onClick={handleClose}
          intent={Intent.DANGER}
          icon='undo'
        >
          Отмена
          </Button>
      </div>
    </div>
  </>
}

function ControllerModal({ currentController, isOpen, handleClose, objectId, onSubmit, requestType, isLoading }) {

  return (
    <Dialog
      isOpen={isOpen}
      className={styles.dialog}
      icon="add"
      onClose={handleClose}
      title={requestType === 'post' ? "Добавление контроллера" : "Редактирование контроллера"}
      canOutsideClickClose={!isLoading}
    >
      {(requestType === 'post' || requestType === 'replace') && (
        <ControllerForm
          onSubmit={onSubmit}
          isSubmitting={isLoading}
          objectId={objectId}
          handleClose={handleClose}
          requestType={requestType}
        />
      )}
      {requestType === 'put' && currentController && (
        <ControllerForm
          onSubmit={onSubmit}
          isSubmitting={isLoading}
          currentController={currentController}
          objectId={objectId}
          handleClose={handleClose}
          requestType={requestType}
        />
      )}
    </Dialog>
  );
}

export default ControllerModal;