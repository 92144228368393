import styled  from 'styled-components'

export const CenterSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Styled = {
  CenterSpinner
}