import React from 'react';
import { Icon } from "@blueprintjs/core";
import unknownPNG from 'assets/img/mapMarkers/Unknown.png'
import largeCity5to10mPNG from 'assets/img/mapMarkers/LargeCity5to10m.png'
import largeCity1to2mPNG from 'assets/img/mapMarkers/LargeCity1to2m.png'
import smallCity50to100kPNG from 'assets/img/mapMarkers/SmallCity50to100k.png'
import smallCity5to10kPNG from 'assets/img/mapMarkers/SmallCity5to10k.png'
import fastFoodPNG from 'assets/img/mapMarkers/FastFood.png'
import bedAndBreakfstPNG from 'assets/img/mapMarkers/BedAndBreakfst.png'
import сampingPNG from 'assets/img/mapMarkers/Camping.png'
import schoolPNG from 'assets/img/mapMarkers/School.png'
import liveTheatrePNG from 'assets/img/mapMarkers/LiveTheatre.png'
import parkOrGardenPNG from 'assets/img/mapMarkers/ParkOrGarden.png'
import museumPNG from 'assets/img/mapMarkers/Museum.png'
import libraryPNG from 'assets/img/mapMarkers/Library.png'
import landmarkPNG from 'assets/img/mapMarkers/Landmark.png'
import zooOrAquariumPNG from 'assets/img/mapMarkers/ZooOrAquarium.png'
import sportsOrFitnessCenterPNG from 'assets/img/mapMarkers/SportsOrFitnessCenter.png'
import barOrNightclubPNG from 'assets/img/mapMarkers/BarOrNightclub.png'
import сinemaPNG from 'assets/img/mapMarkers/Cinema.png'
import iceSkartingPNG from 'assets/img/mapMarkers/IceSkarting.png'
import swimmingPoolPNG from 'assets/img/mapMarkers/SwimmingPool.png'
import shoppingCenterPNG from 'assets/img/mapMarkers/ShoppingCenter.png'
import pharmacyPNG from 'assets/img/mapMarkers/Pharmacy.png'
import apparelPNG from 'assets/img/mapMarkers/Apparel.png'
import houseAndGardenPNG from 'assets/img/mapMarkers/HouseAndGarden.png'
import specialityRetailPNG from 'assets/img/mapMarkers/SpecialityRetail.png'
import autoFielPNG from 'assets/img/mapMarkers/AutoFiel.png'
import autoRepairPNG from 'assets/img/mapMarkers/AutoRepair.png'
import dealerOrAutoPartsPNG from 'assets/img/mapMarkers/DealerOrAutoParts.png'
import parkingPNG from 'assets/img/mapMarkers/Parking.png'
import carWashPNG from 'assets/img/mapMarkers/CarWash.png'
import airTransportationPNG from 'assets/img/mapMarkers/AirTransportation.png'
import groundTransportationPNG from 'assets/img/mapMarkers/GroundTransportation.png'
import postOfficePNG from 'assets/img/mapMarkers/PostOffice.png'
import bankPNG from 'assets/img/mapMarkers/Bank.png'
import personalServicesPNG from 'assets/img/mapMarkers/PersonalServices.png'
import policeStationPNG from 'assets/img/mapMarkers/PoliceStation.png'
import hospital1PNG from 'assets/img/mapMarkers/Hospital1.png'
import cityHallPNG from 'assets/img/mapMarkers/CityHall.png'
import courtHousePNG from 'assets/img/mapMarkers/CourtHouse.png'
import fireDepartamentPNG from 'assets/img/mapMarkers/FireDepartament.png'
import housePNG from 'assets/img/mapMarkers/House.png'
import bridgePNG from 'assets/img/mapMarkers/Bridge.png'
import cemeteryPNG from 'assets/img/mapMarkers/Cemetery.png'
import crossingPNG from 'assets/img/mapMarkers/Crossing.png'
import minePNG from 'assets/img/mapMarkers/Mine.png'
import towerPNG from 'assets/img/mapMarkers/Tower.png'
import tunnelPNG from 'assets/img/mapMarkers/Tunnel.png'
import wellPNG from 'assets/img/mapMarkers/Well.png'
import waterfallPNG from 'assets/img/mapMarkers/Waterfall.png'
import glacierPNG from 'assets/img/mapMarkers/Glacier.png'
import lakePNG from 'assets/img/mapMarkers/Lake.png'
import springPNG from 'assets/img/mapMarkers/Spring.png'
import archPNG from 'assets/img/mapMarkers/Arch.png'
import forestPNG from 'assets/img/mapMarkers/Forest.png'
import rockPNG from 'assets/img/mapMarkers/Rock.png'
import summitPNG from 'assets/img/mapMarkers/Summit.png'

export const markersToAdd = [
  { title: 'Маркеры, Красный', icon: <Icon icon="map-marker" color="red" />, type: 1000 },
  { title: 'Маркеры, Оранжевый', icon: <Icon icon="map-marker" color="orange" />, type: 1001 },
  { title: 'Маркеры, Желтый', icon: <Icon icon="map-marker" color="yellow" />, type: 1002 },
  { title: 'Маркеры, Зеленый', icon: <Icon icon="map-marker" color="green" />, type: 1003 },
  { title: 'Маркеры, Синий', icon: <Icon icon="map-marker" color="blue" />, type: 1004 },
  { title: 'Маркеры, Фиолетовый', icon: <Icon icon="map-marker" color="purple" />, type: 1005 },
  { title: 'Маркеры, Розовый', icon: <Icon icon="map-marker" color="pink" />, type: 1006 },
  { title: 'Маркеры, Белый', icon: <Icon icon="map-marker" color="white" />, type: 1007 },
  { title: 'Маркеры, Серый', icon: <Icon icon="map-marker" color="gray" />, type: 1008 },
  { title: 'Маркеры, Черный', icon: <Icon icon="map-marker" color="black" />, type: 1009 },
  { title: 'Тип не определен', icon: unknownPNG, type: 0 },
  { title: 'Нас. пункты, Мегаполис (5-10 млн.)', icon: largeCity5to10mPNG, type: 1 },
  { title: 'Нас. пункты, Мегаполис (1-2 млн.)', icon: largeCity1to2mPNG, type: 2 },
  { title: 'Нас. пункты, Город (50-100 тыс.)', icon: smallCity50to100kPNG, type: 3 },
  { title: 'Нас. пункты, Населенный пункт (5-10 тыс.)', icon: smallCity5to10kPNG, type: 4 },
  { title: 'Нас. пункты, Населенный пункт (2-5 тыс.)', icon: smallCity5to10kPNG, type: 5 },
  { title: 'Надписи, Крупное государство', icon: <Icon icon="label" color="black" />, type: 6 },
  { title: 'Надписи, Малое государство', icon: <Icon icon="label" color="black" />, type: 7 },
  { title: 'Надписи, Район округа', icon: <Icon icon="label" color="black" />, type: 8 },
  { title: 'Надписи, Надпись', icon: <Icon icon="label" color="black" />, type: 9 },
  { title: 'Предприятия питания. Предприятие питания', icon: fastFoodPNG, type: 10 },
  { title: 'Предприятия питания. Ресторан (деликатесы, торты, пироги)', icon: fastFoodPNG, type: 11 },
  { title: 'Предприятия питания. Ресторан быстрого питания', icon: fastFoodPNG, type: 12 },
  { title: 'Предприятия питания. Ресторан (Итальянская кухня)', icon: fastFoodPNG, type: 13 },
  { title: 'Предприятия питания. Кафе', icon: fastFoodPNG, type: 14 },
  { title: 'Гостиницы, Гостиница', icon: bedAndBreakfstPNG, type: 15 },
  { title: 'Гостиницы, Отель или мотель', icon: bedAndBreakfstPNG, type: 16 },
  { title: 'Гостиницы, Мини гостиница', icon: bedAndBreakfstPNG, type: 17 },
  { title: 'Гостиницы, Кемпинг', icon: сampingPNG, type: 18 },
  { title: 'Образование, Школа', icon: schoolPNG, type: 19 },
  { title: 'Культура досуг, Объект культуры досуга', icon: liveTheatrePNG, type: 20 },
  { title: 'Культура досуг, Парк Культуры и Отдыха', icon: parkOrGardenPNG, type: 21 },
  { title: 'Культура досуг, Музей', icon: museumPNG, type: 22 },
  { title: 'Культура досуг, Библиотека', icon: libraryPNG, type: 23 },
  { title: 'Культура досуг, Достопримечательность', icon: landmarkPNG, type: 24 },
  { title: 'Культура досуг, Парк/Сад', icon: parkOrGardenPNG, type: 25 },
  { title: 'Культура досуг, Зоопарк/Аквариум', icon: zooOrAquariumPNG, type: 26 },
  { title: 'Культура досуг, Стадион', icon: sportsOrFitnessCenterPNG, type: 27 },
  { title: 'Развлечения, Театр', icon: liveTheatrePNG, type: 28 },
  { title: 'Развлечения, Бар/Ночной клуб', icon: barOrNightclubPNG, type: 29 },
  { title: 'Развлечения, Кинотеатр', icon: сinemaPNG, type: 30 },
  { title: 'Спорт, Лыжный центр курорт', icon: сinemaPNG, type: 31 },
  { title: 'Спорт, Каток', icon: iceSkartingPNG, type: 32 },
  { title: 'Спорт, Бассейн', icon: swimmingPoolPNG, type: 33 },
  { title: 'Спорт, Спортзал/Фитнесс-центр', icon: sportsOrFitnessCenterPNG, type: 34 },
  { title: 'Торговля, Торговый объект', icon: shoppingCenterPNG, type: 35 },
  { title: 'Торговля, Универмаг', icon: shoppingCenterPNG, type: 36 },
  { title: 'Торговля, Продовольственный магазин', icon: shoppingCenterPNG, type: 37 },
  { title: 'Торговля, Торговый центр', icon: shoppingCenterPNG, type: 38 },
  { title: 'Торговля, Аптека', icon: pharmacyPNG, type: 39 },
  { title: 'Торговля, Одежда', icon: apparelPNG, type: 40 },
  { title: 'Торговля, Товары для дома и сада', icon: houseAndGardenPNG, type: 41 },
  { title: 'Торговля, Мебель/Товыры для дома', icon: shoppingCenterPNG, type: 42 },
  { title: 'Торговля, Специализированный магазин', icon: specialityRetailPNG, type: 43 },
  { title: 'Торговля, Компьютеры/ПО', icon: specialityRetailPNG, type: 44 },
  { title: 'Автотранспорт, АЗС', icon: autoFielPNG, type: 45 },
  { title: 'Автотранспорт, Автосервис', icon: autoRepairPNG, type: 46 },
  { title: 'Автотранспорт, Автомагазин', icon: dealerOrAutoPartsPNG, type: 47 },
  { title: 'Автотранспорт, Автостоянка', icon: parkingPNG, type: 48 },
  { title: 'Автотранспорт, Автомойка', icon: carWashPNG, type: 49 },
  { title: 'Транспорт, Аэровокзал', icon: airTransportationPNG, type: 50 },
  { title: 'Транспорт, Станция/Остановка наземного транспорта', icon: groundTransportationPNG, type: 51 },
  { title: 'Транспорт, Остановка общественного транспорта', icon: groundTransportationPNG, type: 52 },
  { title: 'Услуги, Почтовое отделение', icon: postOfficePNG, type: 53 },
  { title: 'Услуги, Банк', icon: bankPNG, type: 54 },
  { title: 'Услуги, Служба быта (прачечная, химчистка)', icon: personalServicesPNG, type: 55 },
  { title: 'Гос. и спасательные службы, Отделение полиции', icon: policeStationPNG, type: 56 },
  { title: 'Гос. и спасательные службы, Больница', icon: hospital1PNG, type: 57 },
  { title: 'Гос. и спасательные службы, Мэрия', icon: cityHallPNG, type: 58 },
  { title: 'Гос. и спасательные службы, Суд', icon: courtHousePNG, type: 59 },
  { title: 'Гос. и спасательные службы, Государственное учреждение', icon: cityHallPNG, type: 60 },
  { title: 'Гос. и спасательные службы, Пожарная часть', icon: fireDepartamentPNG, type: 61 },
  { title: 'Прочие объекты, Дом', icon: housePNG, type: 62 },
  { title: 'Искусственные объекты, Мост', icon: bridgePNG, type: 63 },
  { title: 'Искусственные объекты, Кладбище', icon: cemeteryPNG, type: 64 },
  { title: 'Искусственные объекты, Перекресток/переправа/перевал', icon: crossingPNG, type: 65 },
  { title: 'Искусственные объекты, Больница', icon: hospital1PNG, type: 66 },
  { title: 'Искусственные объекты, Шахта/Рудник', icon: minePNG, type: 67 },
  { title: 'Искусственные объекты, Башня/Вышка', icon: towerPNG, type: 68 },
  { title: 'Искусственные объекты, Начало/Окончание тоннеля', icon: tunnelPNG, type: 69 },
  { title: 'Искусственные объекты, Питьевая вода/родник/колодец', icon: wellPNG, type: 70 },
  { title: 'Гидрография, Водопад', icon: waterfallPNG, type: 71 },
  { title: 'Гидрография, Ледник', icon: glacierPNG, type: 72 },
  { title: 'Гидрография, Озеро', icon: lakePNG, type: 73 },
  { title: 'Гидрография, Родник', icon: springPNG, type: 74 },
  { title: 'Наземные объекты, Арка', icon: archPNG, type: 75 },
  { title: 'Наземные объекты, Берег', icon: landmarkPNG, type: 76 },
  { title: 'Наземные объекты, Лес', icon: forestPNG, type: 77 },
  { title: 'Наземные объекты, Скала', icon: rockPNG, type: 78 },
  { title: 'Наземные объекты, Вершина холма или горы', icon: summitPNG, type: 79 },
]