import * as React from 'react';
import axios from 'axios';
import { Checkbox, Icon, Intent, Button, Spinner, MenuItem, Navbar, Tabs, Tab, Alignment } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Select } from "@blueprintjs/select";
import { showToasterS } from 'tools/AppToaster'

import styles from './LibsView.module.scss';

function versionItemRenderer(item, { handleClick, modifiers }) {
    return <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={item.version}
        text={item.version}
        label={item.date.toLocaleDateString()}
        onClick={handleClick}
        textClassName={item.current ? styles.VersionCurrent : undefined}
        icon={item.installedVersion ? IconNames.SMALL_TICK : undefined}
    />;
}

class LibCard extends React.Component {
    constructor(props) {
        super(props);

        let selectedVersion = props.lib.updateVersion;

        if (props.lib.allVersions.length > 0)
            selectedVersion = props.lib.allVersions[0].version;

        this.state = {
            selectedVersion,
            disabled: false
        };
    }

    render() {
        const { lib } = this.props;
        const { disabled, selectedVersion } = this.state;
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <p>
                                <Checkbox checked={lib.checked} onChange={this.props.onCheck} label={lib.assemblyName} inline disabled={disabled} />
                                {lib.removing
                                    ? (<Icon icon={IconNames.DELETE} iconSize={Icon.SIZE_STANDARD} intent={Intent.DANGER} />)
                                    : (lib.version && (lib.version !== lib.updateVersion))
                                        ? (<Icon icon={IconNames.UPLOAD} iconSize={Icon.SIZE_STANDARD} intent={Intent.PRIMARY} />)
                                        : null
                                }
                            </p>
                            <p>{lib.description}</p>
                        </div>
                        <div className="col-md-6">
                            <div className="container">
                                {(!!lib.installedVersion || !!lib.updateVersion) && (
                                    <div className="row" style={{ marginBottom: 16 }}>
                                        <div className="col">{lib.removing ? "Удаление:" : "Установлено:"}</div>
                                        <div className="col-md-5">{lib.displayVersion}</div>
                                        <div className="col-md-4">{lib.removing
                                            ? (<Button text="Отменить" fill onClick={this.handleRestoreClick} disabled={disabled} />)
                                            : (<Button text="Удалить" fill onClick={this.handleRemoveClick} disabled={disabled} />) }</div>
                                    </div>
                                )}

                                {!!lib.allVersions && (
                                    <div className="row">
                                        <div className="col">Версия:</div>
                                        <div className="col-md-5">
                                            <Select
                                                items={lib.allVersions}
                                                itemRenderer={versionItemRenderer}
                                                onItemSelect={this.onVersionSelect}
                                                popoverProps={{ minimal: true }}
                                                filterable={false}
                                                disabled={disabled}
                                            >
                                                <Button text={selectedVersion} rightIcon="caret-down" />
                                            </Select>
                                        </div>
                                        <div className="col-md-4">
                                            <Button text="Установить" fill onClick={this.handleInstallClick} disabled={disabled || selectedVersion === lib.version} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
            </React.Fragment>
        );
    }

    onVersionSelect = (it) => this.setState({ selectedVersion: it.version });

    handleRemoveClick = () => {
        const { assemblyName } = this.props.lib;
        this.setState({ disabled: true });

        axios.post('/api/sys/modules/remove', { assemblyName })
            .then(() => {
                this.setState({ disabled: false });
                this.props.onChange();
            });
    };

    handleRestoreClick = () => {
        const { assemblyName } = this.props.lib;
        this.setState({ disabled: true });

        axios.post('/api/sys/modules/restore', { assemblyName })
            .then(() => {
                this.setState({ disabled: false });
                this.props.onChange();
            });
    };

    handleInstallClick = () => {
        const { assemblyName } = this.props.lib;
        const version = this.state.selectedVersion;
        this.setState({ disabled: true });

        axios.post('/api/sys/modules/install', { assemblyName, version })
            .then(() => {
                this.setState({ disabled: false });
                this.props.onChange();
            });
    };
}

export default class LibsView extends React.Component {
    state = {
        isLoading: true,
        libs: null,
        allLibs: null,
        allSelected: false,
        showUnstable: false,
        checkAll: false,
        navbarTabId: "Update"
    }

    constructor(props) {
        super(props);

        this.update();
    }

    render() {
        if (this.state.isLoading)
            return <Spinner style={{ height: 600 }} />;

        const { libs, showUnstable, checkAll, navbarTabId } = this.state;
        const isUpdate = navbarTabId === "Update";

        return (
            //<div style={{ height: "100%" }}>
            <React.Fragment>
                <Navbar style={{ position: "sticky", top: 0 }}>
                    <Navbar.Group>
                        <Tabs
                            large={true}
                            onChange={this.tabChange}
                            selectedTabId={navbarTabId}
                            defaultSelectedTabId="Update"
                        >
                            <Tab id="Update" title="Обновление" />
                            <Tab id="Installed" title="Установлено" />
                            <Tab id="Browse" title="Обзор" />
                        </Tabs>
                    </Navbar.Group>
                    <Navbar.Group align={Alignment.RIGHT}>
                        <Navbar.Heading>
                            <Button text="Применить изменения и перезагрузить" intent={Intent.DANGER} onClick={() => this.updateServer()} />
                        </Navbar.Heading>
                    </Navbar.Group>
                    <Navbar.Group align={Alignment.RIGHT}>
                        <Navbar.Heading>
                            <Checkbox label="Предварительные выпуски" checked={showUnstable} onChange={() => this.toggleUnstable()} inline />
                        </Navbar.Heading>
                    </Navbar.Group>
                    {isUpdate &&
                    <Navbar.Group align={Alignment.RIGHT}>
                        <Navbar.Heading>
                            <Checkbox label="Выделить все" checked={checkAll} onChange={() => this.toggleCheckAll()} inline />
                            <Button text="Обновить (не реализовано)" />
                        </Navbar.Heading>
                        <Navbar.Divider />
                    </Navbar.Group>
                    }
                </Navbar>
                <div className="container-fluid" style={{ padding: "32px 16px", maxWidth: 1000 }}>
                    <div className="content-body">
                        {libs.map(l => <LibCard key={l.assemblyName}
                            lib={l}
                            onChange={() => this.update()}
                            onCheck={() => this.onLibCheck(l)}
                        />)}
                    </div>
                </div>
            </React.Fragment>
        );
    }

    tabChange = (navbarTabId) => {
        this.setState({
            navbarTabId,
            libs: this.filterLibs(this.state.allLibs, navbarTabId)
        });
    }

    filterLibs(allLibs, navbarTabId) {
        switch (navbarTabId) {
            case "Update":
                return allLibs.filter(l => (!!l.installedVersion || l.updateVersion)  // Установлен или будет установлен
                    && ((!l.updateVersion && l.latestVersion !== l.installedVersion)  // Установленная отличается от последней актуальной
                    || ((!!l.updateVersion && l.latestVersion !== l.updateVersion)))) // Обновление отличается от последней актуальной
            case "Installed":
                return allLibs.filter(l => !!l.installedVersion || l.updateVersion)
            case "Browse":
                return allLibs
            default:
                return []
        }
    }

    toggleUnstable() {
        const showUnstable = !this.state.showUnstable;

        this.setState({ showUnstable });

        this.loadModules(showUnstable);
    }

    onLibCheck(lib) {
        lib.checked = !lib.checked;
        this.setState({ libs: this.state.libs, checkAll: false });
    }

    toggleCheckAll() {
        const { libs } = this.state;
        const checkAll = !this.state.checkAll;
        libs.forEach(l => l.checked = checkAll);
        this.setState({ libs, checkAll });
    }

    update() {
        this.loadModules(this.state.showUnstable);
    };

    loadModules(unstable) {
        axios.get(`/api/sys/modules?unstable=${unstable}`)
            .then(response => response.data.result)
            .then(result => {
                result.libs.forEach(l => l.allVersions.forEach(v => v.date = new Date(v.date)));

                this.setState({
                    isLoading: false,
                    allLibs: result.libs,
                    libs: this.filterLibs(result.libs, this.state.navbarTabId)
                });
            });
    }

    updateServer() {
        axios.post('/api/sys/modules/update')
            .then(() => {
                showToasterS("Готово")
            });
    }
}

export const LibsViewPanel = () => <div className={styles.Panel}><LibsView /></div>
