import styled from 'styled-components'

const List = styled.ul`
    list-style: none;
`;

const Item = styled.li`

`;

export const Styled = {
    List, Item
}