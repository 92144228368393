import React, { Suspense } from 'react';
import { QueryClientProvider } from "react-query";
import queryClient from 'tools/queryClient'
import { ThemeProvider } from 'styled-components'
import { Spinner } from '@blueprintjs/core'
import theme from 'theme'


// Use only class component because GoldenLayout can't read FC
class Content extends React.Component {
  render() {
    const Content = this.props.content;
    const contentProps = this.props.contentProps;

    return (
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Spinner />}>
            <Content glContainer={this.props.glContainer} glEventHub={this.props.glEventHub} {...contentProps} />
          </Suspense>
        </ThemeProvider>
      </QueryClientProvider>
    )
  }
};

export default Content;
