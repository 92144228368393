import React, { useState, useEffect, useMemo, useRef } from 'react'
import { NumericInput, Button, Intent } from "@blueprintjs/core";

import Chart from './Chart'

import styles from './Сonverters.module.scss';
import { showToasterD } from 'tools/AppToaster';

const TarTableForm = ({ rows, onSubmit, onClose }) => {
    const [fielsArray, setFieldsArray] = useState([{ analogValue: 0, tarValue: 0 }])

    const inputFileRef = useRef(null)

    const handleInputChange = (index, value, fieldName) => {
        setFieldsArray(fields => {
            return fields?.map((item, itemIndex) => {
                if (itemIndex === index) {
                    return { ...item, [fieldName]: value }
                }
                return item
            })
        })
    }

    const handleFile = file => {
        const reader = new FileReader();
        reader.readAsText(file.target.files[0]);
        reader.onloadend = e => {
            let error
            const res = e.target.result.split('\r\n').map(item => {
                if (!Number(item.split(';')[0] || !Number(item.split(';')[1]))) {
                    error = true
                }
                return {
                    analogValue: Number(item.split(';')[0]),
                    tarValue: Number(item.split(';')[1])
                }
            })
            if (error) {
                showToasterD('Неправильный формат файла')
                return
            }
            setFieldsArray(res)
        };
        reader.onerror = () => showToasterD('Не удалось прочитать файл')
    }

    const handleRemoveField = index => {
        let arr = [...fielsArray];
        arr.splice(index, 1)
        setFieldsArray(arr)
    }

    const handleSubmit = async e => {
        e.preventDefault();
        await onSubmit({ rows: fielsArray })
        onClose()
    }

    useEffect(() => {
        if (!rows) return
        setFieldsArray(rows)
    }, [rows])

    const chartXArray = useMemo(() => {
        return fielsArray?.map(item => item.analogValue)
    }, [fielsArray])

    const chartYArray = useMemo(() => {
        return fielsArray?.map(item => item.tarValue)
    }, [fielsArray])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <table className={styles.tarTableFormTable}>
                    <tbody>
                        {fielsArray?.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <NumericInput
                                        fill={true}
                                        value={item.analogValue}
                                        name="name"
                                        onValueChange={e => handleInputChange(index, e, 'analogValue')}
                                        placeholder=""
                                    />
                                </td>
                                <td>
                                    <NumericInput
                                        fill={true}
                                        value={item.tarValue}
                                        name="name"
                                        onValueChange={e => handleInputChange(index, e, 'tarValue')}
                                        placeholder=""
                                    />
                                </td>
                                <td>
                                    <Button
                                        icon='trash'
                                        intent={Intent.DANGER}
                                        onClick={() => handleRemoveField(index)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px 0' }}>
                    <Button
                        icon='upload'
                        intent={Intent.SUCCESS}
                        onClick={() => inputFileRef.current.click()}>
                        Импортировать .csv
                    </Button>
                    <input ref={inputFileRef} type='file' hidden onChange={e => handleFile(e)} accept={'.csv'} />
                    <Button
                        icon='plus'
                        intent={Intent.PRIMARY}
                        onClick={() => setFieldsArray([...fielsArray, { analogValue: 0, tarValue: 0 }])}>
                        Добавить поле
                    </Button>
                </div>
                <Chart
                    title='График тарировочной таблицы'
                    xArray={chartXArray}
                    yArray={chartYArray}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '15px 0' }}>
                    <Button
                        style={{ marginRight: '1em' }}
                        text='Отменить'
                        icon='undo'
                        onClick={onClose}
                        intent={Intent.DANGER}
                    />
                    <Button
                        text='Сохранить'
                        icon='floppy-disk'
                        type='submit'
                        intent={Intent.SUCCESS}
                    />
                </div>
            </form>
        </>
    )
}

export default TarTableForm;