import { useState, useCallback } from 'react';

export function useTextInput(defaultValue) {
  const [value, setValue] = useState(defaultValue);
  const handler = useCallback(({ target }) => setValue(target.value), []);
  return [value, handler];
}

export function useNumericInput(defaultValue) {
  const [value, setValue] = useState(defaultValue);
  const handler = useCallback(value => setValue(value), []);
  return [value, handler];
}

export function useCheckbox(defaultValue) {
  const [value, setValue] = useState(defaultValue);
  const handler = useCallback(({ target }) => setValue(target.checked), []);
  return [value, handler];
}

export function useSelect(defaultValue) {
  const [value, setValue] = useState(defaultValue);
  const handler = useCallback(value => setValue(value), []);
  return [value, handler];
}

export function useCheckboxArray(defaultValue) {
  const [array, setValue] = useState(defaultValue);
  const handler = useCallback(event => {

    const { checked, value } = event.target;
    if (checked) setValue(_ => ([..._, value]));
    else setValue(_ => {
      const deleteIndex = _.indexOf(value);
      return [..._.slice(0, deleteIndex), ..._.slice(deleteIndex + 1)];
    });

  }, []);
  return [array, handler];
}

export function useDateInput(defaultValue) {
  const [value, setValue] = useState(defaultValue);
  const handler = useCallback(value => setValue(value), []);
  return [value, handler];
}