import { HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";

export let socketConnection;
const subscribers = new Map();

export const buildSocketConnection = async () => {
  console.log('Подключение к WS...');
  const socket = new HubConnectionBuilder()
      .configureLogging(LogLevel.Debug)
      .withUrl('/api/events', {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build()

  await socket.start()
  socketConnection = socket;
  console.log('Подключен к WS')
}

export const stopSocketConnection = async () => {
  if(socketConnection?.connectionStarted) {
    await socketConnection.stop();
    console.log('Отключен от WS');
  }
}

export const subscribeToChannel = async (type, channel, fields, cb) => {
  let listeners = subscribers.get(`${type}:${channel}`);

  if(socketConnection?.connectionStarted) {
    if(listeners === undefined) {
      listeners = new Set([cb]);
      subscribers.set(`${type}:${channel}`, listeners);
      const callback = (...args) => {
        for(const listenerCb of listeners) listenerCb(...args);
      };
      await subscribe(type, channel, fields, callback);
    } else {
      listeners.add(cb);
    }
  }

  return () => {
    listeners.delete(cb);
  };
}

export const unsubscribeFromChannel = async (type, channel, fields) => {
  if(socketConnection?.connectionStarted) {
    subscribers.delete(`${type}:${channel}`);
    await unsubscribe(type, channel, fields);
  }
}

// If type don't have channel put channel equal null
const subscribe = async (type, channel, fields, cb) => {
  socketConnection.on(channel ? `${type}:${channel}` : type, cb);
  await socketConnection.invoke('Subscribe', type, channel, fields);
  console.log(`Подписан на канал ${type}:${channel}`);
}

const unsubscribe = async (type, channel, fields) => {
  socketConnection.off(channel ? `${type}:${channel}` : type);
  await socketConnection.invoke('Unsubscribe', type, channel, fields);
  console.log(`Отписан от канала ${type}:${channel}`);
}