import React, { lazy } from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';
import { addPanel } from 'tools/initGoldenLayout';

const Chart = lazy(() => import('pages/Monitoring/Chart'))
const COM = lazy(() => import('pages/Monitoring/COM'))
const MessagesHistory = lazy(() => import('pages/Monitoring/MessagesHistory'))

const MonitoringMenu = () => {
  return (
    <Menu>
      <MenuItem
        icon='timeline-line-chart'
        text='График показаний датчиков'
        onClick={() => addPanel('График показаний датчиков', Chart, false)}
      />
      <MenuItem
        icon='warning-sign'
        text='Система оперативного мониторинга'
        onClick={() => addPanel('СОМ', COM, true)}
      />
      <MenuItem
        icon='path-search'
        text='История сообщений'
        onClick={() => addPanel('История сообщений', MessagesHistory, true)}
      />
    </Menu>
  )
}

export default MonitoringMenu;
