import React from "react";
import { observer } from 'mobx-react-lite'
import AdditionalObjectAttributesView from './AdditionalObjectAttributesView'
import additionalObjectAttributesStore from 'stores/additionalObjectAttributesStore'

const AdditionalObjectAttributes = observer(({ entityAttributes, targetType, onChange, entityKey, editable }) => {
  const { attributes, getAttributes, postAttribute, putAttribute, deleteAttribute } = additionalObjectAttributesStore
  return (
    <AdditionalObjectAttributesView
      allAttributes={attributes[targetType]?.data}
      allAttributesFetched={attributes[targetType]?.fetched}
      getAttributes={getAttributes}
      postAttribute={postAttribute}
      putAttribute={putAttribute}
      deleteAttribute={deleteAttribute}
      entityAttributes={entityAttributes}
      onChange={onChange}
      targetType={targetType}
      entityKey={entityKey}
      editable={editable}
    />
  )
})

export default AdditionalObjectAttributes;
