import axios from "axios";

export const getAttributes = async (type = '') => {
    const res = await axios.get(`/api/additional-object-attributes/${type}`);
    return res.data.result
}

export const getAttributesTypes = async () => {
    const res = await axios.get(`/api/additional-object-attributes/target-types`);
    return res.data.result
}

export const postAttribute = async body => {
    return await axios.post('/api/additional-object-attributes', body);
}

export const putAttribute = async (id, body) => {
    return await axios.put(`/api/additional-object-attributes/${id}`, body);
}

export const deleteAttribute = async id => {
    return await axios.delete(`/api/additional-object-attributes/${id}`);
}
