import { makeAutoObservable, runInAction } from 'mobx'
import { getMobject, putMobject } from "api/appAPI";
import { showToasterD, showToasterS } from "tools/AppToaster";

const MobjectRequestFields = `?fields=attributes,controlDevices[*, protocol[*]],dateCreate,dateDelete,
displayName,eventSettings,fuelRates,fuelRatesHours,fuelRatesTKm,garageNum,model,
groups,id,insurancePolices,marker,name,stateNum,techInspections,vin, tid`

export class MobileObjectStore {
    constructor() {
        makeAutoObservable(this)
    }

    currentObject = {}
    loading = true

    getMobject = async id => {
        runInAction(() => this.loading = true)
        const res = await getMobject(id, MobjectRequestFields)
        runInAction(() => {
            this.currentObject = res.result
            this.loading = false
        })
    };

    updateMObject = async newData => {
        runInAction(() => this.loading = true)
        const newObject = {
            ...this.currentObject,
            id: this.currentObject && this.currentObject.id,
            name: this.currentObject && this.currentObject.name ? this.currentObject.name : '',
            model: this.currentObject && this.currentObject.model ? this.currentObject.model : '',
            garageNum: this.currentObject && this.currentObject.garageNum ? this.currentObject.garageNum : '',
            stateNum: this.currentObject.stateNum ? this.currentObject.stateNum : '',
            vin: this.currentObject && this.currentObject.vin ? this.currentObject.vin : '',
            marker: this.currentObject && this.currentObject.marker ? this.currentObject.marker : 0,
            periodsSettings: this.currentObject && this.currentObject.periodsSettings ? this.currentObject.periodsSettings : null,
            attributes: this.currentObject && this.currentObject.attributes ? this.currentObject.attributes : {},
            fuelRates: [],
            fuelRatesHours: [],
            fuelRatesTKm: [],
            insurancePolices: [],
            groups: this.currentObject.groups,

            ...newData
        }

        try {
            await putMobject(this.currentObject.id, newObject)
            await this.getMobject(this.currentObject.id)
            showToasterS("Данные сохранены")
        } catch {
            showToasterD("Ошибка обновления объекта")
        }
        runInAction(() => this.loading = false)
    }
}

export default new MobileObjectStore()