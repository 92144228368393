import axios from 'axios';
import { showToasterD } from 'tools/AppToaster'

export const getReportsList = async () => {
    try {
        const res = await axios.get('/api/reports')
        return res.data.result
    } catch (e) {
        showToasterD('Не удалось загрузить списко отчетов')
        console.error('Не удалось загрузить списко отчетов: ', e)
    }
};