import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite'
import { Checkbox, Spinner, Icon, MenuItem, Button, Dialog, Classes, NumericInput } from '@blueprintjs/core';
import { Select } from "@blueprintjs/select";

import deviceProtocolsStore from 'stores/deviceProtocolsStore'
import styles from "./ControllerModal.module.scss";

function renderCalculationAlgorithms(sensorPrototype, { handleClick }) {
  return (
    <MenuItem key={sensorPrototype.id}
      onClick={e => handleClick(e.target.value)}
      text={sensorPrototype.displayName}
    />
  );
};

function normilizeValue(value, max, min) {
  if (isNaN(value)) return min
  if (value < min) return min
  if (value > max) return max
  return value
}

const SensorPrototypeList = observer(props => {
  return (
    <SensorPrototypeListView
      allDeviceProtocols={deviceProtocolsStore.allDeviceProtocols}
      fetchDetailedDeviceProtocol={deviceProtocolsStore.fetchDetailedDeviceProtocol}
      isDetailedProtocolLoading={deviceProtocolsStore.isDetailedProtocolLoading}
      {...props}
    />
  )
})

function SensorPrototypeListView({
  deviceProtocolId,
  className,
  selectedItems,
  onChange,
  sensorPrototypesWithOptions,
  setSensorPrototypesWithOptions,
  allDeviceProtocols,
  fetchDetailedDeviceProtocol,
  isDetailedProtocolLoading
}) {

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentSensorWitnOptions, setCurrentSensorWitnOptions] = useState({ number: 0 })

  function handleModalClose() {
    setIsModalOpen(false)
    setCurrentSensorWitnOptions({})
  }

  function handleModalSubmit() {
    setSensorPrototypesWithOptions([...sensorPrototypesWithOptions, currentSensorWitnOptions])
    setCurrentSensorWitnOptions({})
    setIsModalOpen(false)
  }

  function handleRemoveSensorPrototypeWithOptions(id) {
    setSensorPrototypesWithOptions(sensorPrototypesWithOptions.filter(item => item.id !== id))
  }

  function handleSelect(item) {
    setIsModalOpen(true)
    setCurrentSensorWitnOptions(item)
  }

  // selects device protocol with the specified id
  const deviceProtocol = allDeviceProtocols.find(deviceProtocol => deviceProtocol.id === deviceProtocolId)

  // loads details for the specified device protocol if not already loaded
  // used to obtain a list of sensor prototypes for the specified device protocol;
  useEffect(() => {
    if (isDetailedProtocolLoading || !deviceProtocol) return
    const { detailsFetched, detailsLoading, id } = deviceProtocol;
    if (!detailsFetched && !detailsLoading) {
      fetchDetailedDeviceProtocol(id)
    }
  }, [deviceProtocol, fetchDetailedDeviceProtocol, isDetailedProtocolLoading]);

  const { sensorPrototypes = [], detailsLoading, detailsError } = deviceProtocol || {};

  const sensors = sensorPrototypes.filter(item => item.hasOwnProperty('value'))
  const sensorsWitnOptions = sensorPrototypes.filter(item => !item.hasOwnProperty('value'))

  return (
    <>
      <div className={className}>

        {detailsLoading && <Spinner size={28} />}

        {detailsError && <div className="error-wrapper">
          <Icon icon="error" iconSize={28} />
        </div>}

        {sensorsWitnOptions.length > 0 && (
          <div className={styles['select-wrapper']}>
            <Select className="select"
              filterable={false}
              itemRenderer={renderCalculationAlgorithms}
              onItemSelect={(item) => { handleSelect(item) }}
              items={sensorsWitnOptions}
            >
              <Button icon='plus'>Добавить датчики с преднастройками</Button>
            </Select>
          </div>
        )}

        {sensors.map(
          ({ id, displayName, value }) => (
            <Checkbox key={id}
              label={displayName}
              value={value}
              checked={selectedItems.indexOf(value) !== -1}
              onChange={onChange}
            />
          )
        )}
        {sensorPrototypesWithOptions.map(item => (
          <Checkbox key={item.id}
            label={`${item.displayName.split('<')[0]} ${item.number}`}
            checked
            onChange={() => handleRemoveSensorPrototypeWithOptions(item.id)}
          />
        ))}
      </div>
      <Dialog
        isOpen={isModalOpen}
        icon="remove"
        onClose={handleModalClose}
        title='Введите номер датчика'
        canOutsideClickClose={false}
      >
        <div className={Classes.DIALOG_BODY}>
          <p>{currentSensorWitnOptions?.displayName}</p>
          <NumericInput
            className="input"
            defaultValue={0}
            value={currentSensorWitnOptions.number}
            onValueChange={value => setCurrentSensorWitnOptions({
              ...currentSensorWitnOptions,
              number: normilizeValue(value, currentSensorWitnOptions?.maximum, currentSensorWitnOptions?.minimum)
            })}
            min={currentSensorWitnOptions?.minimum}
            max={currentSensorWitnOptions?.maximum}
          />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={handleModalSubmit} disabled={false}>Ок</Button>
            <Button onClick={handleModalClose} disabled={false}>Отмена</Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default SensorPrototypeList;