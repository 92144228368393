import axios from 'axios'
import { showToasterD } from 'tools/AppToaster'

export const getBrigades = async () => {
    try {
        const res = await axios.get('/api/brigades')
        return res.data.result
    } catch (e) {
        showToasterD('Не удалось получить список бригад')
        console.log('Не удалось получить список бригад: ', e);
    }
}