import React, { useEffect } from "react";
import { observer } from 'mobx-react-lite'

import Dock from "components/Dock";
import NavMenu from "components/NavMenu";
import LeftPanel from "components/LeftPanel";
// import SocketManager from "containers/SocketManager";

import { buildSocketConnection } from 'tools/socketManager';
import AppStore from 'stores/appStore';

import { Styled } from './Layout.styled'

const Layout = observer(() => {
  
  useEffect(() => {
    (async () => await buildSocketConnection())()
  }, []);

  return (
    <Styled.Layout isLeftPanelActive={AppStore.isLeftPanelActive} >
      {/* <SocketManager/> */}
      <NavMenu />
      <LeftPanel />
      <Dock />
    </Styled.Layout>
  )
})

export default Layout;
