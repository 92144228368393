import React, { useState } from "react";
import moment from 'moment';
import "moment/locale/ru";
import { Dialog, Classes, Button, FormGroup, InputGroup, Checkbox, NumericInput, TextArea, Intent } from "@blueprintjs/core";
import { DateInput, TimePrecision } from "@blueprintjs/datetime";

import DateFormatString from 'constants/locales';

import SensorTypesSelect from './SensorTypesSelect';
import DataTypeSelect from './DataTypeSelect';
import AggregationTypesSelect from './AggregationTypesSelect';

import styles from './SensorModal.module.scss'


const getMomentFormatter = (format) => {
    return {
        formatDate: (date, locale) => moment(date).locale(locale).format(format),
        parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
        placeholder: format,
    }
}

const offset = new Date().getTimezoneOffset() * 60000

const SensorForm = ({ currentSensor, onSubmit, controllerId, handleClose }) => {

    const [name, setName] = useState(currentSensor?.name || '');
    const [sensorType, setSensorType] = useState(currentSensor?.type?.id || 0);
    const [isSetup, setIsSetup] = useState(currentSensor ? currentSensor.isSetup : true);
    const [dataType, setDataType] = useState(currentSensor?.dataType || 0);
    const [containerNum, setContainerNum] = useState(currentSensor?.containerNum);
    const [lineNum, setLineNum] = useState(currentSensor?.lineNum);
    const [minValue, setMinValue] = useState(currentSensor?.minValue);
    const [maxValue, setMaxValue] = useState(currentSensor?.maxValue);
    const [isBit, setIsBit] = useState(!!currentSensor?.bitStart);
    const [bitStart, setBitStart] = useState(currentSensor?.bitStart);
    const [bitLength, setBitLength] = useState(currentSensor?.bitLength);
    const [aggregation, setAggregation] = useState(currentSensor?.aggregation || 1);
    const [isFilterByMinMax, setIsFilterByMinMax] = useState(currentSensor?.isFilterByMinMax);
    const [colorEnable, setСolorEnable] = useState(currentSensor?.colorEnable);
    const [colorTH, setСolorTH] = useState(currentSensor?.colorTH);
    const [dateCreate, setDateCreate] = useState(currentSensor?.dateCreate ? new Date(currentSensor?.dateCreate) : new Date());
    const [dateDelete, setDateDelete] = useState(currentSensor?.dateDelete ? new Date(currentSensor?.dateDelete) : null);
    const [comment, setComment] = useState(currentSensor?.comment);
    const [serialNumber, setSerialNumber] = useState(currentSensor?.serialNumber);
    const [group, setGroup] = useState(currentSensor?.group);

    const onSubmitForm = () => {
        onSubmit({
            device: controllerId,
            name,
            type: String(sensorType),
            isSetup,
            dataType,
            [dataType === 3 && 'aggregation']: aggregation,
            containerNum,
            lineNum,
            minValue,
            maxValue,
            isBit,
            bitStart,
            bitLength,
            isFilterByMinMax,
            colorEnable,
            colorTH,
            dateCreate: new Date((new Date(dateCreate) - offset)).toISOString().slice(0, -5),
            dateDelete: dateDelete ? new Date((new Date(dateDelete) - offset)).toISOString().slice(0, -5) : null,
            comment,
            serialNumber,
            group
        })
    }

    return <>
        <div className={Classes.DIALOG_BODY}>
            <FormGroup inline={false} label="Название" labelFor="name">
                <InputGroup name="name" value={name} onChange={e => setName(e.target.value)} />
            </FormGroup>
            <div className={styles.flex}>
                <div className={styles.column}>
                    <FormGroup inline={true} label="Тип" labelFor="sensorTypesSelect">
                        <SensorTypesSelect name="sensorTypesSelect" value={sensorType} onChange={setSensorType} />
                    </FormGroup>
                    <FormGroup inline={true} label="Тип данных" labelFor="dataTypeSelect">
                        <DataTypeSelect name="dataTypeSelect" value={dataType} onChange={setDataType} />
                    </FormGroup>

                    {
                        (dataType === 0 || dataType === 1 || dataType === 2) &&
                        <>
                            <FormGroup inline={true} label="Номер линии" labelFor="lineNum">
                                <NumericInput name="lineNum" value={lineNum} onValueChange={setLineNum} min={1} />
                            </FormGroup>

                            <FormGroup inline={true} label="Битовый" labelFor="isBit">
                                <Checkbox inline={true} checked={isBit} name="isBit" onChange={e => setIsBit(e.target.checked)} />
                                <NumericInput disabled={!isBit} name="bitStart" value={bitStart} onValueChange={setBitStart} />
                                <NumericInput disabled={!isBit} name="bitLength" value={bitLength} onValueChange={setBitLength} />
                            </FormGroup>
                        </>
                    }

                    {
                        (dataType === 3) &&
                        <FormGroup inline={true} label="Агрегирование" labelFor="aggregation">
                            <AggregationTypesSelect name="aggregation" value={aggregation} onChange={setAggregation} />
                        </FormGroup>
                    }

                    <FormGroup inline={true} label="Дата установки" labelFor="dateCreate">
                        <DateInput
                            dayPickerProps={{
                                months: DateFormatString.months,
                                weekdaysLong: DateFormatString.weekDaysLong,
                                weekdaysShort: DateFormatString.weekDaysShort,
                                firstDayOfWeek: 1
                            }}
                            timePrecision={TimePrecision.MINUTE}
                            onChange={setDateCreate}
                            value={dateCreate || null}
                            locale="RU-ru"
                            minDate={new Date(1990, 5, 5)}
                            {...getMomentFormatter('DD.MM.YYYY : HH.mm')}
                        />
                    </FormGroup>
                    <FormGroup inline={true} label="Дата демонтажа" labelFor="dateDelete">
                        <DateInput
                            dayPickerProps={{
                                months: DateFormatString.months,
                                weekdaysLong: DateFormatString.weekDaysLong,
                                weekdaysShort: DateFormatString.weekDaysShort,
                                firstDayOfWeek: 1
                            }}
                            timePrecision={TimePrecision.MINUTE}
                            onChange={setDateDelete}
                            value={dateDelete}
                            locale="RU-ru"
                            {...getMomentFormatter('DD.MM.YYYY : HH.mm')}
                        />
                    </FormGroup>
                </div>

                <div className={styles.column}>
                    <FormGroup inline={true} labelFor="isSetup">
                        <Checkbox label="Активен" name="isSetup" inline={true} checked={isSetup} onChange={e => setIsSetup(e.target.checked)} />
                    </FormGroup>

                    <FormGroup inline={true} label="Номер устройста" labelFor="containerNum">
                        <NumericInput name="containerNum" value={containerNum} onValueChange={setContainerNum} min={1} />
                    </FormGroup>
                    <FormGroup inline={true} label="Минимум" labelFor="minValue">
                        <NumericInput name="minValue" value={minValue} onValueChange={setMinValue} />
                    </FormGroup>
                    <FormGroup inline={true} label="Максимум" labelFor="maxValue">
                        <NumericInput name="maxValue" value={maxValue} onValueChange={setMaxValue} />
                    </FormGroup>

                    <FormGroup inline={true} labelFor="isFilterByMinMax">
                        <Checkbox label="Фильтровать" name="isFilterByMinMax" inline={true} checked={isFilterByMinMax} onChange={e => setIsFilterByMinMax(e.target.checked)} />
                    </FormGroup>
                    <FormGroup inline={true} labelFor="colorEnable">
                        <Checkbox label="Цвет" inline={true} name="colorEnable" checked={colorEnable} onChange={e => setСolorEnable((e.target.checked))} />
                    </FormGroup>
                    {colorEnable && (
                        <FormGroup inline={true} label="Граница цвета" labelFor="colorTH">
                            <NumericInput name="colorTH" value={colorTH} onValueChange={setСolorTH} />
                        </FormGroup>
                    )}
                </div>
            </div>
            <FormGroup inline={false} label="Комментарий" labelFor="comment">
                <TextArea fill name="comment" value={comment} onChange={e => setComment(e.target.value)} />
            </FormGroup>
            <FormGroup inline={false} label="Серийный номер" labelFor="serialNumber">
                <InputGroup name="serialNumber" value={serialNumber} onChange={e => setSerialNumber(e.target.value)} />
            </FormGroup>
            <FormGroup inline={false} label="Группа" labelFor="group">
                <InputGroup name="group" value={group} onChange={e => setGroup(e.target.value)} />
            </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={onSubmitForm} intent={Intent.SUCCESS} icon='floppy-disk'>Сохранить</Button>
                <Button onClick={handleClose} intent={Intent.DANGER} icon='undo'>Отмена</Button>
            </div>
        </div>
    </>
}

const SensorModal = ({ controllerId, isOpen, handleClose, onSubmit, isLoading, currentSensor, requestType }) => {

    return (
        <Dialog
            isOpen={isOpen}
            icon="add"
            onClose={handleClose}
            title={"Форма датчика"}
            className={styles.dialog}
            canOutsideClickClose={!isLoading}
        >
            {requestType === 'post' && (
                <SensorForm
                    onSubmit={onSubmit}
                    controllerId={controllerId}
                    handleClose={handleClose}
                    isLoading={isLoading}
                />
            )}
            {(requestType === 'put' && currentSensor) && (
                <SensorForm
                    currentSensor={currentSensor}
                    onSubmit={onSubmit}
                    controllerId={controllerId}
                    handleClose={handleClose}
                    isLoading={isLoading}
                />
            )}
        </Dialog>
    );
}

export default SensorModal;
