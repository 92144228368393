import styled from 'styled-components'

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 8px;
  
    cursor: pointer;
  
    &:hover {
      background-color: ${props => props.theme.colors.primaryLight};
    }

    &.selected {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        background-color: ${props => props.theme.colors.primary};
        color: #fff;
        padding: 0 8px;
    }
`;

const Icon = styled.span`
    margin-right: 4px;
`;

const Name = styled.p`
    white-space: nowrap;
    margin: 0;
    padding: 0;
`;

export const Styled = {
    Row, Icon, Name
}