import React from 'react';
import { observer } from 'mobx-react-lite'
import { Tabs, Tab, Icon } from '@blueprintjs/core'
import MobileObjectsList from './MobileObjectsList';
import ZonesList from './ZonesList'
import UsersList from './UsersList'
import WorkersList from './WorkersList'
import BrigadesList from './BrigadesList'

import objectsObserverTabsStore from 'stores/objectsObserverTabsStore'
import AppStore from 'stores/appStore';

import { Styled } from './LeftPanel.styled'

const LeftPanel = observer(() => {
  return (
    <Styled.LeftPanel data-ref="panel" isLeftPanelActive={AppStore.isLeftPanelActive}>
      <Styled.Tabs as={Tabs}
        style={{ justifyContent: 'space-around' }}
        id="mobileObjecTabs"
        animate={false}
        onChange={objectsObserverTabsStore.setActiveTab}
        selectedTabId={objectsObserverTabsStore.activeTab}
      >
        <Tab
          id="mobjects"
          title={<Icon icon='drive-time' htmlTitle="Мобильные объекты" />}
          panel={<MobileObjectsList />}
        />
        <Tab
          id="zones"
          title={<Icon icon='polygon-filter' htmlTitle="Зоны" />}
          panel={<ZonesList />}
        />
        <Tab
          id="users"
          title={<Icon icon='user' htmlTitle="Пользователи" />}
          panel={<UsersList />}
        />
        <Tab
          id="workers"
          title={<Icon icon='build' htmlTitle="Работники" />}
          panel={<WorkersList />}
        />
        <Tab
          id="brigades"
          title={<Icon icon='people' htmlTitle="Бригады" />}
          panel={<BrigadesList />}
        />
      </Styled.Tabs>
    </Styled.LeftPanel>
  );
});

export default LeftPanel;
