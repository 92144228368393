import React, { useState, useEffect } from 'react';
import { Icon, Intent, FormGroup, InputGroup, Button, Dialog, Classes } from '@blueprintjs/core'
import { onDragOver, onDragLeave } from 'tools/dragNDrop'
import { NavigationObjectsMobileGroup } from 'constants/attributesTypes'
import { CheckboxTree } from 'components/CheckboxTree'
import { useQuery } from 'react-query';
import { getRoutes } from 'api/routeService'
import { getRoutesTree } from 'tools/getTree'

const routesFields = '?fields=id,name,directory,directionletter,codeModule&groupBy=codeModule'

const UserObjects = ({ dndRef, onDrop, onSetRoutes, value, onDeleteObjectClick }) => {

    const [search, setSearch] = useState('')
    const [filteredValue, setFilteredValue] = useState(value)
    const [isAddRouteDialogOpen, setIsAddRouteDialogOpen] = useState(false)
    const [newRoutes, setNewRoutes] = useState([])

    const { data: allRoutes } = useQuery('allRoutes', async () => {
        const routes = await getRoutes(routesFields)

        return routes
    })

    const _onDragOver = (e) => onDragOver(dndRef.current, e)
    const _onDragLeave = () => onDragLeave(dndRef.current)

    const submitRoutes = async () => {
        if (!newRoutes.length) return

        onSetRoutes(allRoutes.map(item => ({
            ...item,
            items: item.items.filter(i => newRoutes.includes(String(i.id)))
        })))

        setNewRoutes([])
        setIsAddRouteDialogOpen(false)
    }

    useEffect(() => {
        if (!value) return

        setFilteredValue(value.filter(item => item.object.displayName.toLowerCase().includes(search.toLowerCase())))
    }, [search, value])

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                <FormGroup label='Найти:' style={{ flexGrow: 1, marginRight: '15px', maxWidth: '400px' }}>
                    <InputGroup type='text' value={search} onChange={e => setSearch(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Button icon='plus' intent={Intent.PRIMARY} onClick={() => setIsAddRouteDialogOpen(true)}>Добавить маршрут</Button>
                </FormGroup>
            </div>
            <ul className="bp3-list-unstyled modifier">
                {filteredValue?.map((item, index) => (
                    <li key={item.object.id} style={{ display: 'flex', alignItems: 'flex-end', marginBottom: 5, opacity: item.notSaved ? '.5' : '1', textDecoration: item.toRemove ? 'line-through' : 'none' }}>
                        <Icon icon='trash' intent={Intent.DANGER} onClick={() => onDeleteObjectClick(item)} style={{ marginRight: 5, cursor: 'pointer' }} />
                        <img src={item.type === NavigationObjectsMobileGroup ? 'icons/Folder.png' : item.object.imageUrl || 'icons/MObject.png'} alt='no icon' style={{ marginRight: 5 }} />
                        {item.object.displayName}
                    </li>
                ))}
            </ul>
            <div style={{
                width: '100%',
                height: '100px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'lightcoral',
                color: '#fff',
                fontSize: '24px',
                marginTop: '15px'
            }}
                onDrop={onDrop}
                ref={dndRef}
                onDragLeave={_onDragLeave}
                onDragOver={_onDragOver}>
                Перетащите сюда объекты
            </div>
            <Dialog
                isOpen={isAddRouteDialogOpen}
                onClose={() => setIsAddRouteDialogOpen(false)}
                icon="add"
                title='Добавить маршрут'
            >
                <div className={Classes.DIALOG_BODY}>
                    <CheckboxTree
                        nodes={allRoutes ? getRoutesTree(allRoutes) : []}
                        selectedItems={newRoutes}
                        setSelectedItems={setNewRoutes}
                        noCascade={false}
                        showNodeIcon
                        checkModel='leaf'
                    />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={submitRoutes} intent={Intent.SUCCESS}>Добавить</Button>
                        <Button onClick={() => setIsAddRouteDialogOpen(false)} intent={Intent.DANGER}>Отмена</Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default UserObjects;
