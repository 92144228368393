import styled from 'styled-components'

const AdditionalUnit = styled.div`
    ${({ group }) => group && `
        border: 1px solid;
        margin-bottom: 15px;
        max-width: 500px;
    `};
    border-color: ${props => props.theme.colors.primary};
`;

const AdditionalUnitHeader = styled.div`
    padding: 2px 0 2px 10px;
    background: ${props => props.theme.colors.primary};
    font-weight: 600;
    color: #fff;
`;

const AdditionalUnitBody = styled.div`
    padding: 5px 13px;

    label {
      margin-right: 1em;
    }
`;

export const Styled = {
    AdditionalUnit, AdditionalUnitHeader, AdditionalUnitBody
}