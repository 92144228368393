import { makeAutoObservable, runInAction } from "mobx"

import { getSensorTypes } from 'api/appAPI';

import { showToasterD } from 'tools/AppToaster';

const mapSensorTypes = (sensorType) => ({
    ...sensorType,
    lowerCaseName: sensorType.displayName.toLowerCase(),
});

export class SensorTypesStore {

    constructor() {
        makeAutoObservable(this)
    }

    isFetched = false
    isLoading = false
    allSensorTypes = []
    error = null

    fetchSensorTypes = async () => {
        runInAction(() => this.isLoading = true)
        try {
            const res = await getSensorTypes();
            runInAction(() => {
                this.isFetched = true
                this.allSensorTypes = res.map(mapSensorTypes)
            })
        } catch (error) {
            runInAction(() => this.error = error)
            showToasterD('Не удалось загрузить типы датчиков');
        }
        runInAction(() => this.isLoading = false)
    };
}

export default new SensorTypesStore()