import React, { useState } from 'react'
import { FormGroup, NumericInput, Button, Intent } from '@blueprintjs/core';

const LinearConverterForm = ({ initialK, initialB, onSubmit, onClose }) => {
    const [k, setK] = useState(initialK || 0)
    const [b, setB] = useState(initialB || 0)

    const handleSubmit = async () => {
        await onSubmit({ k, b })
        onClose()
    }
    return (
        <div>
            <FormGroup
                inline={true}
                label='k = '
                labelFor='k'
            >
                <NumericInput
                    name='k'
                    id='k'
                    onValueChange={e => setK(e)}
                    value={k}
                />
            </FormGroup>
            <FormGroup
                inline={true}
                label='b = '
                labelFor='b'
            >
                <NumericInput
                    name='b'
                    id='b'
                    onValueChange={e => setB(e)}
                    value={b}
                />
            </FormGroup>
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '15px 0' }}>
                <Button
                    style={{ marginRight: '1em' }}
                    text='Отменить'
                    icon='undo'
                    onClick={onClose}
                    intent={Intent.DANGER}
                />
                <Button
                    text='Сохранить'
                    icon='floppy-disk'
                    onClick={handleSubmit}
                    intent={Intent.SUCCESS}
                />
            </div>
        </div>
    )
}

export default LinearConverterForm;