import React, { useEffect, useState } from 'react';
import { Button } from '@blueprintjs/core';
import { Marker, Popup } from 'react-leaflet';

import { getWeatherMarkers, deleteWeatherMarker } from 'api/mapMarkersService';
import { getWeatherMarkerByType } from '../tools/getWeatherMarkerByType';
import { formatDate } from 'tools/dateManupulation'
import { subscribeToChannel, unsubscribeFromChannel } from 'tools/socketManager'

import { ON_NAV_OBJECT_CHANGED, ON_NAV_OBJECT_REMOVED } from 'constants/socketManager'

const WeatherMarkers = () => {
  const [markers, setMarkers] = useState([])

  useEffect(() => {
    (async () => {
      const markers = await getWeatherMarkers('weather,updateTime,id');

      if(markers) {
        setMarkers(markers);
      }

      await subscribeToChannel(ON_NAV_OBJECT_CHANGED, 'WeatherMarker', 'weather,updateTime,id', newWeatherMarker => {
        setMarkers(prevMarkers => {
          let newMarkers = [...prevMarkers];
          const foundIndex = newMarkers.findIndex(marker => marker.id === newWeatherMarker.id);
  
          if(foundIndex !== -1) {
            newMarkers.splice(foundIndex, 1);
          }
  
          newMarkers.push(newWeatherMarker);
          return newMarkers;
        })
      });
      await subscribeToChannel(ON_NAV_OBJECT_REMOVED, 'WeatherMarker', 'weather,updateTime,id', deletedWeatherMarker => {
        setMarkers(prevMarkers => {
          let newMarkers = [...prevMarkers];
          newMarkers = newMarkers.filter(marker => marker.id !== deletedWeatherMarker.id);
          return newMarkers;
        });
      });
    })()

 

    return async () => {
      await unsubscribeFromChannel(ON_NAV_OBJECT_CHANGED, 'WeatherMarker', 'weather,updateTime,id')
      await unsubscribeFromChannel(ON_NAV_OBJECT_REMOVED, 'WeatherMarker', 'weather,updateTime,id')
    }
  }, [])


  return (
    <>
      {
        markers.length && 
        markers.map(marker => 
          <Marker
            key={marker.id}
            position={[marker.weather.location[1], marker.weather.location[0]]}
            icon={getWeatherMarkerByType(marker.weather.wType, marker.weather.temp)}
          >
            <Popup>
              <div style={{ fontSize: '13px' }}>
                <strong>Погода</strong>
                <div style={{ marginTop: 5, marginBottom: 5 }}>{marker.weather.type}</div>
                <div style={{ marginTop: 5, marginBottom: 5 }}>Температура: {marker.weather.temp} &#176;C</div>
                <div style={{ marginTop: 5, marginBottom: 5 }}>Ощущается как: {marker.weather.feelslike} &#176;C</div>
                <div style={{ marginTop: 5, marginBottom: 5 }}>Влажность: {marker.weather.humidity} &#37;</div>
                <div style={{ marginTop: 5, marginBottom: 5 }}>Ветер: {marker.weather.wind}</div>
                <div style={{ marginTop: 5, marginBottom: 5 }}>Получена: {formatDate(marker.updateTime)}</div>
                <Button intent="danger" onClick={async () => await deleteWeatherMarker(marker.id)}>
                  Удалить погодный маркер
                </Button>
              </div>
            </Popup>
          </Marker>
        )
      }
    </>
  )
}

export default WeatherMarkers;
