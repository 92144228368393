import styled from 'styled-components'

const Warning = styled.div`
    max-width: 320px;
    padding: 1rem 0.5rem;
    display: flex;
    flex-flow: column;
`;

const WarningButton = styled.div`
    margin-top: 1rem;
    b {
        margin-bottom: 0.3rem;
    }
`;

export const Styled = {
    Warning, WarningButton
}