import React from 'react';
import { Menu, MenuItem, MenuDivider } from '@blueprintjs/core';

import AppMap from 'components/AppMap'

import { getMobileObject } from 'api/mobileObjectsService'

import { addPanel } from 'tools/initGoldenLayout';
import { showToasterD } from 'tools/AppToaster';

import { lastPointFields } from 'constants/monitoringCOM';

const MobileObjectContextMenu = (objectId, type, showObject, objectName) => {
    return (
        <Menu>
          <MenuItem 
            icon="map" 
            text="Показать на карте"
            onClick={async () => {
              try {
                const { lastPoint } = await getMobileObject(objectId, `lastPoint[${lastPointFields}]`)
                addPanel('Карта', AppMap, false, { centeredMobileObject: lastPoint, objects: [objectId] });
              } catch {
                showToasterD('Не удалось получить данные объекта!')
              }
            }}
          />
          <MenuItem icon="path-search" text="Показать трек на карте" />
          <MenuItem icon="document-share" text="Выгрузить трек" />
          <MenuItem icon="document" text="Найти заявку в модуле РНИЦ" />
          <MenuItem icon="calendar" text="Создать пользовательское событие">
            <MenuItem icon="calendar" text="Простой" />
            <MenuItem icon="calendar" text="Резерв" />
            <MenuItem icon="calendar" text="Ремонт" />
          </MenuItem>
          <MenuDivider />
          <MenuItem icon="path-search" text="Отправить на маршрут" />
          <MenuDivider />
          <MenuItem icon="cross" text="Удалить" />
          <MenuItem icon="remove" text="Удалить из группы" />
          {type === 'o' ? <>
            <MenuItem icon="drive-time" text="Добавить мобильный объект" />
            <MenuItem icon="add-to-artifact" text="Рейсы: Добавить в датасет" />
            <MenuItem icon="add-to-artifact" text="Заправки: Добавить в датасет" />
            <MenuItem icon="add-to-artifact" text="Сливы: Добавить в датасет" />
            <MenuItem icon="add-to-artifact" text="Топливо: Добавить в датасет" />
            <MenuItem icon="add-to-artifact" text="Вес: Добавить в датасет" />
          </>
          : null}
          
          <MenuDivider />
          <MenuItem icon="key" text="Права на объект" />
          <MenuItem icon="add" text="Права на объект" />
          <MenuItem icon="folder-close" text="Логи">
            <MenuItem text="Стоянки" /> 
            <MenuItem text="Рейсы" />
            <MenuItem text="Заправки" /> 
          </MenuItem>
          <MenuItem icon="split-columns" text="Периоды смен объекта" />
          {type !== 'o' ? null : <MenuItem onClick={() => showObject(objectId, type, objectName)} icon="properties" text="Свойства" />}
      </Menu>
    )
}


export default MobileObjectContextMenu
