import * as React from 'react';
import { Fragment } from 'react';
import axios from 'axios';
import { Icon, Intent, Card, Checkbox, Spinner, Tooltip, Position } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";

import styles from './ModulesView.module.scss';


function getTagInfo(tag) {
    switch (tag) {
        case 'INavDataStore': return ['database', 'Хранилище данных']
        case 'ISMSProvider': return ['sim-card', 'Отправка СМС']
        case 'IHttpModuleRequest': return ['globe-network', 'Обработка HTTP-запросов']
        case 'ITableModule': return ['th', 'Табличные данные']
        case 'INavDataSource': return ['send-to', 'Источник навигационных данных']
        case 'IDeviceControl': return ['send-to-graph', 'Управление устройствами']
        default:
            return undefined
    }
}


function getIcons(tags) {
    if (!tags)
        return undefined

    return tags.map(t => {
        const info = getTagInfo(t)
        if (!info)
            return undefined

        return <Tooltip key={info[0]} content={info[1]} position={Position.TOP}><Icon icon={info[0]} className={styles.TagIcon} /></Tooltip>
    })
}


export class ModuleCard extends React.Component {
    render() {
        const { lib } = this.props;
        return (
            <Card style={{ margin: 16 }}>
                <div className="row">
                    <div className="col">
                        <h6>{lib.assemblyName}</h6>
                    </div>
                    <div className="col-md-auto text-right">
                        {lib.version ? (
                            <p className="bp3-text-muted">{lib.version}</p>
                        ) : !!lib.updateVersion && (
                            <p className="bp3-text-muted">{lib.updateVersion}</p>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <p>{lib.description}</p>
                    </div>
                    <div className="col-md-auto text-right">
                        {lib.version && (
                            <p className="bp3-text-muted"><Icon icon={IconNames.ARROW_UP} iconSize={Icon.SIZE_STANDARD} intent={Intent.PRIMARY} /> {lib.updateVersion}</p>
                        )}
                    </div>
                </div>
                {lib.modules.map(m => <Checkbox key={m.guid} label={<Fragment><span>{m.name}</span>{getIcons(m.tags)}</Fragment>} name={m.guid} />)}
            </Card>
        );
    }
}

export default class ModulesView extends React.Component {
    state = {
        isLoading: true,
        libs: null
    }

    constructor(props) {
        super(props);

        axios.get('/api/sys/settings/modules')
            .then(response => response.data.result)
            .then(result => {
                this.setState({
                    isLoading: false,
                    libs: result.libs
                });
            });
    }

    render() {
        if (this.state.isLoading)
            return <Spinner style={{ height: 600 }} />;

        const { libs } = this.state;

        return (
            <div style={{  height: 600, overflow: "auto" }}>
                {libs.map(l => <ModuleCard key={l.assemblyName} lib={l} /> )}
            </div>
        );
    }
}
