import React, { useState } from 'react';
import { Button, InputGroup, Tooltip, Intent, Dialog, Classes, FormGroup, Checkbox, } from '@blueprintjs/core';
import { useForm } from 'react-hook-form'

import './LoginForm.scss';

const LoginForm = ({ authLogin }) => {
  const [showPassword, setShowPassword] = useState(false);

  const { register, handleSubmit, errors } = useForm()

  const handleLockClick = () => setShowPassword(!showPassword);

  const lockButton = (
    <Tooltip content={`${showPassword ? 'Скрыть' : 'Показать'} пароль`}>
      <Button
        icon={showPassword ? 'eye-open' : 'eye-off'}
        intent={Intent.PRIMARY}
        minimal={true}
        onClick={handleLockClick}
      />
    </Tooltip>
  );

  return (
    <Dialog
      title='Аутентификация'
      isOpen={true}
      backdropClassName="backdrop"
      isCloseButtonShown={false}
    >
      <form onSubmit={handleSubmit(authLogin)}>
        <div className={Classes.DIALOG_BODY}>
          <FormGroup helperText={errors.login && 'Введите логин'} intent={errors.login && Intent.DANGER}>
            <InputGroup name='login' inputRef={register({ required: true })} placeholder='логин' leftIcon='user' />
          </FormGroup>
          <FormGroup helperText={errors.password && 'Введите пароль'} intent={errors.password && Intent.DANGER}>
            <InputGroup
              name='password'
              inputRef={register({ required: true })}
              placeholder='пароль'
              leftIcon='lock'
              rightElement={lockButton}
              type={showPassword ? 'text' : 'password'}
            />
          </FormGroup>
        </div>
        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Checkbox name='rememberMe' inputRef={register} label='Запомнить' inline />
            <Button icon='log-in' intent={Intent.PRIMARY} type='submit'>
              Войти
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
}

export default LoginForm;
