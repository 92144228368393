import axios from 'axios'
import { showToasterD } from 'tools/AppToaster'

export const getZones = async (fields = '') => {
    try {
        const res = await axios.get(`/api/zones?fields=${fields}`)
        return res.data.result
    } catch (e) {
        showToasterD('Не удалось получить список зон')
        console.log('Не удалось получить список зон: ', e);
    }
}

export const getZoneById = async (id, fields = '') => {
    try {
        const res = await axios.get(`/api/zones/${id}?fields=${fields}`)
        return res.data.result;
    } catch {
        showToasterD('Не удалось получить зону!');
    }
}

export const getAdditionalZonesAttributes = async () => {
    try {
        const res = await axios.get('/api/additional-object-attributes/Navigation.Objects.Zone')
        return res.data.result
    } catch {
        showToasterD('Не удалось получить дополнительные аттрибуты!');
    }
}

export const deleteZoneById = async id => {
    try {
        axios.delete(`/api/zones/${id}`)
    } catch {
        showToasterD('Не удалось удалить зону!');
    }
}

export const putZoneState = async (id, zoneState) => {
    try {
        axios.put(`/api/zone-state/${id}`, zoneState)
    } catch {
        showToasterD('Не удалось обновить состояние зоны!');
    }
}

export const putZoneInfo = async (id, zoneInfo) => {
    try {
        axios.put(`/api/zones/${id}`, zoneInfo)
    } catch { 
        showToasterD('Не удалось обновить информацию зоны!');
    }
}

export const getZonesVirtualTableRange = async (from, to) => {
    try {
        return await axios.get(`/api/zones/vtable/range?from=${from}&to=${to}`)
    } catch (e) {
        showToasterD('Не удалось получить список зон')
        console.log('Не удалось получить список зон: ', e);
    }
}