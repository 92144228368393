import styled from 'styled-components'

const Item = styled.li`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2px 5px;

    cursor: pointer;

    &:hover {
        background-color: #ccc;
    }

    img {
        width: 20px;
        height: 20px;
        margin-right: .4em;
    }

    p {
        margin: 0;
    }
`;

export const Styled = {
    Item
}