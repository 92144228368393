import React, { Component } from 'react';
import {
  Button,
  ButtonGroup,
  Tooltip,
  Divider,
  HTMLTable
} from '@blueprintjs/core';

export default class LitresPer100km extends Component {
  render() {
    return (
      <div>
        <ButtonGroup minimal={true}>
          <Tooltip content='Добавить норму расхода'>
            <Button icon='add' intent='success'></Button>
          </Tooltip>
          <Tooltip content='Удалить норму расхода'>
            <Button icon='remove' intent='danger'></Button>
          </Tooltip>
          <Tooltip content='Редактировать норму расхода'>
            <Button icon='edit'></Button>
          </Tooltip>
        </ButtonGroup>
        <Divider />
        <HTMLTable bordered='true'>
          <thead>
            <tr>
              <th>Начало действия</th>
              <th>Значение</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>20.03.2020 12:30:01</td>
              <td>44.5</td>
            </tr>
          </tbody>
        </HTMLTable>
      </div>
    );
  }
}
