import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'

// ws
import { subscribeToChannel, unsubscribeFromChannel, socketConnection } from 'tools/socketManager';
import { ON_NAV_OBJECT_CHANGED } from 'constants/socketManager';

// stores
import { mobileObjectsListStore } from 'stores/objectsObserverListStore'
import { MobileObjectStore } from 'stores/mobileObjectStore'

// view component
import MobileObjectView from './MobileObjectView'

const MobileObject = observer(({ objectId }) => {

    const [store] = useState(new MobileObjectStore()) // для каждого открытого моб. объекта свой стор

    useEffect(() => {
        store.getMobject(objectId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    useEffect(() => {
        if (!socketConnection) return
        
        (async () => await subscribeToChannel(ON_NAV_OBJECT_CHANGED, 'MobileObject', '', item => {
            if (objectId === item.id) {
                store.getMobject(item.id)
            }
        }))()
    
        return async () => {
            await unsubscribeFromChannel(ON_NAV_OBJECT_CHANGED, 'MobileObject', '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    return <MobileObjectView
        currentObject={store.currentObject}
        loading={store.loading}
        refreshObject={store.getMobject}
        updateMObject={store.updateMObject}
        showInList={mobileObjectsListStore.showInList}
        objectId={objectId}
    />
})

export default MobileObject