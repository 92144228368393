import React from 'react';
import { observer } from 'mobx-react-lite'
import { Menu, MenuItem } from '@blueprintjs/core';

import { updateSize } from 'tools/initGoldenLayout';

import AppStore from 'stores/appStore';

const ViewMenu = observer(() => {
  const isFullScreen = AppStore.isFullScreen

  async function toggleFullScreen() {
    if (!isFullScreen) {
      await document.documentElement.requestFullscreen();
      AppStore.updateFullScreen(true)
    } else {
      if (document.exitFullscreen) {
        await document.exitFullscreen();
        AppStore.updateFullScreen(false)
      }
    }

    updateSize(
      document.documentElement.clientWidth - document.querySelector('[data-ref="panel"]').clientWidth, 
      document.documentElement.clientHeight - document.querySelector('[data-ref="main-menu"]').clientHeight
    )
  }

  return (
    <Menu>
      <MenuItem
        icon="panel-table"
        text="Обозреватель объектов"
        active={AppStore.isLeftPanelActive}
        onClick={AppStore.updateLeftPanelActive}
      />
      <MenuItem
        icon={!isFullScreen ? "fullscreen" : "minimize"}
        text={!isFullScreen ? "Во весь экран" : "Уменьшить"}
        label="F11"
        onClick={toggleFullScreen}
      />
    </Menu>
  )
})

export default ViewMenu;