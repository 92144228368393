import styled from 'styled-components'
import { WithIndent } from './HOC'

const mapColumnsRatio = arr => {
    let res = ''
    arr.forEach(i => {
        res += `${i}fr `
    });
    return res
}

const Grid = styled.div`
    display: grid;
    ${({ columns }) => columns && `grid-template-columns: repeat(${columns}, 1fr)`};
    ${({ columnsRatio }) => columnsRatio && `grid-template-columns: ${mapColumnsRatio(columnsRatio)}`};
    grid-column-gap: ${({ colGap }) => !isNaN(colGap) ? `${colGap}px` : '15px'};
    grid-row-gap: ${({ rowGap }) => !isNaN(rowGap) ? `${rowGap}px` : '15px'};
`;

export default WithIndent(Grid)