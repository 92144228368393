export const onMouseDown = e => {
    const el = e.target;
    const innerHeight = e.view.innerHeight;
    const innerWidth = e.view.innerWidth;
    let offsetX, offsetY;

    // You need to write `data-draggable={true}` attribute on your parent element
    if(el.dataset.draggable) {
        offsetX = e.clientX - el.getBoundingClientRect().left;
        offsetY = e.clientY - el.getBoundingClientRect().top;

        document.querySelector('#root').append(el);

        const moveAt = (pageX, pageY) => {
            const left = pageX - offsetX;
            const top = pageY - offsetY;

            if(innerWidth < left + el.offsetWidth) {
                el.style.left = `${innerWidth - el.offsetWidth}px`;
            } else if(left < 0) {
                el.style.left = `0px`;
            } else {
                el.style.left = `${left}px`;
            }

            if(innerHeight < top + el.offsetHeight) {
                el.style.top = `${innerHeight - el.offsetHeight}px`;
            } else if(top < 0) {
                el.style.top = `0px`;
            } else {
                el.style.top = `${top}px`;
            }
        }

        const onMouseMove = event => moveAt(event.pageX, event.pageY);

        moveAt(e.pageX, e.pageY);

        document.addEventListener('mousemove', onMouseMove);

        document.onmouseup = function() {
            document.removeEventListener('mousemove', onMouseMove);
            document.onmouseup = null;
        }
    }
};

export const onDragOver = (ref, event) => {
    ref.classList.add('drag-enter');
    event.preventDefault();
  }

export const onDragLeave = ref => ref.classList.remove('drag-enter');
