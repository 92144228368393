import { makeAutoObservable, runInAction } from "mobx"

import { getDeviceProtocols, getDetailedDeviceProtocol } from 'api/appAPI';

import { showToasterD } from 'tools/AppToaster';


const mapDeviceProtocol = (deviceProtocol) => ({
    ...deviceProtocol,
    lowerCaseName: deviceProtocol.displayName.toLowerCase(),
    sensorPrototypes: [],
    detailsFetched: false,
    detailsLoading: false,
    detailsError: null,
});

function replaceById(array, id, newValueFn) {
    const index = array.findIndex(el => el.id === id);
    array[index] = newValueFn(array[index]);
}

function prepareSensorPrototypes(data) {
    const { displayName, id } = data;

    if (!displayName) return {
        ...data,
        displayName: 'НЕИЗВЕСТНЫЙ',
        value: JSON.stringify({ sensorPrototype: id }),
    };

    if (displayName.slice(-1) === '>') {
        const startIndexOfNumRangeIndicator = displayName.lastIndexOf('<');
        const numRangeStr = displayName.slice(startIndexOfNumRangeIndicator + 1, -1);
        const numberRange = numRangeStr.split('-');
        return {
            ...data,
            displayName: displayName,
            numberRange,
        };
    }

    return {
        ...data,
        value: JSON.stringify({ sensorPrototype: id })
    };
};

export class deviceProtocolsStore {

    constructor() {
        makeAutoObservable(this)
    }

    isFetched = false
    isLoading = false
    isDetailedProtocolLoading = false
    allDeviceProtocols = []
    error = null

    fetchDeviceProtocols = async () => {
        runInAction(() => this.isLoading = true)
        try {
            const data = await getDeviceProtocols();
            runInAction(() => {
                this.allDeviceProtocols = data.map(mapDeviceProtocol)
                this.isFetched = true
            })
        } catch (error) {
            runInAction(() => this.error = error)
            showToasterD('Не удалось загрузить список типов контроллеров');
        }
        runInAction(() => this.isLoading = false)
    };

    fetchDetailedDeviceProtocol = async (protocolId) => {
        runInAction(() => this.isDetailedProtocolLoading = true)
        try {
            const data = await getDetailedDeviceProtocol(protocolId);
            const { id, sensorPrototypes, ...restData } = data;
            replaceById(this.allDeviceProtocols, id, () => ({
                ...restData,
                id,
                sensorPrototypes: sensorPrototypes.map(prepareSensorPrototypes),
                lowerCaseName: restData.displayName.toLowerCase(),
                detailsFetched: true,
                detailsLoading: false,
                detailsError: null,
            }))
        } catch (error) {
            replaceById(this.allDeviceProtocols, protocolId, deviceProtocol => ({
                ...deviceProtocol,
                detailsLoading: false,
                detailsError: error,
            }));
            showToasterD('Не удалось загрузить список датчиков');
        }
        runInAction(() => this.isDetailedProtocolLoading = false)
    };
}

export default new deviceProtocolsStore()