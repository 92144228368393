import React from 'react';
import { Menu, MenuItem } from "@blueprintjs/core";

const TableRowContextMenu = (
    nodeData,
    setControllerFormType,
    setControllerModalOpen,
    setSensorFormType,
    setSensorModalOpen,
    onDeleteClick,
    onReplaceControllerClick,
    onAddSensorClick
) => {
    const isController = nodeData.entityType === 'controller'
    const isSensor = nodeData.entityType === 'sensor'
    const isAdditionalDevice = nodeData.entityType === 'additionalDevice'

    const handleEditClick = () => {
        if (isSensor) {
            setSensorFormType('put')
            setSensorModalOpen(true)
        } else if (isController) {
            setControllerFormType('put')
            setControllerModalOpen(true)
        }
    }
    const handleRemoveClick = () => {
        onDeleteClick()
    }
    const handleReplaceControllerClick = () => {
        onReplaceControllerClick()
    }
    const handleAddSensorClick = () => {
        onAddSensorClick()
    }

    const entityName = isController ? 'контроллер' : isSensor ? 'датчик' : isAdditionalDevice ? 'доп. оборудование' : ''

    return (
        <Menu>
            {!isAdditionalDevice && <>
                <MenuItem icon="edit" text={`Редактировать ${entityName}`} onClick={handleEditClick} />
            </>}
            {isController && <>
                <MenuItem icon="changes" text='Заменить контроллер' onClick={handleReplaceControllerClick} />
                <MenuItem icon="plus" text='Добавить датчик' onClick={handleAddSensorClick} />
            </>}
            <MenuItem icon="trash" text={`Удалить ${entityName}`} onClick={handleRemoveClick} />

        </Menu>
    )
}

export default TableRowContextMenu;