import React from "react";
import { Tabs, Tab, Spinner } from "@blueprintjs/core";

import {
  MobileObjectControllers,
  // MobileObjectEventOptions,
  // MobileObjectFuelCons,
  MobileObjectGroups,
  MobileObjectProps,
  MobileObjectTechService,
} from "./tabs";

import { Styled } from './MobileObject.styled'

const MobileObjectView = ({ currentObject, loading, refreshObject, updateMObject, showInList, objectId }) => {

  const mobileObjectPropsPanel = (
    <MobileObjectProps
      object={currentObject}
      refreshObject={refreshObject}
      onMobjectPropsUpdate={updateMObject}
      showInList={showInList}
    />
  )

  const mobileObjectControllersPanel = (
    <MobileObjectControllers
      controllers={currentObject?.controlDevices}
      objectId={currentObject?.id}
      refreshObject={refreshObject}
    />
  )

  // const mobileObjectFuelConsPanel = (
  //   <MobileObjectFuelCons />
  // )

  // const mobileObjectEventOptionsPanel = (
  //   <MobileObjectEventOptions />
  // )

  const mobileObjectGroupsPanel = (
    <MobileObjectGroups
      groups={currentObject?.groups || []}
    />
  )

  const mobileObjectTechServicePanel = (
    <MobileObjectTechService
      objectId={currentObject?.id}
      onUpdate={updateMObject}
    />
  )

  return (
    <Styled.MobileObject>
      <Styled.Tabs
        as={Tabs}
        id="mobileObjecTabs"
        animate={false}
        renderActiveTabPanelOnly={true}
      >
        <Tab
          id="props"
          title="Свойства объекта"
          panel={loading ? <Spinner /> : mobileObjectPropsPanel}
        />
        <Tab
          id="controllers"
          title="Устройства"
          panel={loading ? <Spinner /> : mobileObjectControllersPanel}
        />
        {/* <Tab
          id="fuelCons"
          title="Нормы расхода топлива"
          panel={loading ? <Spinner /> : mobileObjectFuelConsPanel}
        />
        <Tab
          id="eventOptions"
          title="Настройки событий"
          panel={loading ? <Spinner /> : mobileObjectEventOptionsPanel}
        /> */}
        <Tab
          id="groups"
          title="Группы"
          panel={loading ? <Spinner /> : mobileObjectGroupsPanel}
        />
        <Tab
          id="techService"
          title="Техобслуживание"
          panel={loading ? <Spinner /> : mobileObjectTechServicePanel}
        />
      </Styled.Tabs>
    </Styled.MobileObject>
  );
};

export default MobileObjectView;
