import React, { Component } from 'react';
import { Tab, Tabs, Checkbox } from '@blueprintjs/core';
import HaltEvents from './HaltEvents';
import FuelEvents from './FuelEvents';

export default class MobileObjectEventOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsEnabled: false
    };

    this._handleTabsEnabledChange = this._handleTabsEnabledChange.bind(this);
  }

  _handleTabsEnabledChange() {
    this.setState(state => ({
      tabsEnabled: !state.tabsEnabled
    }));
  }

  render() {
    return (
      <>
        <Checkbox
          checked={this.state.tabsEnabled}
          label='Установить'
          onChange={this._handleTabsEnabledChange}
        />
        <Tabs id='eventOptionTabs' animate={false}>
          <Tab
            id='haltEvents'
            title='Стоянки'
            panel={<HaltEvents contentDisabled={this.state.tabsEnabled} />}
          />
          <Tab
            id='fuelEvents'
            title='Топливо'
            panel={<FuelEvents contentDisabled={this.state.tabsEnabled} />}
          />
        </Tabs>
      </>
    );
  }
}
