import styled from 'styled-components';

export const SelectTileLayer = styled.div`
  > .bp3-select {
    &:after {
      top: 5px;
    }

    > select {
      height: 25px;
    }
  }
`

export const Styled = {
  SelectTileLayer,
}