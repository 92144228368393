import React, { useState } from 'react';
import moment from 'moment';

import { Column, Table, Cell, SelectionModes } from "@blueprintjs/table";
import { Spinner, Button, Icon, Tooltip, Intent, Dialog, Alert, Label, TextArea, Classes } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DateInput, TimePrecision } from "@blueprintjs/datetime";

import DateFormatString from 'constants/locales';

import styles from './MobileObjectTechService.module.scss'

const MobileObjectTechServiceView = ({ techInspections, onAdd, onEdit, onDelete, loading }) => {

    const initialFormvalues = { beginTime: new Date(), description: '' }
    const [formValues, setFormValues] = useState(initialFormvalues)
    const [isPostPutDialogOpen, setIsPostPutDialogOpen] = useState(false)
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [openedFormType, setOpenedFormType] = useState('')
    const [openedInspectionId, setOpenedInspectionId] = useState('')
    const [deletedInspectionId, setDeletedInspectionId] = useState(null)

    const getMomentFormatter = (format) => {
        return {
            formatDate: (date, locale) => moment(date).locale(locale).format(format),
            parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
            placeholder: format,
        }
    }

    const getDiff = (start, finish) => {
        const diffDuration = moment.duration(Math.abs(moment(start).diff(moment(finish))));
        return `${diffDuration.days()}д. ${diffDuration.hours()}ч. ${diffDuration.minutes()}м. ${diffDuration.seconds()}c.`
    }

    const onDateChange = e => {
        setFormValues({
            ...formValues,
            beginTime: e
        })
    }

    const onDescriptionChange = e => {
        setFormValues({
            ...formValues,
            description: e.target.value
        })
    }

    const handleDeleteClick = (inspectionId) => {
        onDelete(inspectionId)
    }

    const handleAddClick = () => {
        setFormValues(initialFormvalues)
        setIsPostPutDialogOpen(true)
        setOpenedFormType('add')
    }

    const handleEditClick = ({ inspectionId, beginTime, description }) => {
        setOpenedInspectionId(inspectionId)
        setFormValues({ beginTime: new Date(beginTime), description })
        setIsPostPutDialogOpen(true)
        setOpenedFormType('edit')
    }

    const handleSubmit = () => {
        const offset = new Date().getTimezoneOffset() * 60000
        const newTime = new Date((new Date(formValues.beginTime) - offset)).toISOString().slice(0, -5)

        if (openedFormType === 'add') {
            onAdd({
                ...formValues,
                beginTime: newTime
            })
        }
        else if (openedFormType === 'edit') {
            onEdit({
                ...formValues,
                inspectionId: openedInspectionId,
                beginTime: newTime,
            })
        }
        setIsPostPutDialogOpen(false)
    }

    const handleDialogClose = () => {
        setIsPostPutDialogOpen(false)
        setOpenedFormType('')
        setOpenedInspectionId('')
    }

    const beginTimeCellRenderer = rowIndex => (
        <Cell className={styles.cell}>
            {moment(techInspections[rowIndex].beginTime).format('DD.MM.YYYY : HH.mm.ss')}
        </Cell>
    )

    const endTimeRenderer = rowIndex => (
        <Cell className={styles.cell}>
            {techInspections[rowIndex].endTime && moment(techInspections[rowIndex].endTime).format('DD.MM.YYYY : HH.mm.ss')}
        </Cell>
    )

    const durationCellRenderer = rowIndex => (
        <Cell className={styles.cell}>
            {techInspections[rowIndex].endTime
                ? getDiff(techInspections[rowIndex].endTime, techInspections[rowIndex].beginTime)
                : getDiff(Date.now(), techInspections[rowIndex].beginTime)}
        </Cell>
    )

    const mileageCellRenderer = rowIndex => (
        <Cell className={styles.cell}>{techInspections[rowIndex].mileage}</Cell>
    )

    const descriptionCellRenderer = rowIndex => (
        <Cell className={styles.cell}>{techInspections[rowIndex].description}</Cell>
    )

    const editDeleteCellRenderer = rowIndex => (
        <Cell className={styles.cell}>
            <Tooltip content="Изменить техобслуживание">
                <Button
                    onClick={() => handleEditClick({
                        inspectionId: techInspections[rowIndex].id,
                        beginTime: techInspections[rowIndex].beginTime,
                        description: techInspections[rowIndex].description,
                    })}
                    className={styles['edit-btn']}
                >
                    <Icon icon={IconNames.EDIT} />
                </Button>
            </Tooltip>
            <Tooltip content="Удалить техобслуживание">
                <Button onClick={() => {
                    setIsDeleteAlertOpen(true)
                    setDeletedInspectionId(techInspections[rowIndex].id)
                }}>
                    <Icon icon={IconNames.DELETE} intent={Intent.DANGER} />
                </Button>
            </Tooltip>
        </Cell>
    )

    return (
        <>
            {techInspections.length > 0 && (
                <div className={styles['table-container']}>
                    <Table
                        numRows={techInspections.length}
                        enableMultipleSelection={false}
                        defaultRowHeight={50}
                        selectionModes={SelectionModes.NONE}
                        fill
                    >
                        <Column name="Дата с" cellRenderer={beginTimeCellRenderer} />
                        <Column name="Дата по" cellRenderer={endTimeRenderer} />
                        <Column name="Длительность" cellRenderer={durationCellRenderer} />
                        <Column name="Пробег (км)" cellRenderer={mileageCellRenderer} />
                        <Column name="Описание" cellRenderer={descriptionCellRenderer} />
                        <Column name="" cellRenderer={editDeleteCellRenderer} />
                    </Table>
                    {loading && (
                        <div className={styles['spinner-container']}>
                            <Spinner />
                        </div>
                    )}
                </div>
            )}
            <Button
                icon={IconNames.ADD}
                intent={Intent.SUCCESS}
                onClick={handleAddClick}
                className={styles['add-btn']}
            >
                Добавить техобслуживание
            </Button>
            <Dialog
                isOpen={isPostPutDialogOpen}
                onClose={handleDialogClose}
                title={openedFormType === 'add' ? 'Добавить техобслуживание' : 'Изменить техобслуживание'}
            >
                <div className='dialog-body-container'>
                    <div className='form-container'>
                        <Label>
                            <div className='label-container'>
                                <span>Дата:</span>
                                <DateInput
                                    dayPickerProps={{
                                        months: DateFormatString.months,
                                        weekdaysLong: DateFormatString.weekDaysLong,
                                        weekdaysShort: DateFormatString.weekDaysShort,
                                        firstDayOfWeek: 1
                                    }}
                                    timePrecision={TimePrecision.SECOND}
                                    onChange={e => onDateChange(e)}
                                    defaultValue={formValues.beginTime}
                                    locale="RU-ru"
                                    {...getMomentFormatter('DD.MM.YYYY : HH.mm.ss')}
                                />
                            </div>
                        </Label>
                        <Label>
                            <div className='label-container'>
                                <span>Описание:</span>
                                <TextArea
                                    value={formValues.description}
                                    onChange={e => onDescriptionChange(e)}
                                    growVertically
                                    fill
                                />
                            </div>
                        </Label>
                    </div>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button
                            onClick={handleSubmit}
                            icon='floppy-disk'
                            intent={Intent.SUCCESS}
                        >
                            Сохранить
                        </Button>
                        <Button
                            onClick={handleDialogClose}
                            intent={Intent.DANGER}
                        >
                            Отмена
                        </Button>
                    </div>
                </div>
            </Dialog>
            <Alert
                cancelButtonText='Отмена'
                confirmButtonText='Удалить'
                icon='trash'
                intent={Intent.DANGER}
                isOpen={isDeleteAlertOpen}
                onCancel={() => setIsDeleteAlertOpen(false)}
                onConfirm={() => {
                    handleDeleteClick(deletedInspectionId)
                    setIsDeleteAlertOpen(false)
                }}
            >
                <p>Вы уверены, что хотите удалить <b>техобслуживание</b>?</p>
            </Alert>
        </>
    )
}

export default MobileObjectTechServiceView;