import React, { useState, useEffect } from 'react';
import { Button, Dialog, Classes, FormGroup, InputGroup, Intent } from "@blueprintjs/core";

const FormModal = ({ isOpen, onClose, initialData, onSubmit }) => {
    const [dataType, setDataType] = useState(initialData?.dataType || '')
    const [nameRu, setNameRu] = useState(initialData?.nameRu || '')
    const [nameEn, setNameEn] = useState(initialData?.nameEn || '')
    const [code, setCode] = useState(initialData?.code || '')
    const [groupRu, setGroupRu] = useState(initialData?.groupRu || '')
    const [groupEn, setGroupEn] = useState(initialData?.groupEn || '')

    useEffect(() => {
        setDataType(initialData?.dataType || 0)
        setNameRu(initialData?.nameRu || '')
        setNameEn(initialData?.nameEn || '')
        setCode(initialData?.code || '')
        setGroupRu(initialData?.groupRu || '')
        setGroupEn(initialData?.groupEn || '')
    }, [initialData])

    const onSubmitForm = e => {
        e.preventDefault()
        onSubmit({
            dataType: +dataType,
            nameRu,
            nameEn,
            code,
            groupRu,
            groupEn,
        })
    }
    return (
        <Dialog
            isOpen={isOpen}
            icon='add'
            onClose={onClose}
            title='Добавление атрибута'
        >
            <form onSubmit={onSubmitForm}>
                <div className={Classes.DIALOG_BODY}>
                    <FormGroup inline label="Тип:" labelFor="type">
                        <InputGroup
                            id="type"
                            value={dataType}
                            onChange={e => setDataType(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup inline label="Имя (Ru):" labelFor="nameRu">
                        <InputGroup
                            id="nameRu"
                            value={nameRu}
                            onChange={e => setNameRu(String(e.target.value))}
                        />
                    </FormGroup>
                    <FormGroup inline label="Имя (En):" labelFor="nameEn">
                        <InputGroup
                            id="nameEn"
                            value={nameEn}
                            onChange={e => setNameEn(String(e.target.value))}
                        />
                    </FormGroup>
                    <FormGroup inline label="Код:" labelFor="code">
                        <InputGroup
                            id="code"
                            value={code}
                            onChange={e => setCode(String(e.target.value))}
                        />
                    </FormGroup>
                    <FormGroup inline label="Группа (Ru):" labelFor="groupRu">
                        <InputGroup
                            id="groupRu"
                            value={groupRu}
                            onChange={e => setGroupRu(String(e.target.value))}
                        />
                    </FormGroup>
                    <FormGroup inline label="Группа (En):" labelFor="groupEn">
                        <InputGroup
                            id="groupEn"
                            value={groupEn}
                            onChange={e => setGroupEn(String(e.target.value))}
                        />
                    </FormGroup>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button type='submit' intent={Intent.SUCCESS}>Подтвердить</Button>
                        <Button onClick={onClose} intent={Intent.DANGER}>Отмена</Button>
                    </div>
                </div>
            </form>
        </Dialog>
    );
}

export default FormModal;
