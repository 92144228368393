import axios from 'axios';

import { showToasterD, showToasterS } from 'tools/AppToaster';

export const getWeatherMarkers = async fields => {
  try {
    const markers = await axios.get(`/api/weather-markers?fields=${fields}`);

    return markers.data.result
  } catch {
    showToasterD('Не удалось получить погодные маркеры!')
  }
}

export const createWeatherMarker = async location => {
  try {
    const markers = await axios.post(`/api/weather-markers`, { location });

    showToasterS('Погодный маркер был установлен!')

    return markers.data.result
  } catch {
    showToasterD('Не удалось установить погодный маркер!')
  }
}

export const deleteWeatherMarker = async id => {
  try {
    const markers = await axios.delete(`/api/weather-markers/${id}`);

    showToasterS('Погодный маркер был удален!')

    return markers.data.result
  } catch {
    showToasterD('Не удалось удалить погодный маркер!')
  }
}

export const getMarkers = async fields => {
  try {
    const markers = await axios.get(`/api/markers?fields=${fields}`);

    return markers.data.result
  } catch {
    showToasterD('Не удалось получить маркеры!')
  }
}

export const createNewMarker = async (location, type, text) => {
  try {
    const markers = await axios.post(`/api/markers`, {
      location,
      type,
      text,
    });

    showToasterS('Новая точка была установлена!')

    return markers.data.result
  } catch {
    showToasterD('Не удалось установить новую точку!')
  }
}

export const deleteMarker = async id => {
  try {
    const markers = await axios.delete(`/api/markers/${id}`);

    showToasterS('Точка была удалена!')

    return markers.data.result
  } catch {
    showToasterD('Не удалось удалить точку!')
  }
}