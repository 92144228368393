import React from 'react';
import { InputGroup } from '@blueprintjs/core';

const SearchBar = () => {
  return (
    <InputGroup
      leftIcon={"search"}
      placeholder="Найти..."
      round={false}
      small={true}
      type={"search"}
    />
  )
};

export default SearchBar;