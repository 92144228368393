import React, { useState } from 'react'

import { Button, Tooltip, MenuItem, Alert, Intent } from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';

import styles from "./Сonverters.module.scss";

const renderSelectItem = (item, { handleClick }) => {
    return (
        <MenuItem
            key={item.index}
            label={item.displayName}
            onClick={handleClick}
            shouldDismissPopover={true}
        />
    );
}

const Header = ({
    onAddButtonClick,
    onEditButtonClick,
    onDeleteButtonClick,
    onSelectListItem,
    convertersList,
    actualConverter,
    loading,
}) => {
    const [isAlertOpen, setIsAlertOpen] = useState(false)

    const onConfirmDelete = () => {
        onDeleteButtonClick()
        setIsAlertOpen(false)
    }
    return (
        <div style={{ display: 'flex' }}>
            <Tooltip content={'Добавить тарировку'}>
                <Button
                    icon='plus'
                    minimal
                    onClick={onAddButtonClick}
                    disabled={loading}
                />
            </Tooltip>
            <Tooltip content={'Редактировать тарировку'}>
                <Button
                    icon='edit'
                    minimal
                    onClick={onEditButtonClick}
                    disabled={loading || !actualConverter}
                />
            </Tooltip>
            <Select
                className={styles.select}
                id='filterSelect'
                items={convertersList}
                filterable={false}
                itemRenderer={renderSelectItem}
                noResults={<MenuItem disabled={true} text='No results.' />}
                onItemSelect={item => onSelectListItem(item)}
            >
                <Button
                    text={actualConverter?.displayName}
                    rightIcon='caret-down'
                    disabled={loading}
                />
            </Select>
            <Tooltip content={'Удалить тарировку'}>
                <Button
                    onClick={() => setIsAlertOpen(true)}
                    icon='trash'
                    minimal
                    disabled={loading}
                ></Button>
            </Tooltip>
            <Alert
                cancelButtonText='Отмена'
                confirmButtonText='Удалить тарировку'
                icon='trash'
                intent={Intent.DANGER}
                isOpen={isAlertOpen}
                onCancel={() => setIsAlertOpen(false)}
                onConfirm={onConfirmDelete}
            >
                <p>Вы уверены, что хотите удалить <b>тарировку</b>?</p>
            </Alert>
        </div>
    )
}

export default Header;