import * as React from "react";
import axios from "axios";
import {
  Button,
  Switch,
  Spinner,
  NumericInput,
  Classes,
  Label,
  H4,
  H5,
  Collapse,
  MenuItem,
  TextArea,
  Dialog,
  Intent,
} from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

import ModulesView from "containers/system/ModulesView";

import styles from "./ServerSettings.module.scss";

function enumItemRenderer(item, { handleClick, modifiers }) {
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      label={item.value}
      key={item.value}
      text={item.name}
      onClick={handleClick}
    />
  );
}

function enumText(variants, val) {
  return variants.find((v) => v.value === val).name;
}

class EnumSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  render() {
    const { name, variants } = this.props;
    return (
      <Select
        items={variants}
        itemRenderer={enumItemRenderer}
        onItemSelect={this.onItemSelect}
        popoverProps={{ minimal: true }}
        filterable={false}
        name={name}
      >
        <Button
          text={enumText(variants, this.state.value)}
          rightIcon="double-caret-vertical"
        />
      </Select>
    );
  }

  onItemSelect = (it) => this.setState({ value: it.value });
}

class SettingsInput extends React.Component {
  constructor(props) {
    super(props);

    const prop = props.prop;
    if (prop.type === "Boolean") {
      this.state = { checked: prop.value };
    }
  }

  render() {
    const { prop, module } = this.props;

    const id = "conf-" + module + "-" + prop.name;
    switch (prop.type) {
      case "String":
        return (
          <input
            className={Classes.INPUT}
            id={id}
            name={prop.name}
            defaultValue={prop.value}
            style={{ width: "100%" }}
          />
        );
      case "Boolean":
        return (
          <React.Fragment>
            <input name={prop.name} type="hidden" value={this.state.checked} />
            <Switch
              id={id}
              checked={this.state.checked}
              onChange={() => this.setState({ checked: !this.state.checked })}
            />
          </React.Fragment>
        );
      case "Int32":
        return (
          <NumericInput
            id={id}
            name={prop.name}
            defaultValue={prop.value}
            fill
            allowNumericCharactersOnly
          />
        );
      case "UInt32":
        return (
          <NumericInput
            id={id}
            name={prop.name}
            defaultValue={prop.value}
            fill
            allowNumericCharactersOnly
            min={0}
          />
        );
      case "Double":
        return (
          <NumericInput
            id={id}
            name={prop.name}
            defaultValue={prop.value}
            fill
          />
        );
      case "Enum":
        return <EnumSelect {...prop} />;
      case "String[]":
        return (
          <TextArea
            id={id}
            name={prop.name}
            growVertically={true}
            defaultValue={prop.value}
            fill
          />
        );
      default:
        return (
          <span>
            ({prop.type}){prop.value}
          </span>
        );
    }
  }
}

function PropTable({ prop, module }) {
  return (
    <React.Fragment>
      {prop.map((s) => (
        <div key={s.name}>
          <H5>{s.name}</H5>
          {s.properties.map((p) => (
            <div className="row pb-1" key={p.name}>
              <div className="col-sm-8">
                <Label htmlFor={"conf-" + module + "-" + p.name}>
                  {p.displayName}
                  <p className="bp3-text-muted">{p.description}</p>
                </Label>
              </div>
              <div className="col-sm-4">
                <SettingsInput prop={p} module={module} />
              </div>
            </div>
          ))}
        </div>
      ))}
    </React.Fragment>
  );
}

class GlobalPanel extends React.Component {
  state = {
    isOpen: false,
  };

  render() {
    const { prop } = this.props;
    return (
      <section>
        <div className={styles.PropHeader}>
          <H4>Общие</H4>
          <Button onClick={this.handleClick}>
            {this.state.isOpen ? "Свернуть" : "Развернуть"}
          </Button>
        </div>
        <Collapse isOpen={this.state.isOpen}>
          <form onSubmit={this.handleSubmit}>
            <fieldset>
              <PropTable prop={prop} module="global" />
            </fieldset>

            <div className={styles.SettingsFooter}>
              <Button intent={Intent.SUCCESS} text="Сохранить" type="submit" />
            </div>
          </form>
        </Collapse>
      </section>
    );
  }

  handleClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    var properties = {};
    formData.forEach((value, key) => (properties[key] = value));

    axios
      .post("/api/sys/settings/global", { properties })
      .then(() => this.props.onChange());
  };
}

class ModulePanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      isActive: props.module.active,
    };
  }

  render() {
    const { module } = this.props;
    const moduleEnableId = "module-" + module.id + "-enable";
    return (
      <section>
        <hr />
        <div className={styles.PropHeader}>
          <H4 className={this.state.isActive ? "" : Classes.TEXT_DISABLED}>
            {module.name}
          </H4>
          <Button onClick={this.expandClick}>
            {this.state.isOpen ? "Свернуть" : "Развернуть"}
          </Button>
        </div>
        <Collapse isOpen={this.state.isOpen}>
          <form onSubmit={this.handleSubmit}>
            <fieldset>
              <div className="row pb-1">
                <div className="col-sm-8">
                  <Label htmlFor={moduleEnableId}>
                    <H5>Активен</H5>
                  </Label>
                </div>
                <div className="col-sm-4">
                  <Switch
                    id={moduleEnableId}
                    checked={this.state.isActive}
                    onChange={this.activeChange}
                  />
                </div>
              </div>

              <PropTable prop={module.properties} module={module.id} />
            </fieldset>

            <div className={styles.SettingsFooter}>
              <Button intent={Intent.SUCCESS} text="Сохранить" type="submit" />
              <Button
                intent={Intent.DANGER}
                text="Удалить"
                onClick={this.removeClick}
              />
            </div>
          </form>
        </Collapse>
      </section>
    );
  }

  expandClick = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  activeChange = (e) => {
    this.setState({ isActive: e.target.checked });
  };

  removeClick = () => {
    const module = this.props.module.id;
    axios.post("/api/sys/settings/remove", { module }).then(() => {
      this.props.onChange();
    });
  };

  handleSubmit = (event) => {
    const module = this.props.module.id;

    event.preventDefault();
    const formData = new FormData(event.target);

    var properties = {};
    formData.forEach((value, key) => (properties[key] = value));

    const active = this.state.isActive;

    axios
      .post("/api/sys/settings/module", { module, properties, active })
      .then(() => this.props.onChange());
  };
}

export default class ServerSettings extends React.Component {
  state = {
    isLoading: true,
    settings: null,
    addingModule: false,
  };

  constructor(props) {
    super(props);

    this.updateSettings();
  }

  render() {
    if (this.state.isLoading) return <Spinner />;

    return (
      <div className="content-wrapper">
        <div
          className="container-fluid"
          style={{ padding: "0 16px", maxWidth: 1000 }}
        >
          <div className="content-body">
            <GlobalPanel
              prop={this.state.settings.globalSettings}
              onChange={this.updateSettings}
            />
            {this.state.settings.modulesSettings.map((m) => (
              <ModulePanel
                key={m.id}
                module={m}
                onChange={this.updateSettings}
              />
            ))}

            <div className={styles.SettingsFooter}>
              <Button icon="add" onClick={this.openAddModleDialog}>
                Добавить модуль
              </Button>
            </div>

            <Dialog
              title="Добавление модуля"
              isOpen={this.state.addingModule}
              onClose={this.closeAddModleDialog}
            >
              <form onSubmit={this.handleAddModuleSubmit}>
                <ModulesView />
                <div className={Classes.DIALOG_FOOTER}>
                  <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button
                      type="submit"
                      intent={Intent.PRIMARY}
                      text="Добавить"
                    />
                  </div>
                </div>
              </form>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }

  updateSettings = () => {
    axios
      .get("/api/sys/settings")
      .then((response) => response.data.result)
      .then((result) => {
        this.setState({
          isLoading: false,
          settings: result,
        });
      });
  };

  openAddModleDialog = () => this.setState({ addingModule: true });

  closeAddModleDialog = () => this.setState({ addingModule: false });

  handleAddModuleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    var modules = [];
    formData.forEach((value, key) => {
      if (value === "on") modules.push(key);
    });

    axios.post("/api/sys/settings/add", { modules }).then(() => {
      this.closeAddModleDialog();
      this.updateSettings();
    });
  };
}
