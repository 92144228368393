import styled from 'styled-components';
import { Button } from "@blueprintjs/core";

export const ClipButton = styled(Button)`
  svg > path {
    color: #5c7080;
  }
`

export const Styled = {
  ClipButton
}