import React, { useEffect, useState, useMemo } from 'react';
import MobileObjectControllersTable from './MobileObjectControllersTable';
import Converters from './Converters';
import MobileObjectControllersHeader from './MobileObjectControllersHeader';
import ControllerModal from './ControllerModal';
import SensorModal from './SensorModal';
import DeleteModal from './DeleteModal';

const noFilter = { id: -1, name: 'Нет фильтра', displayName: 'Нет фильтра' }

const MobileObjectControllersView = ({
    controllers,
    objectId,
    refreshObject,
    allSensorTypes,
    sensorTypesIsFetched,
    sensorTypesIsLoading,
    fetchSensorTypes,
    addSensor,
    addSensorByPrototype,
    deleteSensor,
    putSensor,
    postSensorConverter,
    putSensorConverter,
    deleteSensorConverter,
    fetchDeviceProtocols,
    fetchDetailedDeviceProtocol,
    deviceProtocolsIsFetched,
    allDeviceProtocols,
    addControlDevice,
    putControlDevice,
    deleteControlDevice,
    replaceControlDevice,
    loadedControlDevices
}) => {

    // loading
    const [loading, setLoading] = useState(false)

    // state
    // modals open
    const [controllerModalOpen, setControllerModalOpen] = useState(false)
    const [sensorModalOpen, setSensorModalOpen] = useState(false)
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    // current form type
    const [controllerFormType, setControllerFormType] = useState('')
    const [sensorFormType, setSensorFormType] = useState('')

    // shouldLoadController
    const [shouldLoadControllerId, setShouldLoadControllerId] = useState('')

    // header options
    const [showDemounted, setShowDemounted] = useState(false)
    const [realTimeMode, setRealTimeMode] = useState(false)
    const [filters, setFilters] = useState([noFilter, ...allSensorTypes])
    const [activeFilter, setActiveFilter] = useState(noFilter)

    // selected item
    // state
    const [selectedItem, setSelectedItem] = useState(null)
    const [selectedItemProtocol, setSelectedItemProtocol] = useState(null)
    // properties
    const isController = selectedItem?.entityType === 'controller'
    const isSensor = selectedItem?.entityType === 'sensor'
    const isAdditionalDevice = selectedItem?.entityType === 'additionalDevice'

    // lists for virtual in header
    const availableToAddSensorsForVirtual = useMemo(() => {
        if (selectedItem?.isVirtual) {
            return loadedControlDevices.find(controller => controller.id === selectedItem.device.id).sensors
                .filter(item => selectedItem.childsIds?.includes(item.id) ? null : item) // убираем те, которые уже есть
                .filter(item => item.id !== selectedItem.id) || [] // убираем сам виртуальный датчик
        }
        return null
    }, [selectedItem, loadedControlDevices])
    const availableToRemoveSensorsForVirtual = useMemo(() => {
        if (selectedItem?.isVirtual) {
            return loadedControlDevices.find(controller => controller.id === selectedItem.device.id).sensors
                .filter(item => selectedItem.childsIds?.includes(item.id) ? item : null) // убираем те, которых еще нет
                .filter(item => item.id !== selectedItem.id) || [] // убираем сам виртуальный датчик
        }
        return null
    }, [selectedItem, loadedControlDevices])


    const postSensor = async (data, controllerId) => {
        setLoading(true)
        await addSensor(data)
        setSensorModalOpen(false)
        setSensorFormType('')
        setShouldLoadControllerId(controllerId)
        setLoading(false)
    }

    const editSensor = async (data, controllerId) => {
        setLoading(true)
        await putSensor(selectedItem.id, data)
        setSensorModalOpen(false)
        setSensorFormType('')
        setShouldLoadControllerId(controllerId)
        setLoading(false)
    }

    const onSensorSubmit = data => {
        if (sensorFormType === 'post') {
            postSensor(data, selectedItem.id)
        } else if (sensorFormType === 'put') {
            editSensor(data, selectedItem.device.id)
        }
    }

    const onSensorConvertersPost = async data => {
        setLoading(true)
        await postSensorConverter(selectedItem.id, data)
        setShouldLoadControllerId(selectedItem?.device.id)
        setLoading(false)
    }

    const onSensorConvertersPut = async (beginDate, data) => {
        setLoading(true)
        await putSensorConverter(selectedItem.id, beginDate, data)
        setShouldLoadControllerId(selectedItem?.device.id)
        setLoading(false)
    }

    const onSensorConvertersDelete = async beginDate => {
        setLoading(true)
        await deleteSensorConverter(selectedItem.id, beginDate)
        setShouldLoadControllerId(selectedItem?.device.id)
        setLoading(false)
    }

    const onVirtualSensorEdit = async (childs, type) => {
        setLoading(true)
        let newChilds
        if (type === 'add') {
            const selectedItemChildsIds = selectedItem.childsIds ? selectedItem.childsIds : []
            newChilds = [...selectedItemChildsIds, childs]
        } else if (type === 'remove') {
            newChilds = selectedItem?.childsIds.filter(item => item !== childs)
        }
        const body = {
            device: selectedItem?.device.id,
            dataType: selectedItem?.dataType,
            aggregation: selectedItem?.aggregation,
            childs: newChilds
        }
        await putSensor(selectedItem.id, body)
        setShouldLoadControllerId(selectedItem?.device.id)
        setLoading(false)
    }

    const onAddSensorByPrototype = async (sensorPrototypeId) => {
        setLoading(true)
        await addSensorByPrototype({ device: selectedItem.id, sensorPrototype: sensorPrototypeId })
        setShouldLoadControllerId(selectedItem?.id)
        setLoading(false)
    }

    const onAddAdditionalDevice = async id => {
        setLoading(true)
        await putControlDevice(
            selectedItem.id,
            {
                additionalDeviceTypes: [String(id)],
                gpsAddr: selectedItem.gpsAddr,
                dateCreate: selectedItem.dateCreate,
                protocol: String(selectedItem.protocol.id),
                mobileObject: objectId
            },
            () => setShouldLoadControllerId(selectedItem?.id)
        )
        setLoading(false)
    }

    const onControllerSubmit = async data => {
        setLoading(true)
        if (controllerFormType === 'post') {
            await addControlDevice(
                data,
                () => refreshObject(objectId),
                () => {
                    setControllerModalOpen(false)
                    setControllerFormType('')
                }
            )
        }
        else if (controllerFormType === 'put') {
            await putControlDevice(
                selectedItem.id,
                data,
                () => setShouldLoadControllerId(selectedItem.id),
                () => {
                    setControllerModalOpen(false)
                    setControllerFormType('')
                }
            )
        }
        else if (controllerFormType === 'replace') {
            await replaceControlDevice(
                selectedItem.id,
                data,
                () => refreshObject(objectId),
                () => {
                    setControllerModalOpen(false)
                    setControllerFormType('')
                }
            )
        }
        setLoading(false)
    }

    const onDelete = async () => {
        setLoading(true)
        if (isController) {
            await deleteControlDevice(
                selectedItem.id,
                () => refreshObject(objectId),
                () => setDeleteModalOpen(false)
            )
        }
        if (isSensor) {
            await deleteSensor(selectedItem.id)
            setShouldLoadControllerId(selectedItem?.device.id)
            setDeleteModalOpen(false)
            setLoading(false)
        }
        if (isAdditionalDevice) {
            const controller = controllers.find(item => item.id === selectedItem.parentId)
            const devices = controller.devices.filter(item => item.id !== selectedItem.id)
            const body = {
                mobileObject: controller.mobileObject.id,
                gpsAddr: controller.gpsAddr,
                dateCreate: controller.dateCreate,
                protocol: String(controller.protocol.id),
                additionalDeviceTypes: devices.map(item => String(item))
            }
            await putControlDevice(
                controller.id,
                body,
                () => setShouldLoadControllerId(controller.id),
                () => setDeleteModalOpen(false)
            )
        }
        setLoading(false)
    }

    const onDismantle = async (dismantleDate) => {
        setLoading(true)
        if (isController) {
            await putControlDevice(
                selectedItem.id,
                { mobileObject: objectId, gpsAddr: selectedItem?.gpsAddr, dateCreate: selectedItem?.dateCreate, protocol: String(selectedItem?.protocol.id), dateDelete: dismantleDate },
                () => refreshObject(objectId),
                () => setDeleteModalOpen(false)
            )
        }
        else {
            await putSensor(
                selectedItem.id,
                { device: selectedItem?.device.id, dateCreate: selectedItem?.dateCreate, dateDelete: dismantleDate }
            )
            setShouldLoadControllerId(selectedItem?.device.id)
            setDeleteModalOpen(false)
        }
        setLoading(false)
    }

    // при открытии вкладки сразу згружаем sensor types для фильтра
    useEffect(() => {
        if (sensorTypesIsLoading) return;

        if (sensorTypesIsFetched) {
            setFilters([noFilter, ...allSensorTypes])
        } else {
            fetchSensorTypes()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sensorTypesIsFetched, sensorTypesIsLoading, allSensorTypes]);

    // при открытии вкладки сразу загружаем device protocols
    useEffect(() => {
        if (deviceProtocolsIsFetched) return;
        fetchDeviceProtocols()
    }, [deviceProtocolsIsFetched, fetchDeviceProtocols])

    // при выборе контроллера запрашиваем его detailed device protocol (или ставим из стора, если уже там такой есть)
    useEffect(() => {
        if (!selectedItem || !deviceProtocolsIsFetched) return;

        if (!isController) {
            setSelectedItemProtocol(null)
            return
        }

        if (isAdditionalDevice) {
            setSelectedItemProtocol(null)
            return
        }

        let currentProtocol = allDeviceProtocols.find(item => item.id === selectedItem.protocol.id)

        if (!currentProtocol.detailsFetched && !currentProtocol.detailsLoading) {
            fetchDetailedDeviceProtocol(selectedItem.protocol.id)
                .then(() => {
                    currentProtocol = allDeviceProtocols.find(item => item.id === selectedItem.protocol.id)
                    setSelectedItemProtocol(currentProtocol)
                })
        }
        else {
            setSelectedItemProtocol(currentProtocol)
        }
    }, [selectedItem, allDeviceProtocols, deviceProtocolsIsFetched, isController, isAdditionalDevice, fetchDetailedDeviceProtocol])

    // открываем нужные модалы
    const handleControllerAddClick = () => {
        setControllerFormType('post')
        setControllerModalOpen(true)
    }
    const handleSensorAddClick = () => {
        setSensorFormType('post')
        setSensorModalOpen(true)
    }
    const handleEditClick = () => {
        if (selectedItem?.entityType === 'controller') {
            setControllerFormType('put')
            setControllerModalOpen(true)
        }
        else {
            setSensorFormType('put')
            setSensorModalOpen(true)
        }
    }

    const handleReplaceControllerClick = () => {
        setControllerFormType('replace')
        setControllerModalOpen(true)
    }

    const controllerId = selectedItem?.device?.id || selectedItem?.id
    return (
        <>
            <MobileObjectControllersHeader
                onControllerAddClick={handleControllerAddClick}
                isSensorAddButtonDisabled={!Boolean(isController)}
                onSensorAddClick={handleSensorAddClick}
                isEditButtonDisabled={!Boolean(selectedItem)}
                onEditClick={handleEditClick}
                isDeleteButtonDisabled={!Boolean(selectedItem)}
                onDeleteClick={() => setDeleteModalOpen(true)}
                sensorsByPrototypeButtonDisabled={!Boolean(selectedItemProtocol?.sensorPrototypes?.length > 0)}
                sensorsByPrototypeList={selectedItemProtocol?.sensorPrototypes} // преднастройки
                onSensorAddByPrototypeClick={onAddSensorByPrototype}
                sensorsListDisabled={!Boolean(availableToAddSensorsForVirtual?.length > 0 || availableToRemoveSensorsForVirtual?.length > 0)}
                availableToAddSensorsForVirtual={availableToAddSensorsForVirtual}
                availableToRemoveSensorsForVirtual={availableToRemoveSensorsForVirtual}
                onVirtualSensorEdit={onVirtualSensorEdit}
                additionalDevicesDisabled={!Boolean(selectedItemProtocol?.additionalDeviceTypes?.length > 0)}
                additionalDevicesList={selectedItemProtocol?.additionalDeviceTypes}
                onAdditionalDevicesListItemClick={onAddAdditionalDevice}
                showDemounted={showDemounted}
                onShowDemountedChange={() => setShowDemounted(!showDemounted)}
                realTimeMode={realTimeMode}
                onRealTimeModeChange={() => setRealTimeMode(!realTimeMode)}
                filterList={filters}
                activeFilter={activeFilter}
                onFilterListitemClick={setActiveFilter}
            />
            <div style={{ display: 'flex' }}>
                <MobileObjectControllersTable
                    controllers={controllers}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    shouldLoadControllerId={shouldLoadControllerId}
                    setShouldLoadControllerId={setShouldLoadControllerId}
                    showDemounted={showDemounted}
                    realTimeMode={realTimeMode}
                    activeFilter={activeFilter}
                    setControllerFormType={setControllerFormType}
                    setControllerModalOpen={setControllerModalOpen}
                    setSensorFormType={setSensorFormType}
                    setSensorModalOpen={setSensorModalOpen}
                    onDeleteClick={() => setDeleteModalOpen(true)}
                    onReplaceControllerClick={handleReplaceControllerClick}
                    onAddSensorClick={handleSensorAddClick}
                    setLoading={setLoading}
                />
                {isSensor && (
                    <Converters
                        converters={selectedItem.converters || []}
                        onPost={onSensorConvertersPost}
                        onPut={onSensorConvertersPut}
                        onDelete={onSensorConvertersDelete}
                        loading={loading}
                    />
                )}
            </div>
            <ControllerModal
                onSubmit={onControllerSubmit}
                objectId={objectId}
                isOpen={controllerModalOpen}
                handleClose={() => {
                    setControllerModalOpen(false)
                    setControllerFormType('')
                }}
                requestType={controllerFormType}
                isLoading={loading}
                currentController={controllerFormType === 'put' ? selectedItem : null}
            />
            <SensorModal
                controllerId={controllerId}
                isOpen={sensorModalOpen}
                handleClose={() => {
                    setSensorModalOpen(false)
                    setSensorFormType('')
                }}
                onSubmit={onSensorSubmit}
                isLoading={loading}
                currentSensor={sensorFormType === 'put' ? selectedItem : null}
                requestType={sensorFormType}
            />
            <DeleteModal
                isLoading={loading}
                selectedItem={selectedItem}
                controllerId={controllerId}
                isOpen={deleteModalOpen}
                handleClose={() => setDeleteModalOpen(false)}
                onDelete={onDelete}
                onDismantle={onDismantle}
            />
        </>
    );
}

export default MobileObjectControllersView