import React, { useMemo, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Divider,
  Popover,
  Position,
  Tooltip,
  Checkbox,
  Icon,
  MenuItem,
  Intent
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';

import styles from './MobileControllersHeader.module.scss';

const MobileObjectsControlHeader = ({
  onControllerAddClick,
  isSensorAddButtonDisabled,
  onSensorAddClick,
  isEditButtonDisabled,
  onEditClick,
  isDeleteButtonDisabled,
  onDeleteClick,
  sensorsByPrototypeButtonDisabled,
	sensorsByPrototypeList,
  onSensorAddByPrototypeClick,
  sensorsListDisabled,
  availableToAddSensorsForVirtual,
  availableToRemoveSensorsForVirtual,
  onVirtualSensorEdit,
  additionalDevicesDisabled,
	additionalDevicesList,
	onAdditionalDevicesListItemClick,
	showDemounted,
	onShowDemountedChange,
	realTimeMode,
	onRealTimeModeChange,
	filterList,
	activeFilter,
	onFilterListitemClick
}) => {

  const _handleControllerAddClick = useCallback(() => {
    onControllerAddClick()
  }, [onControllerAddClick])

  const _handleSensorAddClick = useCallback(() => {
    onSensorAddClick()
  }, [onSensorAddClick])

  const _handleEditClick = useCallback(() => {
    onEditClick()
  }, [onEditClick])

  const _handleDeleteClick = useCallback(() => {
    onDeleteClick() 
  }, [onDeleteClick])

  const _handleSensorAddByPrototypeClick = useCallback(sensorPrototypeId => {
    onSensorAddByPrototypeClick(sensorPrototypeId)
  }, [onSensorAddByPrototypeClick])

  const _handleVirtualSensorsListItemAddClick = useCallback((id) => {
    onVirtualSensorEdit(id, 'add')
  }, [onVirtualSensorEdit])

  const _handleVirtualSensorsListItemRemoveClick = useCallback((id) => {
    onVirtualSensorEdit(id, 'remove')
  }, [onVirtualSensorEdit])

  const _handleAdditionalDeviceButtonClick = useCallback((id) => {
    onAdditionalDevicesListItemClick(id)
  }, [onAdditionalDevicesListItemClick])

  const _handleShowDemountedClick = useCallback(() => {
    onShowDemountedChange() 
  }, [onShowDemountedChange])

  const _handleRealTimeModeChange = useCallback(() => {
    onRealTimeModeChange() 
  }, [onRealTimeModeChange])

  const _handleFilterItemClick = useCallback((filter) => {
    onFilterListitemClick(filter)
  }, [onFilterListitemClick])

  const renderFilterModel = (item, { handleClick }) => {
    return (
      <MenuItem
        key={item.id}
        label={item.name}
        onClick={handleClick}
        shouldDismissPopover={true}
      />
    );
  }

  const _sensorsByPrototypeList = useMemo(() => {
    if (!sensorsByPrototypeList) return null
    return sensorsByPrototypeList.map(item => {
      return <Button key={item.id} icon='insert' onClick={() => _handleSensorAddByPrototypeClick(item.id)}>{item.displayName}</Button>
    })
  }, [sensorsByPrototypeList, _handleSensorAddByPrototypeClick])

  const _virtualAddingSensorsList = useMemo(() => {
    if (!availableToAddSensorsForVirtual) return null
    return availableToAddSensorsForVirtual.map((item, index) => (
      <Button key={index} icon='insert' onClick={() => _handleVirtualSensorsListItemAddClick(item.id)} intent={Intent.SUCCESS}>Добавить: {item.displayName}</Button>
    ))
  }, [availableToAddSensorsForVirtual, _handleVirtualSensorsListItemAddClick])

  const _virtualRemovingSensorsList = useMemo(() => {
    if (!availableToRemoveSensorsForVirtual) return null
    return availableToRemoveSensorsForVirtual.map((item, index) => (
      <Button key={index} icon='trash' onClick={() => _handleVirtualSensorsListItemRemoveClick(item.id)} intent={Intent.DANGER}>Удалить: {item.displayName}</Button>
    ))
  }, [availableToRemoveSensorsForVirtual, _handleVirtualSensorsListItemRemoveClick])

  const _additionalDevicesList = useMemo(() => {
    if (!additionalDevicesList) return null
    return additionalDevicesList.map(item => (
      <Button key={item.id} icon='insert' onClick={() => _handleAdditionalDeviceButtonClick(item.id)}>{item.displayName}</Button>
    ))
  }, [additionalDevicesList, _handleAdditionalDeviceButtonClick])

  return (
    <>
      <ButtonGroup minimal={true}>
        <Button icon='plus' onClick={_handleControllerAddClick}>
          Контроллер
        </Button>
        <Button icon='plus' onClick={_handleSensorAddClick} disabled={isSensorAddButtonDisabled}>
          Датчик
        </Button>
        <Button icon='edit' onClick={_handleEditClick} disabled={isEditButtonDisabled}>
          Редактировать
        </Button>
        <Button icon='minus' onClick={_handleDeleteClick} disabled={isDeleteButtonDisabled}>
          Удалить
        </Button>
        <Popover position={Position.BOTTOM}>
          <Button icon='add' rightIcon='caret-down' disabled={sensorsByPrototypeButtonDisabled}>
            Преднастройки
          </Button>
          <ButtonGroup vertical={true} minimal={true} className={styles.buttons}>
            {_sensorsByPrototypeList}
          </ButtonGroup>
        </Popover>
        <Popover position={Position.BOTTOM}>
          <Button icon='add' rightIcon='caret-down' disabled={sensorsListDisabled}>
            Вирт. датчик
          </Button>
          <ButtonGroup vertical={true} minimal={true} className={styles.buttons}>
            {_virtualAddingSensorsList}
            {_virtualRemovingSensorsList}
          </ButtonGroup>
        </Popover>
        <Divider />
        <Popover position={Position.BOTTOM}>
          <Tooltip content={'Добавить устройство'}>
            <Button
              disabled={additionalDevicesDisabled}
              icon='new-link'
              rightIcon='caret-down'
              minimal={false}
            ></Button>
          </Tooltip>
          <ButtonGroup vertical={true} minimal={true} align={'left'}>
            {_additionalDevicesList}
          </ButtonGroup>
        </Popover>
        <Tooltip content={'Показать демонтированные'}>
          <Checkbox
            className={styles.checkbox}
            checked={showDemounted}
            onChange={_handleShowDemountedClick}
          >
            <Icon icon='cube-remove' />
          </Checkbox>
        </Tooltip>
        <Tooltip content={'Оперативный режим'}>
          <Checkbox
            className={styles.checkbox}
            inline={true}
            checked={realTimeMode}
            onChange={_handleRealTimeModeChange}
          >
            <Icon icon='refresh' />
          </Checkbox>
        </Tooltip>
        <Select
          id='filterSelect'
          items={filterList}
          filterable={false}
          itemRenderer={renderFilterModel}
          noResults={<MenuItem disabled={true} text='No results.' />}
          onItemSelect={_handleFilterItemClick}
        >
          <Button
            text={activeFilter ? activeFilter.name : 'Фильтр'}
            rightIcon='caret-down'
          />
        </Select>
      </ButtonGroup>
    </>
  );
}

export default MobileObjectsControlHeader