import React, { useEffect } from "react";
import { Spinner } from "@blueprintjs/core";

import Routes from "components/Routes";
import InitConfigApp from "containers/system/InitConfigApp";

import { Styled } from './App.styled'

const App = ({ isLoading, isAuthenticated, authRequest, authError }) => {

  useEffect(() => {
    authRequest();
  }, [authRequest]);

  if (isLoading) {
    return (
      <Styled.CenterSpinner>
        <Spinner  />
        <h1>Аутентификация пользователя</h1>
      </Styled.CenterSpinner>
    );
  } else if (authError && authError.statusCode === 1003) {
    return <InitConfigApp />;
  } else {
    return <Routes isAuthenticated={isAuthenticated} />;
  }
}

export default App;
