import {
  RULER,
  AREA,
  SEARCHOBJ,
  ADDZONEDOT,
  ADDZONEPOLYGON,
  ADDZONECORRIDOR,
  CORRIDORFROMTRACK,
  ADDDOTMAP,
  WEATHERMARKER,
  SAVEIMG,
  SEARCHADDRESSBYCOORD,
} from 'constants/typeButtons';

export const firstGroupButtons = [
  { icon: 'pivot', iconSize: 12, title: 'Линейка', attribute: RULER },
  { icon: 'function', iconSize: 12, title: 'Расчет площади', attribute: AREA },
  { icon: 'geosearch', iconSize: 12, title: 'Поиск ближайших объектов', attribute: SEARCHOBJ }
];

export const secondGroupButtons = [
  { icon: 'new-object', iconSize: 12, title: 'Добавить зону типа "Точка с радиусом"', attribute: ADDZONEDOT },
  { icon: 'polygon-filter', iconSize: 12, title: 'Добавить зону типа "Полигон"', attribute: ADDZONEPOLYGON },
  { icon: 'trending-up', iconSize: 12, title: 'Добавить зону типа "Коридор"', attribute: ADDZONECORRIDOR },
  { icon: 'trending-up', iconSize: 12, title: 'Коридоры из трека', attribute: CORRIDORFROMTRACK },
  { icon: 'map-marker', iconSize: 12, title: 'Добавить точку на карте', attribute: ADDDOTMAP },
  { icon: 'cloud', iconSize: 12, title: 'Погодный маркер', attribute: WEATHERMARKER },
];

export const thirdGroupButtons = [
  { icon: 'floppy-disk', iconSize: 12, title: 'Сохранить изображение', attribute: SAVEIMG },
  { icon: 'path-search', iconSize: 12, title: 'Поиск адреса по координатам', attribute: SEARCHADDRESSBYCOORD },
]
