import React, { useMemo, useCallback } from "react";
import { MenuItem, Button } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

// searches for an element that has the specified id, or returns the first element if no id is specified
function findActiveItemByIndex(items, index) {
    if (index === null) return items[0];
    return items?.find(item => item.index === index);
}

function renderDataTypes(dataType, { handleClick, modifiers }) {
    if (!modifiers.matchesPredicate) return null;

    return (
        <MenuItem key={dataType.index}
            active={modifiers.active}
            onClick={handleClick}
            text={dataType.value}
        />
    );
};

const dataTypes = [
    {
        index: 0,
        value: '8 бит'
    },
    {
        index: 1,
        value: '16 бит'
    },
    {
        index: 2,
        value: '32 бита'
    },
    {
        index: 3,
        value: 'Виртуальный'
    },
    {
        index: 4,
        value: 'Ручной ввод'
    }
]

function DataTypeSelect({ value, onChange }) {

    const activeItem = useMemo(() => findActiveItemByIndex(dataTypes, value), [value]);

    const onItemSelect = useCallback(item => {
        onChange && onChange(item.index)
    }, [onChange]);

    return (
        <Select className="select"
            filterable={false}
            itemRenderer={renderDataTypes}
            onItemSelect={onItemSelect}
            items={dataTypes}
            activeItem={activeItem}
        >
            <Button rightIcon="caret-down">
                {activeItem?.value}
            </Button>
        </Select>
    );
};

export default DataTypeSelect;
