export const WSChannelsNames = {
    Enterprise: "Enterprise",
    UserRole: "UserRole",
    User: "User",
    NavCargo: "NavCargo",
    Zone: "Zone",
    MobileObject: "MobileObject",
    MobileObjectModel: "MobileObjectModel",
    MobileObjectType: "MobileObjectType",
    Worker: "Worker",
    SocialSign: "SocialSign"
}

export default WSChannelsNames