import React, { useEffect } from 'react';
import { CheckboxTree } from 'components/CheckboxTree';
import reportsListStore from 'stores/reportsListStore'


const Reports = ({ value, onChange }) => {

    useEffect(() => {
        if (reportsListStore.isFetched) return

        reportsListStore.fetchReportsList()
    })

    const allReports = reportsListStore.data.map(report => ({
        value: report.category,
        label: report.category,
        children: report.items.map(item => ({
            value: item.id,
            label: item.displayName
        }))
    }))

    return (
        <CheckboxTree
            nodes={allReports}
            selectedItems={value}
            setSelectedItems={onChange}
            noCascade={false}
        />
    );
}

export default Reports;
