import GoldenLayout from "golden-layout";
import Content from 'components/Dock/Content'
import { goldenLayoutConfiguration } from 'constants/goldenLayout';

export let layout;

export const initGoldenLayout = ref => {
  layout = new GoldenLayout(goldenLayoutConfiguration, ref);

  layout.registerComponent('dock', Content);
  layout.init();

  layout.on('activeContentItemChanged', contentItem => {
    layout.eventHub.emit('isActiveChange', contentItem)
  });
}

export const addPanel = (title, content, uniq, contentProps) => {
  if (layout) {
    const openedComponent = layout.root.getItemsById()[0].contentItems.find(component => component.config.title === title);
    if (openedComponent && uniq) {
      layout.root.contentItems[0].setActiveContentItem(openedComponent);
      return;
    }

    layout.root.contentItems[0].addChild({
      id: Math.floor(Math.random() * 100000),
      title,
      type: "react-component",
      component: "dock",
      props: { content, contentProps: contentProps ? contentProps : {} }
    })
  }
}

export const updateSize = (width, height) => {
  if(layout) {
    if(width && height) layout.updateSize(width, height);
    else layout.updateSize();
  }
}

export const closePanel = id => {
  layout.root.contentItems[0].removeChild(layout.root.contentItems[0].getItemsById(id)[0])
}
