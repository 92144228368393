import React from 'react';
import { DivIcon, marker, popup } from "leaflet";
import { renderToString } from 'react-dom/server';
import { Button } from '@blueprintjs/core';
import moment from 'moment';

import mobileObjectsListStore from 'stores/objectsObserverListStore'
import carImg from 'assets/img/car.png'

const { showObject } = mobileObjectsListStore

export const createMobileObjectIcon = (mobileObject, isActive) => { //
  const divIcon = new DivIcon({
    html: renderToString(
      <>
        <div 
          className={`mobile-object ${isActive ? 'active' : ''}`}
          title={mobileObject.mobileObject.displayName}
        >
          <div style={{ transform: `rotate(${mobileObject.course}deg)` }} className="course-container">
            <div 
              style={{
                  borderBottom: `11px solid ${
                    mobileObject.speed < 2 ? 'white' :
                    mobileObject.speed < 10 ? 'green' :
                    mobileObject.speed < 40 ? 'yellow' : 'red'
                  }`
                }}
              className="course"
            ></div>
          </div>
          <img src={mobileObject.mobileObject?.imageUrl ? mobileObject.mobileObject.imageUrl : carImg} alt={mobileObject.mobileObject.displayName} />
          <span style={{
              transform: mobileObject.course > 90 && mobileObject.course < 270 ? 'translateY(-40px)' : '',
              position: 'relative',
              width: '80px'
            }}>
            {
              mobileObject.mobileObject.displayName.length > 15 ? 
              `${mobileObject.mobileObject.displayName.slice(0, 9)}...` : 
              mobileObject.mobileObject.displayName
            }
          </span>
        </div>
      </>
    ),
    iconSize: [30, 30],
    className: 'leaflet-div-icon mobile-object-icon'
  });

  return divIcon;
};

export const openMobileObjectPopup = (mobileObject, map) => {
  popup({ className: 'mobile-object-popup' })
    .setLatLng([mobileObject.location[1], mobileObject.location[0]])
    .setContent(renderToString(
      <>
        <strong>{mobileObject.mobileObject.displayName}</strong>

        <ul className="default-list">
          <li>
            <span>Время: {moment(mobileObject.dateTime).locale('ru').format('DD.MM.YYYY HH:mm:ss')}</span>
          </li>
          <li>
            <span>Координаты: {mobileObject.location[1].toFixed(5)}:{mobileObject.location[0].toFixed(5)}</span>
          </li>
          <li>
            <span>Скорость: {mobileObject.speed.toFixed(1)} км/ч</span>
          </li>
          {mobileObject.stateName ?
            <li>
              <span>Состояние: {mobileObject.stateName}</span> 
            </li> : null
          }
        </ul>

        <Button intent="primary" onClick={async () => {
            await showObject(mobileObject.mobileObject.id, 'o', mobileObject.mobileObject.displayName)
          }}>
            Свойства объекта
        </Button>
      </>
    ))
    .openOn(map)
}

export const createMobileObjectMarker = (mobileObject, isActive, map, setCenteredMobileObject) => {
  const divIcon = createMobileObjectIcon(mobileObject, isActive);

  const newMarker = marker([mobileObject.location[1], mobileObject.location[0]], { 
    icon: divIcon,
    zIndexOffset: isActive ? 99999 : null,
    mobileObject: {
      id: mobileObject.mobileObject.id,
      course: mobileObject.mobileObject.course
    },
  })
    .on('dblclick', () => setCenteredMobileObject(mobileObject))
    .on('contextmenu', () => openMobileObjectPopup(mobileObject, map));


  return newMarker;
}