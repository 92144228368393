import React from 'react';
import { Switch, Route } from 'react-router-dom';

import LoginForm from 'components/LoginForm';
import PrivateRoute from 'components/PrivateRoute';
import Layout from 'components/Layout';

const Routes = ({ isAuthenticated }) => {
  return (
    <Switch>
      <Route exact path='/login' component={LoginForm} />
      <PrivateRoute
        isAuthenticated={isAuthenticated}
        exact
        path='/'
        component={Layout}
      />
    </Switch>
  );
};

export default Routes;
