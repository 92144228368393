export const RULER = 'ruler';
export const AREA = 'area';
export const SEARCHOBJ = 'searchobj';
export const ADDZONEDOT = 'addzonedot';
export const ADDZONEPOLYGON = 'addzonepolygon';
export const ADDZONECORRIDOR = 'addzonecorridor';
export const CORRIDORFROMTRACK = 'corridorfromtrack';
export const ADDDOTMAP = 'adddotmap';
export const WEATHERMARKER = 'weathermarker';
export const SAVEIMG = 'saveimg';
export const SEARCHADDRESSBYCOORD = 'searchaddressbycoord';
