import React from 'react'
import moment from 'moment';
import { DateInput as BlueprintDateInput, TimePrecision, TimePicker } from "@blueprintjs/datetime";
import DateFormatString from 'constants/locales';

const getMomentFormatter = (format) => {
    return {
        formatDate: (date, locale) => moment(date).locale(locale).format(format),
        parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
        placeholder: format,
    }
}

const offset = new Date().getTimezoneOffset() * 60000

export const DateTimeInput = ({ value, onChange, ...props }) => {
    return (
        <BlueprintDateInput
            dayPickerProps={{
                months: DateFormatString.months,
                weekdaysLong: DateFormatString.weekDaysLong,
                weekdaysShort: DateFormatString.weekDaysShort,
                firstDayOfWeek: 1
            }}
            timePrecision={TimePrecision.SECOND}
            onChange={e => onChange(e ? new Date((new Date(e) - offset)).toISOString().slice(0, -5) : e)}
            value={value ? new Date(value) : value}
            locale="RU-ru"
            {...getMomentFormatter('DD.MM.YYYY : HH.mm.ss')}
            {...props}
        />
    )
}

export const DateInput = ({ value, onChange, ...props }) => {
    return (
        <BlueprintDateInput
            dayPickerProps={{
                months: DateFormatString.months,
                weekdaysLong: DateFormatString.weekDaysLong,
                weekdaysShort: DateFormatString.weekDaysShort,
                firstDayOfWeek: 1
            }}
            timePrecision={TimePrecision.SECOND}
            onChange={e => onChange(e ? new Date((new Date(e) - offset)).toISOString().slice(0, -5) : e)}
            value={value ? new Date(value) : value}
            locale="RU-ru"
            {...getMomentFormatter('DD.MM.YYYY : HH.mm.ss')}
            {...props}
        />
    )
}

export const TimeInput = ({ value, onChange, ...props }) => {
    return (
        <TimePicker
            value={value ? new Date(value * 1000) : null}
            onChange={e => onChange(e.getTime()/1000)}
            precision={TimePrecision.MINUTE}
            {...props}
        />
    )
}
