import { codeModule } from 'constants/codeModule'

const getEnterprisesTree = arr => {
    if (!arr || arr.length < 1) return []

    const mapItem = item => {
        const childs = arr.filter(i => i.group === item.id).map(item => item.items).flat(1)
        return {
            value: item.name + '~' + item.id,
            label: item.name,
            [childs.length && 'children']: childs.map(mapItem)
        }
    }

    const topLevelItems = arr.filter(item => !item.group)?.shift()?.items || []
    return topLevelItems.map(mapItem)
}

export default getEnterprisesTree


const mapRoutes = item => {

    const dirs = Array.from(new Set(item.items.map(i => i.directory)))
    const names = Array.from(new Set(item.items.map(i => i.name)))

    return {
        value: item.group,
        label: codeModule[item.group],
        children: dirs.map(dir => ({
            value: dir,
            label: dir || 'Без каталога',
            children: names.map((name, i) => ({
                label: name,
                value: name + i,
                children: item.items.filter(i => i.directory === dir).filter(i => i.name === name).map(i => ({
                    value: i.id,
                    label: i.directionLetter
                }))
            }))
        }))
    }
}

export const getRoutesTree = arr => {
    return arr.map(mapRoutes)
}