import { makeAutoObservable, runInAction } from 'mobx';

import { subscribeToChannel, unsubscribeFromChannel } from 'tools/socketManager';

class SocketStore {
  socketChannels = new Map()

  constructor() {
    makeAutoObservable(this);
  }

  addSocketChannel = async (type, channel, fields) => {
    if(!this.socketChannels.get(channel)) {
      await subscribeToChannel(type, channel, fields, message => {
        runInAction(() => {
          this.socketChannels.set(`${type}:${channel}`, message)
        })
      })
    }
  }

  removeSocketChannel = async (type, channel, fields) => {
    if(this.socketChannels.has(`${type}:${channel}`)) {
      runInAction(() => this.socketChannels.delete(`${type}:${channel}`))
      await unsubscribeFromChannel(type, channel, fields)
    }
  }

  removeAllSocketChannels = async () => {
    console.log([...this.socketChannels.keys()])
  }

  getChannelData = (type, channel) => {
    if(this.socketChannels.has(`${type}:${channel}`)) {
      return this.socketChannels.get(`${type}:${channel}`)
    } 
    
    return null
  }
}

export default new SocketStore()
