import React from 'react';

import MobileObject from './MobileObject';

const MobileObjects = ({ 
  mobileObjects, 
  map,
  centeredMobileObject,
  setCenteredMobileObject
}) => (
  <>
    {
      mobileObjects?.length && mobileObjects.map(mobileObject => 
        <MobileObject
          map={map}
          key={mobileObject}
          mobileObject={mobileObject}
          centeredMobileObject={centeredMobileObject}
          setCenteredMobileObject={setCenteredMobileObject}
        />
      )
    }
  </>
);

export default MobileObjects;
