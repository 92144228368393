import React, { lazy, useEffect } from 'react';
import { observer } from 'mobx-react-lite'
import {
  Alignment,
  NavbarDivider,
  NavbarGroup,
  Popover,
  Position,
  Button
} from '@blueprintjs/core';

// Components
import { addPanel } from 'tools/initGoldenLayout';
import SearchBar from './SearchBar';

// Submenus
import ReportsMenu from './submenu/ReportsMenu';
import ViewMenu from './submenu/ViewMenu';
import AdminMenu from './submenu/AdminMenu';
import CatalogMenu from './submenu/CatalogMenu';
import MonitoringMenu from './submenu/MonitoringMenu';

// stores
import reportsListStore from 'stores/reportsListStore'

// Import styles
import { Styled } from './NavMenu.styled'

const AppMap = lazy(() => import('components/AppMap'))

const NavMenu = observer(({ authLogout }) => {

  useEffect(() => {
    if (reportsListStore.isFetched) return

    reportsListStore.fetchReportsList()
  }, [])

  return (
    <Styled.MainMenu data-ref="main-menu">
      <NavbarGroup align={Alignment.LEFT}>
        <Popover
          content={<ViewMenu />}
          position={Position.BOTTOM_LEFT}
          minimal={true}
        >
          <Button icon='application' text='Вид' minimal={true} small={true} />
        </Popover>
        <Popover
          content={<CatalogMenu />}
          position={Position.BOTTOM_LEFT}
          minimal={true}
        >
          <Button icon='applications' text='Справочники' minimal={true} small={true} />
        </Popover>
        <Popover
          content={
            <ReportsMenu
              reports={reportsListStore.data}
            />
          }
          position={Position.BOTTOM_LEFT}
          minimal={true}
        >
          <Button
            text='Отчеты'
            minimal={true}
            small={true}
            disabled={reportsListStore.isLoading}
            icon='clipboard'
          />
        </Popover>
        <Popover
          content={<MonitoringMenu />}
          position={Position.BOTTOM_LEFT}
          minimal={true}
        >
          <Button icon='warning-sign' text='Мониторинг' minimal={true} small={true} />
        </Popover>
        {/* <Button text='Инструменты' minimal={true} small={true} /> Todo maybe in future */}
        <Button
          icon='map'
          text='Карта'
          onClick={() => addPanel('Карта', AppMap, false)}
          minimal={true}
          small={true}
        />
        <NavbarDivider />
        <Popover
          content={<AdminMenu />}
          position={Position.BOTTOM_RIGHT}
          minimal={true}
        >
          <Button
            icon='key'
            text='Администрирование'
            minimal={true}
            small={true}
          />
        </Popover>
        <NavbarDivider />
        <SearchBar />
      </NavbarGroup>
      <NavbarGroup align={Alignment.RIGHT}>
        <Button
          icon='log-out'
          text='Выйти'
          onClick={authLogout}
          minimal={true}
          small={true}
        />
      </NavbarGroup>
    </Styled.MainMenu>
  );
});

export default NavMenu;
