import React, { useRef } from 'react';
import { NavigationObjectsMobileObject, NavigationObjectsMobileGroup } from 'constants/attributesTypes'
import { Styled } from './ListRow.styled'

const ListRow = ({ params, onRigthClick, handleRowSelect, onRowDblClick, selectedObjectIds }) => {
  const { style, icon, text, isSelected, type, objectId, displayName } = params;
  const rowRef = useRef(null);

  return (
    <Styled.Row
      style={style}
      className={isSelected ? 'selected' : ''}
      onClick={handleRowSelect}
      onDoubleClick={() => onRowDblClick({ objectId, type, displayName })}
      onContextMenu={(e) => onRigthClick(e, objectId, type)}
      data-object-type={type}
      data-object-id={objectId}
      data-object-displayname={displayName}
      data-object-icon={icon}
      data-type-draggable={type === 'g' ? NavigationObjectsMobileGroup : type === 'o' ? NavigationObjectsMobileObject : null}
      draggable={true}
      ref={rowRef}
      onDragStart={() => {
        if (selectedObjectIds.length > 1) {
          selectedObjectIds.forEach(objectId => {
            document.querySelector(`[data-object-id="${objectId}"]`)?.setAttribute('draggable-object', true);
          });
        } else {
          rowRef.current.setAttribute('draggable-object', true);
        }
      }}
      onDragEnd={() => {
        if (selectedObjectIds.length > 1) {
          selectedObjectIds.forEach(objectId => {
            document.querySelector(`[data-object-id="${objectId}"]`)?.removeAttribute('draggable-object', true);
          });
        } else {
          rowRef.current.removeAttribute('draggable-object', true);
        }
      }}
    >
      <Styled.Icon>
        <img src={icon} alt="icon" />
      </Styled.Icon>
      <Styled.Name>{text}</Styled.Name>
    </Styled.Row>
  );
};

export default ListRow;
