import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Tab, Tabs, FormGroup, InputGroup, TextArea, Button, Intent, ButtonGroup, Alert, Dialog, Classes } from "@blueprintjs/core";
import Reports from './Reports'
import UserObjects from './UserObjects'
import PasswordForm from './PasswordForm'

import UserRolesSelect from 'components/Selects/UserRolesSelect'
import EnterprisesSelect from 'components/Selects/EnterpsrisesSelect/EnterpsrisesSelect'
import { DateInput } from 'components/DateTimeInput'
import { NavigationObjectsUser, NavigationRoutesRoute } from 'constants/attributesTypes'
import { developerRoleId, adminRoleId } from 'constants/userRoles'

import { useForm, Controller } from 'react-hook-form'
import AdditionalObjectAttributes from 'components/AdditionalObjectAttributes';

import UserStore from 'stores/userStore'

import styles from './style.module.scss'

const UserView = ({ data, loading, onUpdate, onPatch, onDelete, onRestore }) => {

    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [isRestoreAlertOpen, setIsRestoreAlertOpen] = useState(false)
    const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false)

    const dndRef = useRef(null)

    const { register, handleSubmit, reset, control, setValue, getValues } = useForm()

    // удаление пользователя
    const handleDelete = async () => {
        await onDelete()
        setIsDeleteAlertOpen(false)
    }

    // восстановление пользователя
    const handleRestore = async () => {
        await onRestore()
        setIsRestoreAlertOpen(false)
    }

    // сменить пароль (patch)
    const handleResetPassword = async data => {
        await onPatch(data)
        setIsPasswordDialogOpen(false)
    }

    // обновить пользователя
    const handleUpdateUser = async body => {
        await onUpdate({
            id: data.id,
            name: body.name,
            login: body.login,
            eMail: body.eMail,
            phone: body.phone,
            expireDate: body.expireDate,
            role: String(body.role?.id),
            enterprise: String(body.enterprise?.id),
            userAccesses: body.userAccesses.filter(item => !item.toRemove).map(item => ({
                object: String(item.object.id),
                type: item.type || item.object.type,
                editAccess: true,
            })),
            attributes: {
                ...body.attributes,
                'REPORT_FILTER': body.reports?.join('-/-'),
                'REPORT_FILTER_ENB': body.reports?.length > 0 ? '1' : '0',
                'SYS_DESC': body.description
            }
        })
    }

    const onDrop = useCallback(async () => {
        if (dndRef) dndRef.current.classList.remove('drag-enter')

        const draggableObjects = Array.from(document.querySelectorAll('[draggable-object]'))
        if (!draggableObjects?.length) return

        const values = getValues('userAccesses')
        const setOfIds = new Set(values?.map(val => val.object.id) || [])

        setValue('userAccesses', [
            ...values,
            ...draggableObjects.filter(item => !setOfIds.has(item.dataset.objectId)).map(item => ({
                notSaved: true,
                type: item.dataset.typeDraggable,
                editAccess: true,
                object: {
                    id: item.dataset.objectId,
                    displayName: item.dataset.objectDisplayname,
                    imageUrl: item.dataset.objectIcon,
                }
            }))
        ])
    }, [getValues, setValue])

    const onSetRoutes = useCallback(async data => {
        const values = getValues('userAccesses')
        const setOfIds = new Set(values?.map(val => val.object.id) || [])

        setValue('userAccesses', [
            ...values,
            ...data.map(item => item.items.filter(item => !setOfIds.has(item.id)).map(i => ({
                notSaved: true,
                type: NavigationRoutesRoute,
                editAccess: true,
                object: {
                    id: i.id,
                    displayName: i.directionLetter + i.id,
                    imageUrl: 'icons/MObject.png'
                }
            }))).flat(1)
        ])
    }, [getValues, setValue])

    const onDeleteObjectClick = useCallback((object) => {
        const values = getValues('userAccesses')
        const index = values.findIndex(item => item.object.id === object.object.id)
        if (object.notSaved) {
            setValue('userAccesses', [
                ...values.slice(0, index),
                ...values.slice(index + 1)
            ])
        } else {
            setValue('userAccesses', [
                ...values.slice(0, index),
                {
                    ...values[index],
                    toRemove: values[index].toRemove ? false : true,
                },
                ...values.slice(index + 1)
            ])
        }
    }, [getValues, setValue])

    useEffect(() => {
        if (!data) return
        reset({
            id: data.id,
            name: data.displayName,
            role: data.role,
            login: data.login,
            eMail: data.eMail,
            phone: data.phone,
            authDate: data.authDate,
            enterprise: data.enterprise,
            expireDate: data.expireDate,
            description: data.description,
            attributes: data.attributes,
            reports: data.attributes['REPORT_FILTER']?.split('-/-'),
            userAccesses: data.userAccesses
        })
    }, [data, reset])

    const reportsPanel = (
        <Controller control={control} name='reports' render={({ value, onChange }) => (
            <Reports
                value={value}
                onChange={onChange}
            />
        )} />
    )

    const mobjectsPanel = (
        <Controller control={control} name='userAccesses' render={({ value }) => (
            <UserObjects
                dndRef={dndRef}
                onDrop={onDrop}
                value={value}
                onDeleteObjectClick={onDeleteObjectClick}
                onSetRoutes={onSetRoutes}
            />
        )} />
    )

    const isDeveloper = UserStore.data.role.id === developerRoleId
    const isAdmin = UserStore.data.role.id === adminRoleId
    const isOwner = UserStore.data.id === data?.id
    const canEditPassword = isDeveloper || isAdmin || isOwner

    return <>
        <div className={styles['container']}>
            <div className={styles['main-props-container']}>
                <form onSubmit={handleSubmit(handleUpdateUser)}>
                    <FormGroup>
                        <ButtonGroup fill>
                            <Button type='submit' intent={Intent.SUCCESS} fill loading={loading}>Сохранить</Button>
                            {canEditPassword && (
                                <Button intent={Intent.PRIMARY} fill onClick={() => setIsPasswordDialogOpen(true)} loading={loading}>Сменить пароль</Button>
                            )}
                            {data && data.deleted === false ? (
                                <Button intent={Intent.DANGER} fill onClick={() => setIsDeleteAlertOpen(true)} loading={loading}>Удалить</Button>
                            ) : (
                                <Button intent={Intent.WARNING} fill onClick={() => setIsRestoreAlertOpen(true)} loading={loading}>Восстановить</Button>
                            )}
                        </ButtonGroup>
                    </FormGroup>
                    <FormGroup label='Имя:'>
                        <InputGroup inputRef={register} name='name' />
                    </FormGroup>
                    <FormGroup label="Роль:">
                        <Controller
                            control={control}
                            name="role"
                            render={({ value, onChange }) => (
                                <UserRolesSelect value={value} onChange={onChange} />
                            )}
                        />
                    </FormGroup>
                    <FormGroup label="Логин:">
                        <InputGroup inputRef={register} name='login' />
                    </FormGroup>
                    <FormGroup label="E-mail:">
                        <InputGroup inputRef={register} name='eMail' />
                    </FormGroup>
                    <FormGroup label="Телефон:">
                        <InputGroup inputRef={register} name='phone' />
                    </FormGroup>
                    <FormGroup label="Последний вход:">
                        <InputGroup inputRef={register} name='authDate' readOnly />
                    </FormGroup>
                    <FormGroup label="Предприятие:">
                        <Controller
                            control={control}
                            name="enterprise"
                            render={({ value, onChange }) => (
                                <EnterprisesSelect value={value} onChange={onChange} />
                            )}
                        />
                    </FormGroup>
                    <FormGroup label="Дата истечения:">
                        <Controller
                            name='expireDate'
                            control={control}
                            render={({ value, onChange }) => (
                                <DateInput value={value} onChange={onChange} />
                            )}
                        />
                    </FormGroup>
                    <FormGroup label="Примечание:">
                        <TextArea fill inputRef={register} name='description' />
                    </FormGroup>
                    <FormGroup label="ID:">
                        <InputGroup inputRef={register} name='id' readOnly />
                    </FormGroup>
                    <Controller
                        name='attributes'
                        control={control}
                        render={({ value, onChange }) => (
                            <AdditionalObjectAttributes
                                targetType={NavigationObjectsUser}
                                entityAttributes={value}
                                onChange={onChange}
                                entityKey={data?.id}
                            />
                        )}
                    />
                </form>
            </div>
            <div className={styles['info-container']}>
                <Tabs id="TabsExample">
                    <Tab id="mobjects" title="Объекты пользователя" panel={mobjectsPanel} />
                    <Tab id="reports" title="Отчеты" panel={reportsPanel} />
                </Tabs>
            </div>
        </div>
        <Alert
            cancelButtonText='Отмена'
            confirmButtonText='Удалить пользователя'
            icon='undo'
            intent={Intent.DANGER}
            isOpen={isDeleteAlertOpen}
            onCancel={() => setIsDeleteAlertOpen(false)}
            onConfirm={handleDelete}
        >
            <p>Вы уверены, что хотите удалить <b>пользователя</b>?</p>
        </Alert>
        <Alert
            cancelButtonText='Отмена'
            confirmButtonText='Восстановить пользователя'
            icon='undo'
            intent={Intent.WARNING}
            isOpen={isRestoreAlertOpen}
            onCancel={() => setIsRestoreAlertOpen(false)}
            onConfirm={handleRestore}
        >
            <p>Вы уверены, что хотите восстановить <b>пользователя</b>?</p>
        </Alert>
        {canEditPassword && (
            <Dialog
                isOpen={isPasswordDialogOpen}
                onClose={() => setIsPasswordDialogOpen(false)}
                title='Изменить пароль пользователя'
                canOutsideClickClose>
                <div className={Classes.DIALOG_BODY}>
                    <PasswordForm onSubmit={handleResetPassword} onClose={() => setIsPasswordDialogOpen(false)} />
                </div>
            </Dialog>
        )}
    </>
}

export default UserView;
