import React, { useEffect, useRef } from 'react';
import { createTrack } from '../tools/createTrack'

const Track = ({ track, map, setCenter }) => {
  const trackRef = useRef(null)
  const mobileObjectRef = useRef(null);

  useEffect(() => {
    const { t, m } = createTrack(track, map, true)

    trackRef.current = t;
    mobileObjectRef.current = m

    setCenter([...track.firstPoint.location].reverse())
  }, [track, map, setCenter])

  return (
    <>
    </>
  );
}

export default Track;
