import styled from 'styled-components'

const BlockSelect = styled.div`
    display: block;
    width: 100%;
    .bp3-popover-target {
        width: 100%;
        .bp3-button {
            width: 100%;
            justify-content: space-between
        }
    }
`;

export default BlockSelect