import React from 'react'
import { observer } from 'mobx-react-lite'
import App from "components/App";
import userStore from 'stores/userStore'

const AppContainer = observer(() => {
  const { data, isLoading, isAuthenticated, authError, authRequest } = userStore;
  
  return (
    <App
      data={data}
      isLoading={isLoading}
      isAuthenticated={isAuthenticated}
      authError={authError}
      authRequest={authRequest}
    />
  )
})

export default AppContainer