import React, { useEffect } from 'react'
import { renderToString } from 'react-dom/server'
import { Marker, Popup } from 'react-leaflet'
import { divIcon } from 'leaflet'
import { Icon, Button } from '@blueprintjs/core'

import ClipButton from 'components/ClipButton'

const Address = ({ address, setCenter, setAddress }) => {
  useEffect(() => {
    setCenter([address.location[1], address.location[0]])
  }, [address, setCenter]);

  return (
    <Marker 
      position={[address.location[1], address.location[0]]}
      icon={
        divIcon({
          className: '',
          iconSize: [20, 20],
          html: renderToString(<Icon icon="map-marker" intent="danger" iconSize={30} />)
        })
      }
    >
      <Popup >
        <div style={{ fontSize: '13px' }}>
          <strong>
          <span style={{ paddingRight: 10 }}>{address.location[1]}&nbsp;:&nbsp;{address.location[0]}</span>
            <ClipButton text={`${address.location[1]} : ${address.location[0]}`} />
          </strong>
          <div style={{ marginTop: 5, marginBottom: 5 }}>
            {address.displayName} 
          </div>
          <Button intent="danger" onClick={() => setAddress(null)}>
            Убрать точку с карты
          </Button>
        </div>
      </Popup>
    </Marker>
  )
}

export default Address;