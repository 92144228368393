import React from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';

// import ChannelListener from 'containers/system/ChannelListener';
import ServerSettings from 'containers/system/ServerSettings';
import { LibsViewPanel } from 'containers/system/LibsView';
import { addPanel } from 'tools/initGoldenLayout';



const AdminMenu = () => {
    return (
        <Menu>
            {/* <MenuItem
                icon='dashboard'
                text='Логи'
                onClick={() =>
                    addPanel('main', ChannelListener, true, {socketType: 'OnChannelMessages', socketChan: 'main', rowLimit: 20})
                }
            /> */}
            <MenuItem
                icon='wrench'
                text='Настройки'
                onClick={() => addPanel('Настройки сервера', ServerSettings, true)}
            />
            <MenuItem
                icon='cube'
                text='Модули'
                onClick={() => addPanel('Модули', LibsViewPanel, true)}
            />
        </Menu>
    )
}

export default AdminMenu;