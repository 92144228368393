import styled from 'styled-components'

export const Layout = styled.div`
  display: grid;
  grid-template-areas: ${props => props.isLeftPanelActive ? `
    'nav nav'
    'left dock'
    'left dock';
    ` : `
    'nav nav'
    'dock dock'
    'dock dock';
    `
  }
  grid-template-rows: 30px calc(100vh - 30px);
  grid-template-columns: 1fr 4fr;

  height: 100%;
  max-height: 100%;
`

export const Styled = {
  Layout
}