import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { QueryClientProvider } from "react-query";
import { FocusStyleManager } from '@blueprintjs/core';
import { ThemeProvider } from 'styled-components'

import history from 'tools/history';
import queryClient from 'tools/queryClient'

import theme from './theme'
import 'normalize.css';
import 'scss/index.scss';

import App from 'containers/App';

window.React = React;
window.ReactDOM = ReactDOM;

FocusStyleManager.onlyShowFocusOnTabs();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Router>
  </QueryClientProvider>,
  document.getElementById('root')
);
