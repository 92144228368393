import { makeAutoObservable, runInAction } from "mobx"
import { showToasterD, showToasterS } from 'tools/AppToaster';
import {
    getAttributes as _getAttributes,
    getAttributesTypes as _getAttributesTypes,
    postAttribute as _postAttribute,
    putAttribute as _putAttribute,
    deleteAttribute as _deleteAttribute,
} from 'api/additionalObjectAttributes';

export class AdditionalObjectAttributesStore {

    attributes = {}
    attributesTypes = []
    loading = false

    constructor() {
        makeAutoObservable(this)
    }

    getAttributes = async (objectType) => {
        runInAction(() => this.loading = true)
        try {
            const res = await _getAttributes(objectType)
            runInAction(() => {
                this.attributes[objectType] = {
                    data: res,
                    fetched: true
                }
            })
        } catch {
            showToasterD('Не удалось загрузить атрибуты')
        }
        runInAction(() => this.loading = false)
    }

    getAttributesTypes = async () => {
        runInAction(() => this.loading = true)
        try {
            const res = await _getAttributesTypes()
            runInAction(() => this.attributesTypes = res)
        } catch {
            showToasterD('Не удалось загрузить атрибуты')
        }
        runInAction(() => this.loading = false)
    }

    postAttribute = async (body) => {
        runInAction(() => this.loading = true)
        try {
            await _postAttribute(body)
            showToasterS('Атрибут успешно добавлен')
        } catch {
            showToasterD('Не удалось добавить атрибут')
        }
        runInAction(() => this.loading = false)
    }

    putAttribute = async (id, body) => {
        runInAction(() => this.loading = true)
        try {
            await _putAttribute(id, body)
            showToasterS('Атрибут успешно обновлен')
        } catch {
            showToasterD('Не удалось обновить атрибут')
        }
        runInAction(() => this.loading = false)
    }

    deleteAttribute = async (id) => {
        runInAction(() => this.loading = true)
        try {
            await _deleteAttribute(id)
            showToasterS('Атрибут успешно удалён')
        } catch {
            showToasterD('Не удалось удалить атрибут')
        }
        runInAction(() => this.loading = false)
    }
}

export default new AdditionalObjectAttributesStore()