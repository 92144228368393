import { makeAutoObservable, runInAction } from "mobx"

import {
    createSensor,
    createSensorByPrototype,
    deleteSensor as _deleteSensor,
    putSensor as _putSensor,
    getSensor as _getSensor,
    postSensorConverter as _postSensorConverter,
    putSensorConverter as _putSensorConverter,
    deleteSensorConverter as _deleteSensorConverter
} from 'api/appAPI';

import { showToasterD, showToasterS } from 'tools/AppToaster';

export class SensorsStore {

    constructor() {
        makeAutoObservable(this)
    }

    loadedSensors = []
    isLoading = true
    error = false

    getSensor = async (sensorId, fields) => {
        runInAction(() => this.isLoading = true)
        try {
            const res = await _getSensor(sensorId, fields);
            const updateIndex = this.loadedSensors.findIndex(item => item.id === res.data.result.id)
            if (updateIndex > -1) {
                runInAction(() => {
                    this.loadedSensors = [
                        ...this.loadedSensors.slice(0, updateIndex),
                        res.data.result,
                        ...this.loadedSensors.slice(updateIndex + 1),
                    ]
                })
            } else {
                runInAction(() => {
                    this.loadedSensors = [...this.loadedSensors, res.data.result]
                })
            }
        } catch (error) {
            runInAction(() => this.error = error)
        }
        runInAction(() => this.isLoading = false)
    };

    addSensor = async (data) => {
        try {
            await createSensor(data);
            showToasterS('Датчик успешно добавлен');
        } catch (error) {
            runInAction(() => this.error = error)
            showToasterD('Не удалось добавить датчик');
        }
    };

    addSensorByPrototype = async (data) => {
        try {
            await createSensorByPrototype(data);
            showToasterS('Датчик успешно добавлен');
        } catch (error) {
            runInAction(() => this.error = error)
            showToasterD('Не удалось добавить датчик');
        }
    };

    deleteSensor = async (id) => {
        try {
            await _deleteSensor(id);
            showToasterS('Датчик удалён');
        }
        catch (error) {
            runInAction(() => this.error = error)
            showToasterD('Не удалось удалить датчик');
        }
    };

    putSensor = async (id, body) => {
        try {
            await _putSensor(id, body);
            showToasterS('Датчик обновлен');
        }
        catch (error) {
            runInAction(() => this.error = error)
            showToasterD('Не удалось обновить датчик');
        }
    };

    postSensorConverter = async (sensorId, body) => {
        try {
            await _postSensorConverter(sensorId, body);
            showToasterS('Тарировка добавлена');
        }
        catch (error) {
            showToasterD('Не удалось добавить тарировку');
        }
    };

    putSensorConverter = async (sensorId, beginDate, body) => {
        try {
            await _putSensorConverter(sensorId, beginDate, body);
            showToasterS('Тарировка обновлена');
        }
        catch (error) {
            showToasterD('Не удалось обновить тарировку');
        }
    };
    
    deleteSensorConverter = async (sensorId, beginDate) => {
        try {
            await _deleteSensorConverter(sensorId, beginDate);
            showToasterS('Тарировка удалена');
        }
        catch (error) {
            showToasterD('Не удалось удалить тарировку');
        }
    };
}

export default new SensorsStore()