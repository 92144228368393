import React from 'react';
import { observer } from 'mobx-react-lite'

// view cmponent
import MobileObjectControllersView from './MobileObjectControllersView'

// stores
import sensorTypesStore from 'stores/sensorTypesStore'
import sensorsStore from 'stores/sensorsStore'
import deviceProtocolsStore from 'stores/deviceProtocolsStore'
import controlDevicesStore from 'stores/controlDevicesStore'


const MobileObjectControllers = observer(props => {
  return (
    <MobileObjectControllersView
      // sensor types
      allSensorTypes={sensorTypesStore.allSensorTypes}
      sensorTypesIsFetched={sensorTypesStore.isFetched}
      sensorTypesIsLoading={sensorTypesStore.isLoading}
      fetchSensorTypes={sensorTypesStore.fetchSensorTypes}
      // sensors
      addSensor={sensorsStore.addSensor}
      addSensorByPrototype={sensorsStore.addSensorByPrototype}
      deleteSensor={sensorsStore.deleteSensor}
      putSensor={sensorsStore.putSensor}
      postSensorConverter={sensorsStore.postSensorConverter}
      putSensorConverter={sensorsStore.putSensorConverter}
      deleteSensorConverter={sensorsStore.deleteSensorConverter}
      // DeviceProtocols
      fetchDeviceProtocols={deviceProtocolsStore.fetchDeviceProtocols}
      fetchDetailedDeviceProtocol={deviceProtocolsStore.fetchDetailedDeviceProtocol}
      deviceProtocolsIsFetched={deviceProtocolsStore.isFetched}
      allDeviceProtocols={deviceProtocolsStore.allDeviceProtocols}
      // control devices
      addControlDevice={controlDevicesStore.addControlDevice}
      putControlDevice={controlDevicesStore.putControlDevice}
      deleteControlDevice={controlDevicesStore.deleteControlDevice}
      replaceControlDevice={controlDevicesStore.replaceControlDevice}
      loadedControlDevices={controlDevicesStore.loadedControlDevices}
      {...props}
    />
  )
})

export default MobileObjectControllers;
