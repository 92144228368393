import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite'

import { createMobileObjectMarker, createMobileObjectIcon } from 'components/AppMap/tools/createMobileObject';

import { ON_NEW_LAST_POINTS } from 'constants/socketManager';
import { lastPointFields } from 'constants/monitoringCOM';

import SocketStore from 'stores/socketStore'

const MobileObject = observer(({
  map,
  mobileObject,
  centeredMobileObject,
  setCenteredMobileObject,
}) => {
  const mobileObjectMessage = SocketStore.getChannelData(ON_NEW_LAST_POINTS, mobileObject)
  const marker = useRef(null)
  const [mobileObjectMarker, setMobileObjectMarker] = useState({})

  if(marker?.current?._latlng) {
    if(map?.getBounds() && map.getBounds().contains(marker.current._latlng)) {
      marker.current.addTo(map)
    }
  }

  useEffect(() => {
    if(mobileObjectMessage) {
      marker.current = createMobileObjectMarker(mobileObjectMessage, false, map, setCenteredMobileObject)

      setMobileObjectMarker(prevMobileObjectMarker => {
        if(prevMobileObjectMarker.marker) {
          map.removeLayer(prevMobileObjectMarker.marker)
        }

        return {
          marker: marker.current,
          message: mobileObjectMessage
        }
      })
    }

  // eslint-disable-next-line
  }, [mobileObjectMessage])

  useEffect(() => {
    if(mobileObjectMarker.marker) {
      if(map.getBounds().contains([...mobileObjectMarker.message.location].reverse())){
        mobileObjectMarker.marker.addTo(map)
      } else {
        map.removeLayer(mobileObjectMarker.marker)
      }

      if(mobileObject === centeredMobileObject?.mobileObject?.id) {
        mobileObjectMarker.marker.setIcon(createMobileObjectIcon(mobileObjectMarker.message, true))
        mobileObjectMarker.marker.setZIndexOffset(1000)
        mobileObjectMarker.marker.addTo(map)
        map.setView([...mobileObjectMarker.message.location].reverse())
      } else {
        if(mobileObjectMarker.marker) {
          mobileObjectMarker.marker.setIcon(createMobileObjectIcon(mobileObjectMarker.message, false))
        }
      }
    }
  }, [mobileObjectMarker, mobileObject, centeredMobileObject, map])

  useEffect(() => {
    if(!SocketStore.getChannelData(ON_NEW_LAST_POINTS, mobileObject)) {
      SocketStore.addSocketChannel(ON_NEW_LAST_POINTS, mobileObject, lastPointFields)
    }

    return () => {
      SocketStore.removeSocketChannel(ON_NEW_LAST_POINTS, mobileObject, lastPointFields)
    }
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    return () => {
      if(marker.current) {
        map.removeLayer(marker.current);
      }
    }
  }, [marker, map])

  return (
    <>
    </>
  )
})

export default MobileObject;
