import styled from 'styled-components';

export const GroupButton = styled.div`
  margin: 0;
  padding: 0;
  list-style: none;

  > button {
    margin-right: 5px;
    min-height: 24px;
    padding: 0px 7px;

    &:last-child {
      margin-right: 0;
    }

    .bp3-button-text {
      display: flex;
    }
  }
`