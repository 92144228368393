import { makeAutoObservable, runInAction } from "mobx"

import { getReportsList } from 'api/reportsService';

const groupList = initialList => {
    if (!initialList) return null
    
    const categories = Array.from(new Set(initialList.map(item => item.categoryName)))
    return categories.map(item => ({
        category: item,
        items: initialList.filter(_item => _item.categoryName === item)
    }))
}
class ReportsListStore {

    data = []
    isLoading = false
    error = false
    isFetched = false

    constructor() {
        makeAutoObservable(this)
    }

    fetchReportsList = async () => {
        runInAction(() => this.isLoading = true)
        const res = await getReportsList()
        runInAction(() => {
            this.isLoading = false
            this.isFetched = true
            this.data = groupList(res)
        })
    }
}

export default new ReportsListStore()