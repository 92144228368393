import styled from 'styled-components'

const MobileObject = styled.div`
    height: 100%;
    width: 100%;
    padding: 1em 8px;
`;

const Tabs = styled.div`
    max-height: 100%;
    overflow-y: scroll;
`;

export const Styled = {
    MobileObject, Tabs
}