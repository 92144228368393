import React from 'react';
import styles from './ListRowPlaceholder.module.scss';

const ListRowPlaceholder = ({ style}) => {

  return (
    <div style={style} className={styles.row}>
        <span className={styles.placeholder}></span>
    </div>
  );
};

export default ListRowPlaceholder;
