import axios from 'axios'
import { showToasterD } from 'tools/AppToaster'

export const getWorkers = async () => {
    try {
        const res = await axios.get('/api/workers')
        return res.data.result
    } catch (e) {
        showToasterD('Не удалось получить список работников')
        console.log('Не удалось получить список работников: ', e);
    }
}