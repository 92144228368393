import React, { useEffect, useRef } from 'react';

import { createZone, createNameMarkerZone } from '../tools/createZone'

const EditedZone = ({
  editedZone,
  clearEditedZone,
  map,
  zoom,
}) => {
  const zone = useRef(null);
  const circleMarkerRef = useRef(null);
  const textMarker = useRef(null);

  useEffect(() => {
    const createdZone = createZone(editedZone, map, true, true);

    if(createdZone) {
      zone.current = createdZone.zoneMarkerRef;
      circleMarkerRef.current = createdZone.circleMarkerRef;
    }
    
    return () => {
      map.removeLayer(zone.current);

      if(circleMarkerRef.current) {
        map.removeLayer(circleMarkerRef.current);
      }
    }
  }, [editedZone, map]);

  useEffect(() => {
    return () => {
      clearEditedZone();
    }
  }, [clearEditedZone]);

  useEffect(() => {
    const nameZone = createNameMarkerZone(editedZone, map, zoom, true);

    if(nameZone) {
      textMarker.current = nameZone
    }

    if(zoom < 12) {
      if(textMarker.current) {
        map.removeLayer(textMarker.current)
      }
    }

    return () => {
      map.removeLayer(textMarker.current)
    }
  }, [zoom, editedZone, map])


  return <></>;
}

export default EditedZone;
