import styled from 'styled-components';
import { Navbar } from '@blueprintjs/core';

export const MainMenu = styled(Navbar)`
  grid-area: nav;
  height: unset;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;

  .bp3-navbar-group {
    height: auto;
  }

  .bp3-align-right {
    margin-left: auto;
  }
`

export const Styled = {
  MainMenu
}