import styled from 'styled-components';

export const FormContainer = styled.div`
  > input,
  .description {
    width: 100% !important;
    max-height: 100px;
  }

  .label-with-checkbox {
    display: flex;
    justify-content: space-between;
    background-color: white;
    cursor: pointer;
  }
` 