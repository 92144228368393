import React, { useState, useMemo, useEffect } from "react";
import { Button, Intent, Alert } from "@blueprintjs/core";
import ParamField from "./ParamField";
import FormModal from './FormModal'
import { groupBy } from 'tools/groupBy'
import cleanObject from 'tools/cleanObject'

import { Styled } from './AdditionalObjectAttributes.styled'

const AdditionalObjectAttributesView = ({
    allAttributes,
    allAttributesFetched,
    getAttributes,
    postAttribute,
    putAttribute,
    deleteAttribute,
    targetType,
    entityAttributes,
    onChange,
    editable = false,
    entityKey
}) => {
    // local state
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [formType, setFormType] = useState('')
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false)
    const [currentAttribute, setCurrentAttribute] = useState(null)

    // local data
    const groupedEntityAttributes = useMemo(() => groupBy(allAttributes, 'groupRu'), [allAttributes])

    // did mount
    useEffect(() => {
        if (allAttributesFetched) return
        getAttributes(targetType)
    }, [allAttributesFetched, getAttributes, targetType])

    const onAttributeChange = newAttribute => {
        onChange(cleanObject({ ...entityAttributes, ...newAttribute }))
    }

    // form handlers
    const handleSubmit = async (data) => {
        if (formType === 'post') {
            await postAttribute({ targetType, ...data })
            await getAttributes(targetType)
            setIsModalOpen(false)
        } else if (formType === 'put') {
            await putAttribute(currentAttribute.id, data)
            await getAttributes(targetType)
            setIsModalOpen(false)
        }
    }

    const handleDelete = async () => {
        await deleteAttribute(currentAttribute.id)
        getAttributes(targetType)
        setIsDeleteAlertOpen(false)
    }

    return (
        <>
            {Object.keys(groupedEntityAttributes).map(group => (
                <Styled.AdditionalUnit key={group} group={Boolean(group)}>
                    {group && (
                        <Styled.AdditionalUnitHeader>
                            <span>{group}</span>
                        </Styled.AdditionalUnitHeader>
                    )}
                    {groupedEntityAttributes[group].map(attribute => (
                        <Styled.AdditionalUnitBody key={attribute.code}>
                            <ParamField
                                type={attribute.dataType}
                                label={attribute.nameRu}
                                value={entityAttributes?.[attribute.code] || ''}
                                code={attribute.code}
                                entityKey={entityKey}
                                onChange={onAttributeChange}
                                btnEdit={editable && <Button minimal icon='edit' intent={Intent.WARNING} onClick={() => {
                                    setFormType('put')
                                    setIsModalOpen(true)
                                    setCurrentAttribute(attribute)
                                }} />}
                                btnDelete={editable && <Button minimal icon='trash' intent={Intent.DANGER} onClick={() => {
                                    setIsDeleteAlertOpen(true)
                                    setCurrentAttribute(attribute)
                                }} />}
                            />
                        </Styled.AdditionalUnitBody>
                    ))}
                </Styled.AdditionalUnit>
            ))}
            {editable && (
                <>
                    <Button
                        icon='plus'
                        intent={Intent.SUCCESS}
                        onClick={() => {
                            setFormType('post')
                            setCurrentAttribute(null)
                            setIsModalOpen(true)
                        }}
                    >
                        Добавить атрибут
                    </Button>
                    <FormModal
                        isOpen={isModalOpen}
                        onClose={isModalOpen => setIsModalOpen(!isModalOpen)}
                        initialData={currentAttribute}
                        onSubmit={handleSubmit}
                    />
                    <Alert
                        cancelButtonText='Отмена'
                        confirmButtonText='Удалить атрибут'
                        icon='undo'
                        intent={Intent.DANGER}
                        isOpen={isDeleteAlertOpen}
                        onCancel={() => setIsDeleteAlertOpen(false)}
                        onConfirm={handleDelete}
                    >
                        <p>Вы уверены, что хотите удалить <b>атрибут</b>?</p>
                    </Alert>
                </>
            )}
        </>
    );
};

export default AdditionalObjectAttributesView;
