import styled from 'styled-components';

export const AppMapControl = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: #fff;
  z-index: 1000;
  overflow: hidden;
`

export const CenteredMobileObject = styled.li`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  button {
    min-height: 24px;
    margin-left: 5px;
  }

  .icon {
    margin: 0 5px;
  }
`

export const CenteredMobileObjectName = styled.span`
  font-size: 0.8rem;
`

export const ResponsiveMenuButton = styled.li`
  padding: 5px;
`

export const AppMapControlUnits = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  height: 100%;

  .active {
    background: rgba(167, 182, 194, 0.3);
    box-shadow: none;
    color: #182026;
    text-decoration: none;

    &:hover {
      background: rgba(167, 182, 194, 0.3);
      box-shadow: none;
      color: #182026;
      text-decoration: none;
    }
  }

  .hovered {
    &:hover {
      background: rgba(167, 182, 194, 0.3);
      box-shadow: none;
      color: #182026;
      text-decoration: none;
    }
  }

  &.responsive {
    flex-direction: column;
    justify-content: space-between;

    .app-map-control-unit {
      width: 100%;
      border-bottom: 1px solid lightgray;
      border-right: none;
      max-width: 100%;
      text-align: center;
    }
  }
`

export const AppMapControlUnit = styled.li`
  display: inline-block;
  padding: 5px;
  border-right: 1px solid lightgray;

  &:last-child {
    border-right: 0;
    flex: 1;
  }

  .custom-button {
    background: none;
    box-shadow: none;
    padding: 0 10px;
    min-height: 20px;
  }

  .search-map-input {
    display: flex;
    justify-content: center;
    align-items: center;

    > .bp3-input-group {
      > .bp3-input {
          height: 25px !important;
          outline: none;
          box-shadow: 0 0 0 0 rgba(19, 124, 189, 0),
                      0 0 0 0 rgba(19, 124, 189, 0),
                      inset 0 0 0 1px rgba(16, 22, 26, 0.15),
                      inset 0 1px 1px rgba(16, 22, 26, 0.2);
        }
    }

    button {
      min-height: 25px;
      padding: 4px 7px;
    }
  }
`

export const Styled = {
  AppMapControl,
  AppMapControlUnits,
  AppMapControlUnit,
  CenteredMobileObject,
  CenteredMobileObjectName,
  ResponsiveMenuButton,
}