import React, { useEffect, useRef } from 'react';

import { initGoldenLayout } from 'tools/initGoldenLayout'

import 'golden-layout/src/css/goldenlayout-base.css';
import 'golden-layout/src/css/goldenlayout-light-theme.css';
import './Dock.scss'

const Dock = () => {
  const dockRef = useRef(null);

  useEffect(() => {
    if(dockRef.current) {
      initGoldenLayout(dockRef.current);
    }
  }, [])

  return (
    <div ref={dockRef} className="main-dock" />
  )
};

export default Dock;
