import React, { useState } from "react";
import moment from 'moment';
import "moment/locale/ru";
import { Dialog, Classes, Button, FormGroup, Radio, RadioGroup, Intent } from "@blueprintjs/core";
import { DateInput, TimePrecision } from "@blueprintjs/datetime";

import { useDateInput } from 'tools/inputHandlers';
import DateFormatString from 'constants/locales';


const getMomentFormatter = (format) => {
    return {
        formatDate: (date, locale) => moment(date).locale(locale).format(format),
        parseDate: (str, locale) => moment(str, format).locale(locale).toDate(),
        placeholder: format,
    }
}

const DeleteModal = ({ selectedItem, isOpen, handleClose, onDelete, onDismantle, isLoading }) => {

    const [willRemove, setWillRemove] = useState('false')
    const [dismantleDate, setDismantleDate] = useDateInput(new Date())
    const isController = selectedItem?.entityType === 'controller'
    const isSensor = selectedItem?.entityType === 'sensor'
    const isAdditionalDevice = selectedItem?.entityType === 'additionalDevice'

    const handleSubmit = () => {
        if (willRemove === 'true') {
            onDelete()
        }
        else {
            const offset = new Date().getTimezoneOffset() * 60000
            const newDismantleDate = new Date((new Date(dismantleDate) - offset)).toISOString().slice(0, -5)
            onDismantle(newDismantleDate)
        }
    }

    const onClose = () => {
        console.log('qweqweqweqweewqwe');
        setWillRemove('false')
        setDismantleDate(new Date())
        handleClose()
    }

    const operationName = willRemove ? 'Удалить' : 'Демонтировать'
    const typeString = isController ? 'контроллер' : isSensor ? 'датчик' : isAdditionalDevice ? 'дополнительное оборудование' : ''

    return (
        <Dialog
            isOpen={isOpen}
            icon="remove"
            onClose={onClose}
            title={`${operationName} ${typeString}`}
            canOutsideClickClose={!isLoading}
        >
            <div className={Classes.DIALOG_BODY}>
                {!isAdditionalDevice && (
                    <FormGroup inline={false} label="Дата демонтажа">
                        <RadioGroup
                            onChange={e => setWillRemove(e.target.value)}
                            selectedValue={willRemove}
                        >
                            <Radio label="Демонтировать" value='false' />
                            <Radio label="Удалить" value='true' />
                        </RadioGroup>
                    </FormGroup>
                )}
                {willRemove === 'false' && (
                    <FormGroup inline={true} label="Дата демонтажа">
                        <DateInput
                            dayPickerProps={{
                                months: DateFormatString.months,
                                weekdaysLong: DateFormatString.weekDaysLong,
                                weekdaysShort: DateFormatString.weekDaysShort,
                                firstDayOfWeek: 1
                            }}
                            timePrecision={TimePrecision.MINUTE}
                            onChange={e => setDismantleDate(e)}
                            defaultValue={dismantleDate}
                            locale="RU-ru"
                            {...getMomentFormatter('DD.MM.YYYY : HH.mm')}
                        />
                    </FormGroup>
                )}
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                    <Button onClick={handleSubmit} disabled={isLoading} intent={Intent.DANGER} icon='trash'>{operationName}</Button>
                    <Button onClick={handleClose} disabled={isLoading} intent={Intent.SUCCESS} icon='undo'>Отмена</Button>
                </div>
            </div>
        </Dialog >
    );
}

export default DeleteModal;
