import React from "react";
import { useQuery } from 'react-query'

import { getEnterprises } from "api/enterprisesService";

import { MenuItem, Button } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import queryClient from 'tools/queryClient'

const EnterprisesSelect = ({ value, onChange }) => {

  const { isLoading, data } = useQuery("EnterprisesListForSelect", () => getEnterprises(), {
    initialData: () => queryClient.getQueryData('EnterprisesListForSelect')
  });

  const renderItem = (item, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <li
        className="model-item"
        key={item.id}
        onClick={handleClick}
        query={item.name}
      >
        <p>{item.displayName}</p>
      </li>
    );
  };

  const filterModels = (query, item) => {
    if (item.displayName || item.name) {
      if (!item.displayName) {
        return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
      } else {
        return (
            item.displayName.toLowerCase().indexOf(query.toLowerCase()) >= 0
        );
      }
    }
    return false;
  };

  return (
    <Select
      id="modelSelect"
      items={data || []}
      filterable={true}
      itemRenderer={renderItem}
      itemPredicate={filterModels}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={onChange}
      activeItem={value}
      disabled={!data?.length}
    >
      <Button
        loading={isLoading}
        disabled={!data?.length}
        text={value?.displayName || 'Выберите предприятие'}
        rightIcon="caret-down"
      />
    </Select>
  );
};

export default EnterprisesSelect;
