import React, { Component } from 'react';
import { showToasterD } from 'tools/AppToaster';

class ErrorBoundary extends Component {
  state = {
    hasError: false
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo)
    showToasterD('Что-то пошло не так!');
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }


  render() {
    return (
      this.state.hasError
        ? <h1>Что-то пошло не так!</h1>
        : this.props.children
    );
  }
}

export default ErrorBoundary;
