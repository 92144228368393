import React, { useState } from 'react';
import { useQuery } from 'react-query'
import { Spinner } from '@blueprintjs/core'

import VirtualList from 'components/VirtualList'

import { getBrigades } from 'api/brigadesService'

import { Styled } from '../LeftPanel.styled'

const WorkersList = () => {

    const [selectedObjectIds, setSelectedObjectIds] = useState([])

    const { data, isLoading, error } = useQuery("brigadesListForObjectsObserver", () => getBrigades())

    return (
        <Styled.ListContainer>
            {isLoading ? (
                <Spinner />
            ) : error ? (
                <p>Произошла ошибка, попробуйте перезагрузить страницу</p>
            ) : (
                <VirtualList
                    list={data?.map(item => ({ objectId: item.id, ...item }))}
                    needSelectObj={false}
                    rowCount={data.length}
                    onRowDblClick={() => { }}
                    selectedObjectIds={selectedObjectIds}
                    setSelectedObjectIds={setSelectedObjectIds}
                    searchedObjectIndex={null}
                />
            )}
        </Styled.ListContainer>
    );
}

export default WorkersList;