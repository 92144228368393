import axios from 'axios'
import { showToasterD } from 'tools/AppToaster'

// Получение списка маршрутов
export const getRoutes = async (fields = '') => {
    try {
        const res = await axios.get(`/api/routes${fields}`)
        return res.data.result
    } catch (e) {
        showToasterD('Не удалось получить спискок маршрутов')
        console.log('Не удалось получить список маршрутов: ', e);
    }
}