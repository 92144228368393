import styled from 'styled-components'
import { Button } from '@blueprintjs/core';
import { Map as MapContainer } from 'react-leaflet';

export const AppMapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 5;
`

export const Map = styled(MapContainer)`
  position: absolute;
  width: 100%;
  height: 100%;

  .span-temp {
    color: #FFFFFF;
    text-shadow: 
      -0   -1px 0   #000000,
      0   -1px 0   #000000,
      -0    1px 0   #000000,
      0    1px 0   #000000,
      -1px -0   0   #000000,
      1px -0   0   #000000,
      -1px  0   0   #000000,
      1px  0   0   #000000,
      -1px -1px 0   #000000,
      1px -1px 0   #000000,
      -1px  1px 0   #000000,
      1px  1px 0   #000000,
      -1px -1px 0   #000000,
      1px -1px 0   #000000,
      -1px  1px 0   #000000,
      1px  1px 0   #000000;
  }

  .leaflet-control-zoom { 
    a {
      color: #5c7080;
    }
  }

  .weather-marker-cursor,
  .new-map-dot,
  .save-img-cursor,
  .search-address-cursor {
    position: absolute;
    color: #FFFFFF;
    text-shadow: 
      -0   -1px 0   #000000,
      0   -1px 0   #000000,
      -0    1px 0   #000000,
      0    1px 0   #000000,
      -1px -0   0   #000000,
      1px -0   0   #000000,
      -1px  0   0   #000000,
      1px  0   0   #000000,
      -1px -1px 0   #000000,
      1px -1px 0   #000000,
      -1px  1px 0   #000000,
      1px  1px 0   #000000,
      -1px -1px 0   #000000,
      1px -1px 0   #000000,
      -1px  1px 0   #000000,
      1px  1px 0   #000000;
    z-index: 999;
  }

  &.weathermarker,
  .weathermarker,
  &.adddotmap,
  .adddotmap,
  &.save-img-cursor,
  .save-img-cursor,
  &.search-address-cursor,
  .search-address-cursor {
    cursor: default !important;
    .leaflet-interactive {
      cursor: default !important;
    }
  }

  &.addzonecorridor,
  .addzonecorridor,
  &.addzonedot,
  .addzonedot {
    cursor: crosshair !important;
    .leaflet-interactive {
      cursor: crosshair !important;
    }
  }

  &.area,
  .area,
  &.addzonepolygon,
  .addzonepolygon {
    cursor: move !important;
    .leaflet-interactive {
      cursor: move !important;
    }
  }

  

  &.saveimg {
    position: relative;

    &:before {
      content: 'Кликните, чтобы сохранить';
    }
  }

  .number-marker {
    color: #000;
    font-size: 12px;
    text-shadow: white 0px -2px 0px;
  }


  .mobile-object-popup {
    margin-bottom: 75px;
  }

  .mobile-object-icon {
    background: none!important;
    border: none!important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mobile-object {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    &.active {
      span {
        background: red;
        color: #fff;
        text-align: center;
      }
    }
  
    span {
      background: #fff;
      padding: 0 1px;
      margin-top: 5px;
      font-size: 11px;
      text-align: center;
    }
  
    .course-container {
      position: absolute;
      top: -20px;
      left: 0;
      bottom: 0;
      right: 0;
      width: 30px;
      height: 50px;
  
      .course {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        margin: 0 auto;
      }
    }
  }
`

export const GetGeolocation = styled(Button)`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999;
  margin-left: 10px;
  margin-bottom: 10px;
  min-height: 40px;
  min-width: 40px;
`

export const Styled = {
  AppMapContainer,
  Map,
  GetGeolocation,
}