import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Circle, CircleMarker } from "react-leaflet";
import { latLng } from "leaflet";

// Import components
import EditPopup from './EditPopup';
import CompleteCreateZone from "../../CompleteCreateZone";

// Import tools
import { appendChildToMap } from "../../tools/appendChild";

const AddZoneDot = ({
  center,
  radiusMouseMove,
  activeRadius,
  map,
  setActiveButton,
  setCenterZoneDot,
  setRadiusMouseMove,
  setActiveRadius,
  createInitialZone,
  setActiveCreateZone,
  setCreateCompletePopup,
  createCompleteZonePopup,
  editedPopupIsHidden,
}) => {
  const [radius, setRadius] = useState(0);
  const [zoneColor, setZoneColor] = useState({
    r: createInitialZone.color[0],
    g: createInitialZone.color[1],
    b: createInitialZone.color[2],
    a: +(createInitialZone.color[3] / 255).toFixed(2),
  });
  // The reference belongs EditPopup
  const editPopupRef = useRef();

  const setRadiusCallback = useCallback(() => {
    if(!activeRadius) {
      const radius = latLng(center).distanceTo(radiusMouseMove);

      setRadius(radius);
    }
  }, [radiusMouseMove, activeRadius, center])

  useEffect(() => {
    setRadiusCallback()
  }, [setRadiusCallback]);

  const clearEffectsCallback = useCallback(() => {
    appendChildToMap(map._container, editPopupRef.current);
    setCenterZoneDot({})
    setRadiusMouseMove({})
    setActiveRadius(null);
    setActiveCreateZone(false);
    setCreateCompletePopup(false);
  }, [
    setCenterZoneDot,
    setRadiusMouseMove,
    setActiveRadius,
    setActiveCreateZone,
    setCreateCompletePopup,
    map,
    editPopupRef
  ]);

  useEffect(() => {
    return () => {
      clearEffectsCallback()
    }
  }, [clearEffectsCallback]);

  const handleSetTogglePopup = () => {
    clearEffectsCallback();
    setActiveButton('');
  };


  return (
    <>
      {
        createCompleteZonePopup &&
        <CompleteCreateZone
          map={map}
          setActiveCreateZone={setActiveCreateZone}
          setCreateCompletePopup={setCreateCompletePopup}
          createInitialZone={createInitialZone}
          center={center}
          radius={radius}
          editPopupRef={editPopupRef}
          editedPopupIsHidden={editedPopupIsHidden}
          handleSetTogglePopup={handleSetTogglePopup}
          zoneColor={zoneColor}
          className="map"
        />
      }
      {
        !createCompleteZonePopup &&
        <EditPopup
          handleSetTogglePopup={handleSetTogglePopup}
          radius={radius}
          setRadius={setRadius}
          color={zoneColor}
          editPopupRef={editPopupRef}
          setColor={setZoneColor}
          className="map"
          map={map}
          setCreateCompletePopup={setCreateCompletePopup}
          editedPopupIsHidden={editedPopupIsHidden}
        />
      }
      <Circle
        center={center}
        radius={radius}
        color={`rgba(${zoneColor.r}, ${zoneColor.g}, ${zoneColor.b}, ${zoneColor.a})`}
      >
        <CircleMarker
          center={center}
          radius={1}
          color="red"
          className="circle-marker"
        />
      </Circle>
    </>
  );
};

export default AddZoneDot;
