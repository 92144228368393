import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Alert, InputGroup, Icon } from '@blueprintjs/core';
import { ZoomControl } from 'react-leaflet';
import { latLng, canvas, CRS } from "leaflet";

// Import app components
import AppMapControl from "./AppMapControl";
import TileLayers from "./TileLayers";
import Ruler from "./Ruler";
import Area from "./Area";
import AddZoneDot from "./Zones/AddZoneDot";
import EditedZone from "./EditedZone";
import ErrorBoundary from "components/ErrorBoundary";
import Address from './Address'
import WeatherMarkers from './WeatherMarkers'
import Markers from './Markers'
import AddMarkerPopup from './AddMarkerPopup';
import Zones from './Zones';
import Track from './Track'
import MobileObjects from './MobileObjects'

// Import constants
import { tileLayers as tileLayersConstans } from "constants/tileLayers";
import {
  RULER,
  AREA,
  ADDZONEDOT,
  ADDZONEPOLYGON,
  ADDZONECORRIDOR,
  SAVEIMG,
  SEARCHADDRESSBYCOORD,
  WEATHERMARKER,
  ADDDOTMAP,
} from 'constants/typeButtons';

// Import tools
import { getDistance } from "./tools/getDistance";
import { getArea } from './tools/getArea';
import { getCentroid } from './tools/getCentroid';
import { initialStateZone } from './tools/initialStateZone';
import { makeScreenshotMap } from "./tools/makeScreenshotMap";
import { showToasterW } from 'tools/AppToaster';
import { getGeolocation } from 'tools/navigator';
import { renderMarker } from './AddMarkerPopup/AddMarkerPopup'

// Import api
import { getAddressByCoords } from 'api/geoDataService';
import { createWeatherMarker, createNewMarker } from 'api/mapMarkersService';

// Import styles
import 'leaflet/dist/leaflet.css';
import { Styled } from './AppMap.styled'

const AppMap = ({
  zoneCatalog,
  editedZone,
  activeButton = '',
  hideControlButtons,
  clearEditedZone,
  glContainer,
  glEventHub,
  center,
  centeredMobileObject,
  onCenteredMobileObject,
  onRemoveCenteredMobileObject,
  getCoordinateByClick,
  mapRef,
  setMapBounds,
  address,
  showAllZones,
  track,
  objects
}) => {
  // Map reference for handling some events
  // eslint-disable-next-line
  mapRef = mapRef ? mapRef : useRef(null);
  // Map container reference for hide modals
  const mapContainerRef = useRef(null);
  const searchAddressRef = useRef(null);
  const saveImgRef = useRef(null);
  const markerWeatherRef = useRef(null);
  const newMapDotRef = useRef(null);

  // General states of the component
  const [_center, setCenter] = useState(center);
  const [zoom, setZoom] = useState(13)

  const [mobileObjects, setMobileObjects] = useState([]);
  const [_centeredMobileObject, setCenteredMobileObject] = useState(centeredMobileObject);

  const [editedPopupIsHidden, setEditedPopupIsHidden] = useState(false);
  // Initial state for creating zone
  const [createInitialZone, setCreateInitialZone] = useState({});
  // Active tile layer uses for the map
  const [activeTileLayer, setActiveTileLayer] = useState(
    localStorage.getItem('mapTileLayer')
      ? JSON.parse(localStorage.getItem('mapTileLayer'))
      : tileLayersConstans[0]
  );
  // Active button on AppMapControl for some handling on the map
  const [_activeButton, setActiveButton] = useState(activeButton);
  // Show title by hover for some events
  const [mapTitle, setMapTitle] = useState('');
  // Boolean value for processing active create a zone
  const [activeCreateZone, setActiveCreateZone] = useState(false);
  // Boolean value for processing a finish creating a zone
  const [createCompleteZonePopup, setCreateCompletePopup] = useState(false);
  // Special string for getting position by street, region, etc...
  const [searchString, setSearchString] = useState('');
  // Mouse move event processing for getting a position that uses on create a new zone
  const [mouseMovePositions, setMouseMovePositions] = useState({});

  // Ruler & Corridor state
  // Special array for stores positions like LatLng
  const [rulerPositions, setRulerPositions] = useState([]);
  // Special array for stores distances between positions
  const [distances, setDistances] = useState([0]);

  // Area & Polygon state
  // Special array for stores positions like LatLng like for Ruler
  const [areaPositions, setAreaPositions] = useState([]);
  // The number equals area by areaPositions
  const [calculatedArea, setCalculatedArea] = useState(0);
  // The special position that uses for setting the center of Area for calculatedArea
  const [centerPolygon, setCenterPolygon] = useState(null);

  // Add zone type dot with radius
  // The special position that uses for setting the center of zone type dot with radius
  const [centerZoneDot, setCenterZoneDot] = useState({});
  // Mouse move event processing for getting a position that uses on create a new zone for radius
  const [radiusMouseMove, setRadiusMouseMove] = useState({});
  // The special number equals the final radius
  const [activeRadius, setActiveRadius] = useState(null);

  // The handlers for typing filename save as map
  const [activeAlertFilename, setActiveAlertFilename] = useState(false);
  const [fileName, setFilename] = useState(editedZone?.name || 'Карта');
  const [formatFilename, setFormatFilename] = useState('png')

  const [_address, setAddress] = useState(address);

  const [newMapPoint, setNewMapPoint] = useState({});
  const [addMarkerPopup, setAddMarkerPopup] = useState(false);
  const [newMapDot, setNewMapDot] = useState({
    marker: null,
    text: ''
  })

  // Functions
  // The function uses for setting area to component Area that depends on func getArea (function not pure)
  const setStateArea = state => {
    if (state.length > 0) {
      const area = getArea(state);
      setCalculatedArea(area);
    }
  };

  // The function uses for setting a center to component Area that depends of func getCentroid (function not pure)
  const setCentroidArea = state => {
    if (state.length > 2) {
      const arrCenter = [];
      Object.values(state).map(pos => arrCenter.push([pos.lat, pos.lng]));
      const center = getCentroid(arrCenter);
      setCenterPolygon(latLng(center[0], center[1]));
    }
  };

  // Handlers
  // The handler for save map as image
  const handleConfirmSaveMapAsImage = () => {
    if (fileName && formatFilename) {
      setActiveAlertFilename(false);
      makeScreenshotMap(mapRef.current.container.parentNode, fileName, formatFilename);
      setMapTitle('');
      setActiveButton('');
    }
  };

  // The handler changes search string by value received from BluePrint input
  const handleChangeSearchString = value => setSearchString(value);

  // The handler changes an active tile layer on map
  const handleSetActiveTileLayer = value => {
    const tileLayer = tileLayersConstans.find(tile => tile.title === value);

    if (tileLayer) {
      const center = mapRef.current.leafletElement.getCenter();

      localStorage.setItem('mapTileLayer', JSON.stringify(tileLayer));
      setActiveTileLayer(tileLayer);

      mapRef.current.leafletElement.options.crs = tileLayer.crs;
      mapRef.current.leafletElement.setView(center);
      setCenter(center);
    }
  };

  // The handler changes an active button on AppMapControl by attribute of a button
  const handleSetActiveButton = value => setActiveButton(value);

  // The handler event by click changes states of AppMap that depends on an active button
  const handleClick = async e => {
    if (typeof getCoordinateByClick === 'function') {
      getCoordinateByClick(e.latlng)
    }

    switch (_activeButton) {
      case RULER:
      case ADDZONECORRIDOR:
        if (!activeCreateZone && !createCompleteZonePopup) {
          if (!createInitialZone?.state && _activeButton !== RULER) {
            const zone = initialStateZone(2);
            setCreateInitialZone(zone)
          }
          setRulerPositions(prevState => [...prevState, e.latlng]);
          if (rulerPositions[rulerPositions.length - 1]) {
            let distance = getDistance(rulerPositions[rulerPositions.length - 1], mouseMovePositions);
            setDistances(prevState => [...prevState, prevState[prevState.length - 1] + +distance]);
          }
        }
        return;
      case AREA:
      case ADDZONEPOLYGON:
        if (!activeCreateZone && !createCompleteZonePopup) {
          if (!createInitialZone?.state && _activeButton === ADDZONEPOLYGON) {
            const zone = initialStateZone(1);
            setCreateInitialZone(zone)
          }
          setAreaPositions(prevState => {
            const newState = [...prevState, e.latlng];
            setStateArea(newState);
            setCentroidArea(newState);
            return newState;
          });
        }
        return;
      case ADDZONEDOT:
        if (!createInitialZone?.state) {
          const zone = initialStateZone(0);
          setEditedPopupIsHidden(false)
          setCreateInitialZone(zone)
        }
        if (centerZoneDot.lat && !activeRadius) {
          const radius = latLng(centerZoneDot).distanceTo(e.latlng);
          setActiveRadius(radius);
        } else if (!centerZoneDot.lat && !activeRadius) {

          setCenterZoneDot(e.latlng);
        }
        return;
      case SEARCHADDRESSBYCOORD:
        const address = await getAddressByCoords(e.latlng.lat, e.latlng.lng);
        setAddress(address);
        setActiveButton('');
        return;
      case SAVEIMG:
        setActiveAlertFilename(true);
        return;
      case WEATHERMARKER:
        createWeatherMarker([e.latlng.lng, e.latlng.lat]);
        setActiveButton('');
        return;
      case ADDDOTMAP:
        const { id } = await createNewMarker([e.latlng.lng, e.latlng.lat], newMapDot.marker.type, newMapDot.text);
        setNewMapPoint({ 
          id, 
          location: [e.latlng.lng, e.latlng.lat], 
          type: newMapDot.marker.type, 
          text: newMapDot.text 
        });
        setNewMapDot({
          marker: null,
          text: '',
        });
        setActiveButton('');
        return;

      default:
        return;
    }
  };

  // The handler event by right click changes states of AppMap that depends on an active button
  const handleRightClick = e => {
    e.originalEvent.preventDefault();

    switch (_activeButton) {
      case RULER:
      case ADDZONECORRIDOR:
        if (!createCompleteZonePopup) {
          setRulerPositions(prevState => {
            prevState.pop();
            return [...prevState];
          });
          setDistances(prevState => {
            if (prevState.length > 1) {
              prevState.pop();
            }
            return [...prevState];
          });
        }
        return;
      case AREA:
      case ADDZONEPOLYGON:
        setAreaPositions(prevState => {
          setStateArea(prevState);
          setCentroidArea(prevState);
          prevState.pop();
          return [...prevState];
        });
        return;
      case ADDZONEDOT:
        if (!createCompleteZonePopup) {
          if (centerZoneDot.lat && activeRadius) {
            setActiveRadius(null);
          } else if (centerZoneDot.lat && !activeRadius) {
            setCenterZoneDot({});
          }
        }
        return;
      case ADDDOTMAP:
        setNewMapDot({
          marker: null,
          text: '',
        });
        setActiveButton('');
        return;

      default:
        setActiveButton('');
        return;
    }
  };

  // The handler event by mouse move changes states of AppMap that depends on an active button
  const handelMouseMove = e => {
    switch (_activeButton) {
      case RULER:
      case ADDZONECORRIDOR:
        if (!createCompleteZonePopup) {
          if (rulerPositions.length > 0) {
            setMouseMovePositions(e.latlng);
          }
        }
        return;
      case AREA:
      case ADDZONEPOLYGON:
        if (areaPositions.length < 2) {
          setMouseMovePositions(e.latlng);
        }
        return;
      case ADDZONEDOT:
        setRadiusMouseMove(e.latlng);
        return;
      case SAVEIMG:
        if(saveImgRef.current) {
          saveImgRef.current.style.top = (e.originalEvent.pageY - 60) + 'px'
          saveImgRef.current.style.left = (e.originalEvent.pageX - 390) + 'px'
        }
        return;
      case WEATHERMARKER:
        if(markerWeatherRef.current) {
          markerWeatherRef.current.style.top = (e.originalEvent.pageY - 60) + 'px'
          markerWeatherRef.current.style.left = (e.originalEvent.pageX - 390) + 'px'
        }
        return;
      case ADDDOTMAP:
        if(newMapDotRef.current) {
          newMapDotRef.current.style.top = (e.originalEvent.pageY - 70) + 'px'
          newMapDotRef.current.style.left = (e.originalEvent.pageX - 393) + 'px'
        }
        return;
      case SEARCHADDRESSBYCOORD:
        if(searchAddressRef.current) {
          searchAddressRef.current.style.top = (e.originalEvent.pageY - 78) + 'px'
          searchAddressRef.current.style.left = (e.originalEvent.pageX - 400) + 'px'
        }
        return;

      default:
        return;
    }
  };

  const updateActiveButtonWithCallback = useCallback(() => {
    setActiveButton(activeButton)
  }, [activeButton]);

  const updatePlaceholderFilenameByEditedZone = useCallback(() => {
    if (editedZone?.name) {
      setFilename(editedZone.name);
    }
  }, [editedZone]);

  const onGetGeolocation = needAddressMarker => {
    if(mapRef?.current?.leafletElement) {
      getGeolocation(
        pos => {
          mapRef.current.leafletElement.setView([pos.coords.latitude, pos.coords.longitude]);
          setCenter([pos.coords.latitude, pos.coords.longitude]);
          if(needAddressMarker) {
            setAddress({
              location: [pos.coords.longitude, pos.coords.latitude],
              displayName: `Приблизительно ваше местоположение, может отличаться на ${pos.coords.accuracy}м.`
            })
          }
        },
        () => showToasterW('Не удалось определить местоположение!')
      )
    }
  }

  const onMoveEnd = e => {
    setZoom(e.target._zoom)
    if (setMapBounds) {
      setMapBounds(e.target.getBounds());
    }
  }

  const handleMobileObjectCentered = item => {
    if(typeof onCenteredMobileObject === 'function') {
      onCenteredMobileObject(item);
    }
    
    setCenteredMobileObject(item);
  }

  useEffect(() => {
    updateActiveButtonWithCallback();
  }, [updateActiveButtonWithCallback]);

  useEffect(() => {
    updatePlaceholderFilenameByEditedZone();

    return () => {
      setFilename('');
    }
  }, [updatePlaceholderFilenameByEditedZone]);

  useEffect(() => {
    mapRef.current.leafletElement.invalidateSize()
  });

  useEffect(() => {
    if(glEventHub) {
      glEventHub.on('isActiveChange', contentItem => {
        if(mapRef.current) {
          setEditedPopupIsHidden(true);

          if(contentItem.config.id === glContainer._config.id) {
            setEditedPopupIsHidden(false);
          }
        }
      });
    }

    onGetGeolocation()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setAddress(address);
  }, [address]);

  useEffect(() => {
    setCenter(center);
  }, [center]);

  useEffect(() => {
    if(_activeButton === ADDDOTMAP) {
      setAddMarkerPopup(true);
    }
  }, [_activeButton]);

  useEffect(() => {
    setMobileObjects(objects)
  }, [objects]);

  useEffect(() => {
    if(centeredMobileObject?.mobileObject) {
      setCenteredMobileObject(centeredMobileObject)
      setMobileObjects(prevObjects => {
        if(!prevObjects.includes(centeredMobileObject.mobileObject.id)) {
          return [...prevObjects, centeredMobileObject.mobileObject.id]
        }

        return prevObjects
      })
    } else {
      setCenteredMobileObject({})
    }
  }, [centeredMobileObject]);

  return (
    <ErrorBoundary>
      <Styled.AppMapContainer
        data-testid="app-map-container"
        title={mapTitle}
        ref={mapContainerRef}
      >
        <AppMapControl
          mapRef={mapRef}
          activeTileLayer={activeTileLayer}
          tileLayers={tileLayersConstans}
          searchString={searchString}
          activeButton={_activeButton}
          handleChangeSearchString={handleChangeSearchString}
          handleSetActiveTileLayer={handleSetActiveTileLayer}
          handleSetActiveButton={handleSetActiveButton}
          setAddress={setAddress}
          hideButtons={hideControlButtons}
          centeredMobileObject={_centeredMobileObject}
          onRemoveCenteredMobileObject={onRemoveCenteredMobileObject}
          setCenteredMobileObject={setCenteredMobileObject}
        />
        <Styled.Map
          ref={mapRef}
          center={_center}
          zoom={zoom}
          className={`${_activeButton}`}
          attributionControl={false}
          zoomControl={false}
          doubleClickZoom={false}
          scrollWheelZoom={true}
          dragging={true}
          animate={true}
          zoomAnimation={true}
          crs={activeTileLayer.crs.code === CRS.EPSG3857.code ? CRS.EPSG3857 : CRS.EPSG3395}
          onClick={handleClick}
          onContextMenu={handleRightClick}
          onMouseMove={handelMouseMove}
          renderer={canvas()}
          onmoveend={onMoveEnd}
        >
          <TileLayers
            tileLayers={tileLayersConstans}
            activeTileLayer={activeTileLayer}
          />

          {
            rulerPositions &&
            (_activeButton === RULER || _activeButton === ADDZONECORRIDOR) &&
            mapRef.current.leafletElement &&
            <Ruler
              positions={rulerPositions}
              distances={distances}
              setDistances={setDistances}
              setRulerPositions={setRulerPositions}
              setMouseMovePositions={setMouseMovePositions}
              map={mapRef.current.leafletElement}
              createZoneCorridor={_activeButton === ADDZONECORRIDOR ? true : false}
              activeButton={_activeButton}
              createInitialZone={createInitialZone}
              setActiveButton={setActiveButton}
              setActiveCreateZone={setActiveCreateZone}
              setCreateCompletePopup={setCreateCompletePopup}
              createCompleteZonePopup={createCompleteZonePopup}
              editedPopupIsHidden={editedPopupIsHidden}
            />
          }

          {
            areaPositions &&
            (_activeButton === AREA || _activeButton === ADDZONEPOLYGON) &&
            mapRef.current.leafletElement &&
            <Area
              map={mapRef.current.leafletElement}
              positions={areaPositions}
              mouseMovePositions={mouseMovePositions}
              calculatedArea={calculatedArea}
              centerPolygon={centerPolygon}
              setAreaPositions={setAreaPositions}
              setCalculatedArea={setCalculatedArea}
              activeButton={_activeButton}
              setActiveButton={setActiveButton}
              setCenterPolygon={setCenterPolygon}
              setActiveCreateZone={setActiveCreateZone}
              setCreateCompletePopup={setCreateCompletePopup}
              createZonePolygon={_activeButton === ADDZONEPOLYGON ? true : false}
              createInitialZone={createInitialZone}
              createCompleteZonePopup={createCompleteZonePopup}
              editedPopupIsHidden={editedPopupIsHidden}
            />
          }

          {
            centerZoneDot.lat &&
            _activeButton === ADDZONEDOT &&
            <AddZoneDot
              createInitialZone={createInitialZone}
              map={mapRef.current.leafletElement}
              center={centerZoneDot}
              radiusMouseMove={radiusMouseMove}
              activeRadius={activeRadius}
              centerZoneDot={centerZoneDot}
              mapRefCurrent={mapRef.current}
              createCompleteZonePopup={createCompleteZonePopup}
              editedPopupIsHidden={editedPopupIsHidden}
              setActiveButton={setActiveButton}
              setCenterZoneDot={setCenterZoneDot}
              setRadiusMouseMove={setRadiusMouseMove}
              setActiveRadius={setActiveRadius}
              setActiveCreateZone={setActiveCreateZone}
              setCreateCompletePopup={setCreateCompletePopup}
            />
          }

          {
            zoneCatalog &&
            editedZone?.states &&
            mapRef?.current?.leafletElement &&
            <EditedZone
              editedZone={editedZone}
              map={mapRef.current.leafletElement}
              clearEditedZone={clearEditedZone}
              zoom={zoom}
            />
          }

          {
            mapRef?.current?.leafletElement &&
            <Zones
              showAllZones={showAllZones}
              map={mapRef.current.leafletElement}
              zoom={zoom}
            />
          }

          {
            track?.points?.length &&
            mapRef?.current?.leafletElement &&
            <Track 
              track={track}
              map={mapRef.current.leafletElement}
              setCenter={setCenter}
            />
          }

          {
            mapRef?.current?.leafletElement &&
            <MobileObjects
              centeredMobileObject={_centeredMobileObject}
              mobileObjects={mobileObjects} 
              map={mapRef.current.leafletElement}
              setCenteredMobileObject={handleMobileObjectCentered}
            />
          }

          {
            activeAlertFilename &&
            <Alert
              isOpen={activeAlertFilename}
              cancelButtonText="Отменить"
              canOutsideClickCancel={true}
              onCancel={() => setActiveAlertFilename(false)}
              onConfirm={handleConfirmSaveMapAsImage}
            >
              <h5>Название изображения</h5>
              <InputGroup
                value={fileName}
                placeholder="Введите название изображения"
                autoFocus={activeAlertFilename}
                onChange={e => setFilename(e.target.value)}
                intent={fileName.length ? "primary" : "danger"}
              />

              <h5 className="mt-2">Формат</h5>
              <div className="bp3-select modifier wrap-select">
                <select value={formatFilename} onChange={e => setFormatFilename(e.target.value)}>
                  <option value="png">PNG</option>
                  <option value="jpeg">JPEG</option>
                </select>
              </div>
            </Alert>
          }

          {
            _address && 
            <Address 
              address={_address}
              setCenter={setCenter}
              setAddress={setAddress}
            />
          }

          {
            _activeButton === WEATHERMARKER &&
            <div className="weather-marker-cursor" ref={markerWeatherRef}>
              <Icon icon="cloud" color="#00d0ff" />
              <span className="ml-1">Погода</span>
            </div>
          }

          {
            _activeButton === SAVEIMG &&
            <div className="save-img-cursor" ref={saveImgRef}>
              <Icon icon="floppy-disk" intent="primary" />
              <span className="ml-1">Сохранить карту</span>
            </div>
          }

          {
            _activeButton === SEARCHADDRESSBYCOORD &&
            <div className="search-address-cursor" ref={searchAddressRef}>
              <Icon icon="map-marker" intent="danger" iconSize={30} />
              <span className="ml-1">Найти адрес</span>
            </div>
          }
          
          {
            newMapDot?.marker?.type &&
            <div className="new-map-dot" ref={newMapDotRef}>
              {renderMarker(newMapDot.marker)}
              <div>
                {newMapDot.text}
              </div>
            </div>
          }

          <AddMarkerPopup 
            addMarkerPopup={addMarkerPopup}
            setActiveButton={setActiveButton} 
            setNewMapDot={setNewMapDot}
            setAddMarkerPopup={setAddMarkerPopup}
          />

          <WeatherMarkers />

          <Markers newMapPoint={newMapPoint} setNewMapPoint={setNewMapPoint} />

          <ZoomControl 
            position="bottomright" 
            zoomInTitle="Приблизить"
            zoomOutTitle="Отдалить"
          />
        </Styled.Map>

        <Styled.GetGeolocation 
          onClick={() => onGetGeolocation(true)} 
          title="Определить местоположение"
        >
          <Icon icon="geolocation" iconSize={20} />
        </Styled.GetGeolocation>
      </Styled.AppMapContainer>
    </ErrorBoundary>
  );
}

export default AppMap
