import React, { useEffect, useCallback, useState, useRef } from 'react';
import { CircleMarker, Marker, Polygon, Polyline } from "react-leaflet";
import { divIcon } from "leaflet";

import { ADDZONEPOLYGON } from 'constants/typeButtons';
import CompleteCreateZone from '../CompleteCreateZone';
import { appendChildToMap } from '../tools/appendChild';
import EditPopup from './EditPopup';

const Area = ({
 positions,
 mouseMovePositions,
 calculatedArea,
 centerPolygon,
 setAreaPositions,
 setCalculatedArea,
 setCenterPolygon,
 createZonePolygon = false,
 activeButton,
 createCompleteZonePopup,
 setActiveButton,
 createInitialZone,
 setActiveCreateZone,
 setCreateCompletePopup,
 editedPopupIsHidden,
 map,
}) => {
  const [width, setWidth] = useState(2);
  const [zoneColor, setZoneColor] = useState({});

  // The reference belongs EditPopup
  const editPopupRef = useRef();

  useEffect(() => {
    if(createInitialZone.hasOwnProperty('color')) {
      setZoneColor({
        r: createInitialZone.color[0],
        g: createInitialZone.color[1],
        b: createInitialZone.color[2],
        a: +(createInitialZone.color[3] / 255).toFixed(2),
      });
    }
  }, [createInitialZone])

  const clearEffectsCallback = useCallback(() => {
    appendChildToMap(map._container, editPopupRef.current);
    setAreaPositions([])
    setCalculatedArea(0)
    setCenterPolygon(null);
    setActiveCreateZone(false);
    setCreateCompletePopup(false);
  }, [
    setAreaPositions,
    setCalculatedArea,
    setCenterPolygon,
    setActiveCreateZone,
    setCreateCompletePopup,
    map,
    editPopupRef
  ]);

  useEffect(() => {
    return () => {
      clearEffectsCallback()
    }
  }, [clearEffectsCallback]);

  const handleSetTogglePopup = () => {
    clearEffectsCallback();
    setActiveButton('');
  }

  return (
    <>
      {
        createCompleteZonePopup &&
        <CompleteCreateZone
          map={map}
          setActiveCreateZone={setActiveCreateZone}
          setCreateCompletePopup={setCreateCompletePopup}
          createInitialZone={createInitialZone}
          width={width}
          editPopupRef={editPopupRef}
          points={positions}
          zoneColor={zoneColor}
          handleSetTogglePopup={handleSetTogglePopup}
          editedPopupIsHidden={editedPopupIsHidden}
          className="map"
        />
      }
      {
        activeButton === ADDZONEPOLYGON &&
        positions[0] &&
        !createCompleteZonePopup &&
        <EditPopup
          handleSetTogglePopup={handleSetTogglePopup}
          width={width}
          setWidth={setWidth}
          color={zoneColor}
          editPopupRef={editPopupRef}
          setColor={setZoneColor}
          className="map"
          map={map}
          setActiveCreateZone={setActiveCreateZone}
          setCreateCompletePopup={setCreateCompletePopup}
          positions={positions}
          editedPopupIsHidden={editedPopupIsHidden}
        />
      }
      {
        positions[0] &&
        <Polygon
          positions={positions}
          color={!createZonePolygon ? "rgba(0, 0, 0, .5)" : `rgba(${zoneColor.r}, ${zoneColor.g}, ${zoneColor.b}, ${zoneColor.a})`}
          weight={width}
        >
          {
            positions.map((latlng, idx) =>
              <CircleMarker
                key={idx}
                center={latlng}
                radius={2}
                color={!createZonePolygon ? "rgba(0, 0, 0, .8)" : `rgba(${zoneColor.r}, ${zoneColor.g}, ${zoneColor.b}, ${zoneColor.a})`}
                className="circle-marker"
              />
            )
          }
          {
            calculatedArea &&
            positions[1] &&
            centerPolygon &&
            !createZonePolygon &&
            <Marker
              position={centerPolygon}
              icon={
                divIcon({
                  className: '',
                  iconSize: [50, 50],
                  html: `<div class="number-marker"><span>${calculatedArea}м<sup>2</sup></span></div>`
                })
              }
            />
          }
        </Polygon>
      }
      {
        mouseMovePositions &&
        positions[0] &&
        positions.length < 2 &&
        <Polyline
          positions={[positions[0], mouseMovePositions]}
          color={!createZonePolygon ? "rgba(0, 0, 0, .5)" : `rgba(${zoneColor.r}, ${zoneColor.g}, ${zoneColor.b}, ${zoneColor.a})`}
          weight={width}
        />
      }
    </>
  )
};

export default Area;
