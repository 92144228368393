import React, { useState, useEffect } from "react";
import "moment/locale/ru";
import {
  FormGroup,
  Checkbox,
  Alignment,
  NumericInput,
  InputGroup,
  HTMLSelect,
} from "@blueprintjs/core";

import { DateTimeInput, DateInput, TimeInput } from 'components/DateTimeInput'

import styles from "./ParamField.module.scss";

// @type input type date, number, float

const ParamField = ({ type, label, value, code, onChange, btnEdit, btnDelete, entityKey }) => {

  const [isDisabled, setIsDisabled] = useState(!value)

  const setValue = value => {
    onChange({ [code]: value })
  }

  useEffect(() => {
    if (isDisabled) {
      onChange({ [code]: '' })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled])

  useEffect(() => {
    if (!value) {
      setIsDisabled(true)
    }
    if (value) {
      setIsDisabled(false)
    }
  }, [value, entityKey])

  const textInput = () => (
    <InputGroup
      disabled={isDisabled}
      value={Boolean(value) ? value : ''}
      onChange={e => setValue(e.target.value)}
    />
  );

  const dateTimeInput = () => (
    <DateTimeInput disabled={isDisabled} value={value} onChange={e => setValue(e)} />
  )

  const dateInput = () => (
    <DateInput disabled={isDisabled} value={value} onChange={e => setValue(e)} />
  )

  const timeInput = () => (
    <TimeInput
      disabled={isDisabled}
      value={value}
      onChange={value => setValue(String(value))}
    />
  );

  const numericInput = () => (
    <NumericInput
      disabled={isDisabled}
      defaultValue={0}
      value={Boolean(value) ? value : ''}
      // в этом компоненте аргумент value с пустой строкой заменяется на 0, поэтому используем второй аргумент:
      onValueChange={(value, exactValue) => setValue(String(exactValue))}
    />
  );

  const select = () => (
    <HTMLSelect
      disabled={isDisabled}
      value={(value && value)}
      onChange={e => setValue(String(e.target.value))}
    >
      <option value={null} hidden>Выберите вариант</option>
      <option value={"True"}>Да</option>
      <option value={"False"}>Нет</option>
    </HTMLSelect>
  );

  const checkbox = () => (
    <Checkbox
      checked={(value && (value === 'True' || value === 'true')) ? true : false}
      onChange={e => setValue(String(e.target.checked))}
      label={label}
    />
  );

  const renderInput = type => {
    switch (type) {
      case 6:
        return dateTimeInput()
      case 7:
        return dateInput()
      case 8:
        return timeInput();
      case 1:
      case 2:
        return numericInput();
      case 3:
        return select();
      case 5:
        return checkbox()
      default:
        return textInput();
    }
  };

  return (
    <div className={styles["input-field"]}>
      <div style={{ display: 'flex', alignItems: 'top' }}>
        {btnEdit && btnEdit}
        {btnDelete && btnDelete}
        {type !== 5 && (
          <Checkbox
            label={label}
            alignIndicator={Alignment.LEFT}
            checked={!isDisabled}
            onChange={() => setIsDisabled(!isDisabled)}
          />
        )}
      </div>
      <FormGroup inline={true}>{renderInput(type)}</FormGroup>
    </div>
  );
};

export default ParamField;
