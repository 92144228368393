export function getDistance(from, to) {
  let lon1 = toRadian(from.lng),
    lat1 = toRadian(from.lat),
    lon2 = toRadian(to.lng),
    lat2 = toRadian(to.lat);

  let deltaLat = lat2 - lat1;
  let deltaLon = lon2 - lon1;

  let a = Math.pow(Math.sin(deltaLat/2), 2) + Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(deltaLon/2), 2);
  let c = 2 * Math.asin(Math.sqrt(a));
  let EARTH_RADIUS = 6371;

  return (c * EARTH_RADIUS * 1000).toFixed(0);
}

const toRadian = degree => degree * Math.PI / 180;
