import React from 'react';

import { Breadcrumbs as BPBreadcrumbs, Breadcrumb, Icon, Boundary } from '@blueprintjs/core';
import { Styled } from './Breadcrumbs.styled.js'

const Breadcrumbs = ({ breadcrumbs, onBreadcrumbClick }) => {
  const breadCrumbRenderer = ({ text, icon, id, ...restProps }) => {
    return (
      <Breadcrumb  onClick={() => onBreadcrumbClick(id)
      } {...restProps}>
        <Icon icon={icon} />
        {text}
      </Breadcrumb>
    );
  };

  const currentBreadcrumbRenderer	 = ({ text, icon, id, ...restProps }) => {
    return (
      <Breadcrumb current={true} {...restProps}>
        <Icon icon={icon} />
        {text}
      </Breadcrumb>
    );
  };

  return (
    <Styled.Breadcrumbs as={BPBreadcrumbs}
      breadcrumbRenderer={breadCrumbRenderer}
      currentBreadcrumbRenderer={currentBreadcrumbRenderer}
      collapseFrom={Boundary.END}
      items={breadcrumbs}
    />
  );
};

export default Breadcrumbs;
