import React from "react";
import { useQuery } from 'react-query'

import { getMobjectModels } from "api/MObjectModelsService";

import { MenuItem, Button } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import queryClient from 'tools/queryClient'

import { BlockSelect } from 'styled'
import { Styled } from './ModelsSelect.styled'

const ModelsSelect = ({ currentModel, onModelChange }) => {

  const { isLoading, data } = useQuery("MobileObjectModelsListForSelect", () => getMobjectModels(), {
    initialData: () => queryClient.getQueryData('MobileObjectModelsListForSelect')
  });

  const renderModel = (model, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <Styled.Item
        key={model.id}
        onClick={handleClick}
        query={model.name}
      >
        <img src={`${model.imageUrl}`} alt={model.name} />
        <p>{model.displayName}</p>
      </Styled.Item>
    );
  };

  const filterModels = (query, model) => {
    if (model.displayName || model.name) {
      if (!model.displayName) {
        return model.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
      } else {
        return (
          model.displayName.toLowerCase().indexOf(query.toLowerCase()) >= 0
        );
      }
    }
    return false;
  };

  return (
    <BlockSelect as={Select}
      id="modelSelect"
      items={data || []}
      filterable={true}
      itemRenderer={renderModel}
      itemPredicate={filterModels}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={item => onModelChange(item)}
      activeItem={currentModel}
      disabled={!data?.length}
    >
      <Button
        loading={isLoading}
        disabled={!data?.length}
        rightIcon="caret-down"
      >
        <img src={`${currentModel.imageUrl}`} alt={currentModel.name} /> {currentModel?.name || data?.[0]?.name}
      </Button>
    </BlockSelect>
  );
};

export default ModelsSelect;
