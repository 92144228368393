/* eslint-disable */
import { Toaster, Position, Intent } from "@blueprintjs/core";

export const AppToaster = Toaster.create({ position: Position.TOP });

const alreadyExists = message => {
  if(AppToaster.getToasts().find(item => item.message === message)) {
    return true;
  }

  return false;
}

export const showToaster = (message, intent) => AppToaster.show({ message, intent });

export const showToasterP = message => {
  if(alreadyExists(message)) {
    AppToaster.dismiss();
    return;
  } 

  showToaster(message, Intent.PRIMARY);
}

export const showToasterS = message => {
  if(alreadyExists(message)) {
    AppToaster.dismiss();
    return;
  } 
  
  showToaster(message, Intent.SUCCESS);
}

export const showToasterD = message => {
  if(alreadyExists(message)) {
    AppToaster.dismiss();
    return;
  }

  showToaster(message, Intent.DANGER);
}

export const showToasterW = message => {
  if(alreadyExists(message)) {
    AppToaster.dismiss();
    return;
  }
  
  showToaster(message, Intent.WARNING);
}
