import React, { useState, useMemo } from 'react';
import CheckboxTree from 'react-checkbox-tree';
import { Icon, Button, Intent } from '@blueprintjs/core';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import classes from './style.module.scss'

function getNodeIds(nodes) {
    let ids = [];

    nodes.forEach(({ value, children }) => {
        const childs = children ? getNodeIds(children) : []
        ids = [...ids, value, ...childs];
    });

    return ids;
}

export const Widget = ({ nodes, selectedItems = [], setSelectedItems = () => { }, onItemClick, hideChecks, isLoading, noCascade = true, checkModel='all', ...props }) => {
    const allNodeIds = useMemo(() => getNodeIds(nodes), [nodes])
    const [expanded, setExpanded] = useState(allNodeIds)

    const handleCheck = i => {
        setSelectedItems(i)
    }
    const toggleSelectAll = () => {
        if (selectedItems.length > 0) {
            setSelectedItems([])
        } else {
            setSelectedItems(allNodeIds)
        }
    }
    return (
        <div className={hideChecks && classes.noChecks}>
            {!hideChecks && <Button onClick={toggleSelectAll} icon='selection' intent={Intent.PRIMARY} style={{ marginBottom: 10 }}>Выбрать все</Button>}
            <CheckboxTree
                showCheckbox={false}
                nodes={nodes}
                checked={selectedItems}
                expanded={expanded}
                onCheck={handleCheck}
                onExpand={setExpanded}
                icons={icons}
                checkModel={checkModel}
                noCascade={noCascade}
                showNodeIcon={false}
                onClick={onItemClick}
                disabled={isLoading}
                {...props}
            />
        </div>
    );
}


const icons = {
    check: <Icon icon='tick' />,
    uncheck: <Icon icon='square' />,
    halfCheck: <Icon icon='stop' />,
    expandClose: <Icon icon='chevron-right' />,
    expandOpen: <Icon icon='chevron-down' />,
    expandAll: <Icon icon='plus' />,
    collapseAll: <Icon icon='minus' />,
    parentClose: <Icon icon='folder-close' />,
    parentOpen: <Icon icon='folder-open' />,
    leaf: <Icon icon='asterisk' />,
}