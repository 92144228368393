import React from 'react';
import { TileLayer } from "react-leaflet";

const TileLayers = ({
  activeTileLayer,
  tileLayers
}) => (
  <>
    {
      tileLayers.map(tileLayer => {
        if(tileLayer.title === activeTileLayer.title) {
          return (
            <TileLayer
              key={tileLayer.title}
              url={activeTileLayer.url}
              subdomains={activeTileLayer.subdomains}
              accessToken={activeTileLayer.accessToken}
            />
          )
        } else {
          return null
        }
      })
    }
  </>
);

export default TileLayers;
