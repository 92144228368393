import { makeAutoObservable } from "mobx"

class ObjectsObserverTabsStore {

    constructor() {
        makeAutoObservable(this)
    }

    activeTab = 'mobjects'

    setActiveTab = tab => {
        this.activeTab = tab
    }
}

export default new ObjectsObserverTabsStore()