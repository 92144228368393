import React, { useEffect, useState, useRef } from 'react';
import { Button, Popover, Icon } from "@blueprintjs/core";
import PropTypes from 'prop-types';

// Import components
import SelectTileLayer from '../SelectTileLayer';
import GroupButtons from './GroupButtons';
import FindTextInput from 'components/FindTextInput';

// Import constants
import {
  firstGroupButtons,
  secondGroupButtons,
  thirdGroupButtons,
} from 'constants/appMapControlButtons';

// Import api
import { getAddressByAddress } from 'api/geoDataService'

import { showToasterD } from 'tools/AppToaster';

// Import styles
import { Styled } from './AppMapControl.styled'

/**
 * AppMapControl component control panel for component AppMap
 * Input explains what you need to push in prop
 *
 * @prop activeTileLayer - Input: object = {} Active tileLayer for Map
 * @prop tileLayers - Input: object[] = [] All tileLayers which user can choose
 * @prop searchString - Input: string = '' A string for searching by street, region, etc...
 * @prop activeButton - Input: string = '' A string that explains which button is an active button by attribute
 * @prop handleSetActiveButton - Input: void = () => {} A function that change active button by attribute
 * @prop handleChangeSearchString - Input: void = e => e.target.value A function that change value of searchString
 * @prop handleSetActiveTileLayer - Input: void = () => {} A function that change activeTileLayer
 * @prop hideButtons - Input: string[] = [] An array of string where string equal attribute that hides the desired button
 *
 * @returns JSX - Layout of AppMapComponent
 */
const AppMapControl = ({
  mapRef,
  activeTileLayer,
  tileLayers,
  searchString,
  activeButton,
  handleSetActiveButton,
  handleChangeSearchString,
  handleSetActiveTileLayer,
  hideButtons,
  centeredMobileObject,
  onRemoveCenteredMobileObject,
  setAddress,
  setCenteredMobileObject,
}) => {
  // Responsive states
  // All of them are boolean handlers that change display to responsive
  const [responsiveState, setResponsiveState] = useState(false);
  const [inputDisplay, setInputDisplay] = useState(true);
  const [firstGroupButtonsDisplay, setFirstGroupButtonsDisplay] = useState(true);
  const [secondGroupButtonsDisplay, setSecondGroupButtonsDisplay] = useState(true);
  const [lastGroupButtonsDisplay, setLastGroupButtonsDisplay] = useState(true);
  const controlUnitsRef = useRef(null);

  const groupButtons = group => {
    // If there are button attributes in the hideButtons array, then hide them
    group = group.filter((button, idx) => button.attribute !== hideButtons[idx]);
    return (
      <GroupButtons
        buttons={group}
        activeButton={activeButton}
        hideButtons={hideButtons}
      />
    )
  }

  const handleClickOnButtons = e => {
    // Finding button by attribute data-button
    const parent = e.target.closest('[data-button]');
    if(parent) {
      // If activeButton equals parent, then delete activeButton like active and set nothing
      // Else set activeButton as parent
      if(activeButton === parent.dataset.button) {
        handleSetActiveButton('');
      } else {
        handleSetActiveButton(parent.dataset.button);
      }
    }
  }

  // todo change responsive
  const changeWidth = width => {
    if(width) {
      if(width <= 600) {
        setSecondGroupButtonsDisplay(false);
      }
      
      if(width <= 800) {
        setResponsiveState(true);
        setLastGroupButtonsDisplay(false);
        setInputDisplay(false);
      } else {
        setResponsiveState(false);
        setInputDisplay(true);
        setLastGroupButtonsDisplay(true);
        setSecondGroupButtonsDisplay(true);
        setFirstGroupButtonsDisplay(true);
      }
    }
  }

  const onGetAddressByAddress = async e => {
    if(e.key === 'Enter') {
      const mapBounds = mapRef.current.leafletElement.getBounds();

      const leftTopLocation = {
        lat: mapBounds.getNorth(),
        lng: mapBounds.getWest()
      }

      const rightBottomLocation = {
        lat: mapBounds.getSouth(),
        lng: mapBounds.getEast()
      }

      const address = await getAddressByAddress('', e.target.value, leftTopLocation, rightBottomLocation);
      if(address) {
        handleSetActiveButton('');
        setAddress(address);
      } else {
        showToasterD('Не удалось определить адрес!')
        setAddress(null);
      }
    }
  }


  useEffect(() => {
    const resizeMapControl = () => {
      const width = +controlUnitsRef.current.getBoundingClientRect().width;
      changeWidth(width);
    }

    if(controlUnitsRef.current) {
      resizeMapControl();
      window.addEventListener('resize', resizeMapControl)
    }
    
    return () => window.removeEventListener('resize', resizeMapControl)
  }, []);

  return (
    <Styled.AppMapControl>
      <Styled.AppMapControlUnits
        onClick={handleClickOnButtons}
        ref={controlUnitsRef}
      >
        {
          firstGroupButtonsDisplay &&
          <Styled.AppMapControlUnit>
            {groupButtons(firstGroupButtons)}
          </Styled.AppMapControlUnit>
        }
        <Styled.AppMapControlUnit>
          <SelectTileLayer
            activeTileLayer={activeTileLayer}
            handleSetActiveTileLayer={handleSetActiveTileLayer}
            tileLayers={tileLayers}
          />
        </Styled.AppMapControlUnit>
        {
          secondGroupButtonsDisplay &&
          <Styled.AppMapControlUnit>
            {groupButtons(secondGroupButtons)}
          </Styled.AppMapControlUnit>
        }
        {
          lastGroupButtonsDisplay &&
          <Styled.AppMapControlUnit>
            {groupButtons(thirdGroupButtons)}
          </Styled.AppMapControlUnit>
        }
        {
          centeredMobileObject?.mobileObject?.id &&
          <Styled.CenteredMobileObject title={centeredMobileObject.mobileObject.displayName}>
            <Icon className="icon" icon="locate" iconSize={14} />
            <Styled.CenteredMobileObjectName>
              {
                centeredMobileObject.mobileObject.displayName.length > 18 ? 
                `${centeredMobileObject.mobileObject.displayName.slice(0, 15)}...` : 
                centeredMobileObject.mobileObject.displayName
              }
            </Styled.CenteredMobileObjectName>
            <Button 
              title="Отключить центрирование" 
              className="hovered" 
              icon="cross" 
              onClick={() => {
                if(typeof onRemoveCenteredMobileObject === 'function') {
                  onRemoveCenteredMobileObject()
                }

                setCenteredMobileObject({})
              }}
            />
          </Styled.CenteredMobileObject>
        }
        {
          inputDisplay &&
          <Styled.AppMapControlUnit>
            <FindTextInput 
              value={searchString}
              onChange={handleChangeSearchString}
              onKeyDown={onGetAddressByAddress}
            />
          </Styled.AppMapControlUnit>
        }
        {
          responsiveState &&
          <Styled.ResponsiveMenuButton>
            <Popover>
              <Button icon="chevron-down"/>
              <Styled.AppMapControlUnits className="responsive">
                {
                  !firstGroupButtonsDisplay &&
                  <Styled.AppMapControlUnit>
                    {groupButtons(firstGroupButtons)}
                  </Styled.AppMapControlUnit>
                }
                {
                  !secondGroupButtonsDisplay &&
                  <Styled.AppMapControlUnit>
                    {groupButtons(secondGroupButtons)}
                  </Styled.AppMapControlUnit>
                }
                {
                  !lastGroupButtonsDisplay &&
                  <Styled.AppMapControlUnit>
                    {groupButtons(thirdGroupButtons)}
                  </Styled.AppMapControlUnit>
                }
                {
                  !inputDisplay &&
                  <Styled.AppMapControlUnit>
                    <FindTextInput 
                      value={searchString}
                      onChange={handleChangeSearchString}
                    />
                  </Styled.AppMapControlUnit>
                }
              </Styled.AppMapControlUnits>
            </Popover>
          </Styled.ResponsiveMenuButton>
        }
      </Styled.AppMapControlUnits>
    </Styled.AppMapControl>
  )
};

AppMapControl.propTypes = {
  activeTileLayer: PropTypes.object.isRequired,
  tileLayers: PropTypes.array.isRequired,
  searchString: PropTypes.string,
  activeButton: PropTypes.string,
  handleSetActiveButton: PropTypes.func,
  handleChangeSearchString: PropTypes.func,
  handleSetActiveTileLayer: PropTypes.func,
  hideButtons: PropTypes.array,
}

AppMapControl.defaultProps = {
  searchString: '',
  activeButton: '',
  handleSetActiveButton: () => {},
  handleChangeSearchString: () => {},
  handleSetActiveTileLayer: () => {},
  hideButtons: [],
}

export default AppMapControl;
