import React, { useState, useEffect } from 'react'
import { Checkbox, Collapse, FormGroup } from '@blueprintjs/core'
import { TimePicker, TimePrecision } from "@blueprintjs/datetime";

import { Flex } from 'styled'

const ShiftCollapse = ({ periodsSettings, onSettingsChange }) => {

    const [checkedMode, setCheckedMode] = useState(!!periodsSettings)
    const [workShiftDay, setWorkShiftDay] = useState(() => periodsSettings && periodsSettings.sm1 ? periodsSettings.sm1 : 28800)
    const [workShiftNight, setWorkShiftNight] = useState(() => periodsSettings && periodsSettings.sm2 ? periodsSettings.sm2 : 72000)

    useEffect(() => {
        onSettingsChange(checkedMode ? { sm1: workShiftDay, sm2: workShiftNight } : null)
    }, [checkedMode, workShiftDay, workShiftNight, onSettingsChange])

    return (
        <Flex column>
            <FormGroup
                inline={true}
                label="Смены: "
                labelFor="shifts"
            >
                <Checkbox
                    id="shifts"
                    checked={!!checkedMode}
                    label="Собственное деление на смены"
                    onChange={() => setCheckedMode(!checkedMode)}
                />
            </FormGroup>
            <Collapse keepChildrenMounted={true} isOpen={!!checkedMode}>
                <FormGroup
                    inline={true}
                    label="Смена 1: "
                    labelFor="shiftOne"
                >
                    <TimePicker
                        id="shiftOne"
                        precision={TimePrecision.MINUTE}
                        value={new Date((+workShiftDay - 25200) * 1000)}
                        onChange={newTime => { setWorkShiftDay(Date.parse(newTime) / 1000 + 25200) }}
                    />
                </FormGroup>
                <FormGroup
                    inline={true}
                    label="Смена 2: "
                    labelFor="shiftTwo"
                >
                    <TimePicker
                        id="shiftTwo"
                        precision={TimePrecision.MINUTE}
                        value={new Date((+workShiftNight - 25200) * 1000)}
                        onChange={newTime => { setWorkShiftNight(Date.parse(newTime) / 1000 + 25200) }}
                    />
                </FormGroup>
            </Collapse>
        </Flex>
    )
}

export default ShiftCollapse
