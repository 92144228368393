import { makeAutoObservable, runInAction } from "mobx"
import { stopSocketConnection } from 'tools/socketManager';

import history from "tools/history";
import { showToasterD, showToasterS } from "tools/AppToaster";
import { getUser, login, logout } from "api/appAPI";

export class UserStore {

    data = {}
    isLoading = true
    isAuthenticated = false
    authError = false

    constructor() {
        makeAutoObservable(this)
    }

    authLogin = async data => {
        runInAction(() => this.isLoading = true)
        try {
            await login(data)
        } catch {
            showToasterD("Ошибка авторизации, попробуйте еще раз");
        }
        runInAction(() => this.isLoading = false)
        this.authRequest()
    };

    authRequest = async () => {
        runInAction(() => this.isLoading = true)
        const res = await getUser()
        if (res.status === 200) {
            runInAction(() => {
                this.isAuthenticated = true
                this.data = res.data.result
                this.authError = false
            })
            history.push("/");
            showToasterS("Успешная авторизация");
        } else {
            runInAction(() => {
                this.isAuthenticated = false
                this.authError = res.statusCode
            })
        }
        runInAction(() => this.isLoading = false)
    };

    authLogout = async () => {
        logout(
            async () => {
                await stopSocketConnection();
                runInAction(() => {
                    this.data = {}
                    this.isLoading = false
                    this.isAuthenticated = false
                    this.authError = false
                })
                history.push("/login");
            },
            () => showToasterD('Не удалось выйти из системы!')
        );
    }
}

export default new UserStore()
