import React, { lazy } from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';

import { addPanel } from 'tools/initGoldenLayout';

const EnterprisesCatalog = lazy(() => import('pages/Catalogs/EnterprisesCatalog'))
const ZonesCatalog = lazy(() => import('pages/Catalogs/ZonesCatalog'))
const UsersCatalog = lazy(() => import('pages/Catalogs/UsersCatalog'))
const RolesCatalog = lazy(() => import('pages/Catalogs/RolesCatalog'))
const ModelsCatalog = lazy(() => import('pages/Catalogs/ModelsCatalog'))
const ModelTypesCatalog = lazy(() => import('pages/Catalogs/ModelTypesCatalog'))
const SensorTypesCatalog = lazy(() => import('pages/Catalogs/SensorTypesCatalog'))

const CatalogMenu = () => {
    return (
        <Menu>
            <MenuItem
                icon='wrench'
                text='Предприятия'
                onClick={() => addPanel('Предприятия', EnterprisesCatalog, false)}
            />
            <MenuItem
                icon='polygon-filter'
                text='Зоны'
                onClick={() => addPanel('Зоны', ZonesCatalog, false, {  })}
            />
            <MenuItem
                icon='people'
                text='Пользователи'
                onClick={() => addPanel('Справочник пользователей', UsersCatalog, true)}
            />
            <MenuItem
                icon='user'
                text='Роли пользователей'
                onClick={() => addPanel('Справочник ролей пользователей', RolesCatalog, true)}
            />
            <MenuItem
                icon='drive-time'
                text='Модели мобильных объектов'
                onClick={() => addPanel('Справочник моделей', ModelsCatalog, true)}
            />
            <MenuItem
                icon='unknown-vehicle'
                text='Типы моделей мобильных объектов'
                onClick={() => addPanel('Справочник типов моделей', ModelTypesCatalog, true)}
            />
            <MenuItem
                icon='properties'
                text='Типы датчиков'
                onClick={() => addPanel('Справочник типов датчиков', SensorTypesCatalog, true)}
            />
        </Menu>
    )
}

export default CatalogMenu;
