import { makeAutoObservable, runInAction } from "mobx"

import { prepareVtable, getMobjects } from "api/appAPI";
import { getZonesVirtualTableRange } from 'api/zonesService'
import MobileObject from 'pages/MobileObject';
import objectsObserverTabsStore from 'stores/objectsObserverTabsStore'

// tools
import { showToasterD } from 'tools/AppToaster';
import { addPanel } from 'tools/initGoldenLayout';

let lastRange = null;
class ObjectsObserverListStore {

    isLoading = false
    isErrorred = false
    breadCrumbs = [{ href: "#", icon: "home" }]
    dataLength = 0
    loadStep = 60
    list = []
    searchedObjectIndex = undefined
    isPrepared = false
    selectedObjectIds = []

    constructor(prepareVtable, getData, entityName) {
        makeAutoObservable(this)
        this.getData = getData
        this.prepareVTable = prepareVtable
        this.entityName = entityName
    }

    changeListItem = newItem => {
        
        const index = this.list.findIndex(i => i.objectId === newItem.id)
        this.list = [
            ...this.list.slice(0, index),
            {
                ...this.list[index],
                name: newItem.displayName
            },
            ...this.list.slice(index + 1)
        ]
    }

    setSelectedObjectIds = newIds => runInAction(() => this.selectedObjectIds = newIds)

    handleRowDblClick = ({ objectId, type, displayName }) => {
        if (type === 'g') {
            this.loadMObjects(objectId);
        }
        else this.showObject(objectId, type, displayName);
    };

    showInList = (groupId = null, searchedObjectId) => {
        this.dataRequest(0, this.loadStep, groupId, true, searchedObjectId)
        this.selectedObjectIds = [searchedObjectId]
        objectsObserverTabsStore.setActiveTab('mobjects')
    };

    showMobileObjectDock = (objectId, Component, name) => {
        if (!objectId) {
            showToasterD('Объект не найден')
            return
        }
        addPanel(name || 'Нет имени', Component, false, { objectId })
    };

    showObject = async (id, type, name) => {
        if (type !== 'o') return

        this.showMobileObjectDock(id, MobileObject, name);
    };

    dataRequest = async (counter, step = 16, parentId = "", shouldPrepare = false, searchedObjectId = undefined) => {
        let shouldPrep = shouldPrepare;

        const objects = this.list;
        const currentObject = objects.find((item) => item?.objectId === parentId);
        const breadcrumbs = this.breadCrumbs;
        const currentBreadcrumb = breadcrumbs.find((item) => item?.id === parentId);

        if (!parentId) {
            this.setBreadcrumb();
            shouldPrep = true;
        }

        if (parentId && parentId.toString().length > 0) {
            let newBreadcrumb;
            if (currentObject) {
                newBreadcrumb = {
                    text: `${currentObject.name}`,
                    icon: "folder-open",
                    id: `${parentId}`,
                };
            } else if (currentBreadcrumb) {
                newBreadcrumb = {
                    text: `${currentBreadcrumb.name}`,
                    icon: "folder-open",
                    id: `${parentId}`,
                };
            }
            this.setBreadcrumb(newBreadcrumb);
        }

        if (shouldPrep) {
            runInAction(() => this.isLoading = true)
            try {
                const res = await this.prepareVTable(parentId, searchedObjectId, this.entityName)
                runInAction(() => {
                    this.dataLength = res.count
                    this.searchedObjectIndex = res.rowIndex
                    this.list = Array.from({ length: res.count }, () => undefined)
                })
                if (res.total) {
                    this.setBreadcrumb()
                    res.total.breadcrumbs.map(item => this.setBreadcrumb({
                        text: item.name,
                        icon: "folder-open",
                        id: item.id,
                    }))
                }
            } catch {
                runInAction(() => {
                    this.isErrorred = true
                    this.isLoading = false
                })
            }
        }

        try {
            const { data: { result: { objects } } } = await this.getData(counter, step)
            if (shouldPrep) {
                runInAction(() => {
                    this.isLoading = false
                    this.isErrorred = false
                    this.list = [
                        ...this.list.slice(0, counter),
                        ...Object.keys(objects).map((id) => objects[id]),
                        ...this.list.slice(counter + step),
                    ]
                    this.isPrepared = true
                })
            } else {
                runInAction(() => {
                    this.isLoading = false
                    this.isErrorred = false
                    this.list = [...this.list, ...objects]
                })
            }
        } catch {
            runInAction(() => {
                this.isErrorred = true
                this.isLoading = false
            })
        }
    };

    loadMObjects = async (parentId = null) => {
        this.dataRequest(0, this.loadStep, parentId, true);
    }

    queueMObjectsRangeRequest = (from, to) => {

        if (!this.isPrepared) return;

        const _lastRange = {};
        lastRange = _lastRange;

        let resolve;
        const promise = new Promise(_resolve => {
            resolve = _resolve;
        });

        setTimeout(async () => {
            if (lastRange === _lastRange) {
                const { data: { result: { objects } } } = await this.getData(from, to)

                if (objects) {
                    for (let index = 0; index < objects.length; index++) {
                        runInAction(() => this.list[from + index] = objects[index])
                    }
                }
            }
            resolve();
        }, 200);

        return promise;
    }

    loadMoreRows = ({ startIndex, stopIndex }) => this.queueMObjectsRangeRequest(startIndex, stopIndex);

    setBreadcrumb = (newBreadcrumb) => {
        if (!newBreadcrumb) {
            this.clearBreadcrumbs()
            return
        }
        const currentBreadcrumbs = this.breadCrumbs;
        const breadcrumbIndex = currentBreadcrumbs
            .map((o) => o.id)
            .indexOf(newBreadcrumb.id);
        if (breadcrumbIndex < 0) {
            return this.addBreadcrumb(newBreadcrumb);
        } else {
            const newBreadcrumbs = currentBreadcrumbs.slice(0, breadcrumbIndex + 1);
            return this.removeBreadcrumb(newBreadcrumbs);
        }
    };

    // breadcrumbs
    handleBreadcrumbClick = (groupId = null) => {
        this.loadMObjects(groupId);
    };

    clearBreadcrumbs = () => {
        const newBreadcrumbs = [this.breadCrumbs[0]];
        this.removeBreadcrumb(newBreadcrumbs);
    };

    removeBreadcrumb = (breadcrumbs) => {
        runInAction(() => this.breadCrumbs = breadcrumbs)
    }

    addBreadcrumb = (breadcrumb) => {
        runInAction(() => this.breadCrumbs = [...this.breadCrumbs, breadcrumb])
    }
}

const mobileObjectsListStore = new ObjectsObserverListStore(prepareVtable, getMobjects, 'mobjects')
const zonesListStore = new ObjectsObserverListStore(prepareVtable, getZonesVirtualTableRange, 'zones')

export { mobileObjectsListStore, zonesListStore }

export default new ObjectsObserverListStore()