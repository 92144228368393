import React from 'react';
import { Button, Icon } from '@blueprintjs/core';

// Import styles
import { GroupButton } from './GroupButtons.styled'

const GroupButtons = ({
  buttons,
  activeButton
}) => (
  <GroupButton>
    {buttons.map(button => (
      <Button
        key={button.title}
        title={button.title}
        className={activeButton === button.attribute ? "active" : "hovered"}
        data-button={button.attribute}
      >
        <Icon icon={button.icon} iconSize={button.iconSize} />
      </Button>
    ))}
  </GroupButton>
);

export default GroupButtons;
