import React, { useEffect, useState } from 'react';
import { Intent, Alert } from "@blueprintjs/core";
import { observer } from 'mobx-react-lite'
import { useQuery, useMutation } from 'react-query'
import { getUser, putUser, patchUser, deleteUser, restoreUser } from 'api/usersService'
import { socketConnection } from 'tools/socketManager';
import { closePanel } from 'tools/initGoldenLayout'
import { ON_NAV_OBJECT_CHANGED, ON_NAV_OBJECT_REMOVED } from 'constants/socketManager';
import SocketStore from 'stores/socketStore'

import UserView from './UserView'

const userFields = '?fields=id,displayName,role,login,eMail,phone,authDate,enterprise,expireDate,deleted,description,attributes[*],userAccesses'

const User = observer(({ id, glContainer }) => {

    const [removedAlertOpen, setRemovedAlertOpen] = useState(false)

    const { data, isFetching, refetch } = useQuery(['users', id], () => getUser(id, userFields))

    const { mutateAsync: _putUser, isLoading: putUserFetching } = useMutation(body => putUser(id, body))
    const { mutateAsync: _patchUser, isLoading: patchUserFetching } = useMutation(body => patchUser(id, body))
    const { mutateAsync: _deleteUser, isLoading: deleteUserFetching } = useMutation(() => deleteUser(id))
    const { mutateAsync: _restoreUser, isLoading: restoreUserFetching } = useMutation(() => restoreUser(id))

    const closeTab = () => {
        closePanel(glContainer._config.id);
    }

    const updatedItem = SocketStore.getChannelData(ON_NAV_OBJECT_CHANGED, 'User');
    const removedItem = SocketStore.getChannelData(ON_NAV_OBJECT_REMOVED, 'User');

    useEffect(() => {
        if (!socketConnection) return

        SocketStore.addSocketChannel(ON_NAV_OBJECT_CHANGED, 'User', '')
        SocketStore.addSocketChannel(ON_NAV_OBJECT_REMOVED, 'User', '')

        return () => {
            SocketStore.removeSocketChannel(ON_NAV_OBJECT_CHANGED, 'User', '')
            SocketStore.removeSocketChannel(ON_NAV_OBJECT_REMOVED, 'User', '')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socketConnection]);

    useEffect(() => {
        if (removedItem?.id === id) {
            setRemovedAlertOpen(true)
        } else if (updatedItem?.id === id) {
            refetch()
        }
    }, [id, refetch, removedItem, updatedItem])

    return <>
        <UserView
            data={data}
            loading={isFetching || putUserFetching || patchUserFetching || deleteUserFetching || restoreUserFetching}
            onUpdate={_putUser}
            onPatch={_patchUser}
            onDelete={_deleteUser}
            onRestore={_restoreUser}
            refetch={refetch}
        />
        <Alert
            confirmButtonText='Ок'
            icon='trash'
            intent={Intent.DANGER}
            isOpen={removedAlertOpen}
            onConfirm={closeTab}
        >
            <p>Пользователь был удален</p>
        </Alert>
    </>
})

export default User;
