import axios from "axios";
import { showToasterD, showToasterS } from 'tools/AppToaster'

export const getUsers = async (fields = '') => {
    try {
        const res = await axios.get(`/api/users${fields}`);
        return res.data.result
    } catch (e) {
        console.error('Ошибка при получении списка пользователей: ', e)
        showToasterD('Ошибка при получении списка пользователей')
    }
}

export const getUser = async (id, fields = '') => {
    try {
        const res = await axios.get(`/api/users/${id}${fields}`);
        return res.data.result 
    } catch (e) {
        console.error('Ошибка при получении информации о пользователе: ', e)
        showToasterD('Ошибка при получении информации о пользователе')
    }
}

export const postUser = async body => {
    try {
        await axios.post('/api/users', body);
        showToasterS('Пользователь добавлен')
    } catch (e) {
        console.error('Ошибка при добавлении пользователя: ', e)
        showToasterD('Ошибка при добавлении пользователя')
    }
}

export const patchUser = async (id, body) => {
    try {
        await axios.patch(`/api/users/${id}`, body);
        showToasterS('Пользователь обновлён')
    } catch (e) {
        console.error('Ошибка при изменении пользователя: ', e)
        showToasterD('Ошибка при изменении пользователя')
    }
}

export const putUser = async (id, body) => {
    try {
        await axios.put(`/api/users/${id}`, body);
        showToasterS('Пользователь обновлён')
    } catch (e) {
        console.error('Ошибка при изменении пользователя: ', e)
        showToasterD('Ошибка при изменении пользователя')
    }
}

export const deleteUser = async id => {
    try {
        await axios.delete(`/api/users/${id}`);
        showToasterS('Пользователь удалён')
    } catch (e) {
        console.error('Ошибка при удалении пользователя: ', e)
        showToasterD('Ошибка при удалении пользователя')
    }
}

export const restoreUser = async id => {
    try {
        await axios.post(`/api/users/${id}/restore`);
        showToasterS('Пользователь восстановлен')
    } catch (e) {
        console.error('Ошибка при восстановлении пользователя: ', e)
        showToasterD('Ошибка при восстановлении пользователя')
    }
}