import axios from "axios";
import { showToasterD } from 'tools/AppToaster'

export const getRoles = async (fields = '') => {
    try {
        const res = await axios.get(`/api/user-roles${fields}`);
        return res.data.result
    } catch (e) {
        console.error('Ошибка при получении списка ролей: ', e)
        showToasterD('Ошибка при получении списка ролей')
    }
}

export const getRole = async id => {
    try {
        await axios.get(`/api/user-roles/${id}`);
    } catch (e) {
        console.error('Ошибка при получении информации о роли: ', e)
        showToasterD('Ошибка при получении информации о роли')
    }
    
}

export const postRole = async body => {
    try {
        await axios.post('/api/user-roles', body);
    } catch (e) {
        console.error('Ошибка при добавлении роли: ', e)
        showToasterD('Ошибка при добавлении роли')
    }
}

export const putRole = async (id, body) => {
    try {
        await axios.put(`/api/user-roles/${id}`, body);
    } catch (e) {
        console.error('Ошибка при обновлении роли: ', e)
        showToasterD('Ошибка при обновлении роли')
    }
}

export const deleteRole = async id => {
    try {
        await axios.delete(`/api/user-roles/${id}`);
    } catch (e) {
        console.error('Ошибка при удалении роли: ', e)
        showToasterD('Ошибка при удалении роли')
    }
}
