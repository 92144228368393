import React, { lazy } from 'react';
import { Menu, MenuItem } from '@blueprintjs/core';

import { addPanel } from 'tools/initGoldenLayout';

const Report = lazy(() => import('pages/Report'))

const ReportsMenu = ({ reports }) => (
  <Menu>
    {reports.sort((a, b) => a.category > b.category ? 1 : a.category < b.category ? -1 : 0).map(report => (
      <MenuItem
        icon='clipboard'
        text={report.category}
        key={report.category}
      >
        {report.items.sort((a, b) => a.displayName > b.displayName ? 1 : a.displayName < b.displayName ? -1 : 0).map(item => (
          <MenuItem
            icon='clipboard'
            key={item.id}
            text={item.displayName}
            onClick={() => addPanel(item.displayName, Report, false, { reportId: item.id })}
          />
        ))}
      </MenuItem>
    ))}
  </Menu>
)

export default ReportsMenu
