import moment from 'moment';

import dateFormatString from 'constants/locales';

export const dateToISOString = date => {
  date = moment(date);
  return `${date.format('YYYY')}-${date.format('MM')}-${date.format('DD')}T${date.format('HH')}:${date.format('mm')}:${date.format('ss')}Z`;
};

export const dateToUTCFormat = date => moment.utc(moment(date).utcOffset(date)).valueOf();

export const formatDate = date => moment.utc(moment(date).utcOffset(date)).format('DD.MM.YYYY HH:mm:ss');

export const configureDateInputProps = (value, onChange, timePrecision, className) => ({
  dayPickerProps: {
    locale: 'ru',
    months: dateFormatString.months,
    weekdaysLong: dateFormatString.weekDaysLong,
    weekdaysShort: dateFormatString.weekDaysShort,
    firstDayOfWeek: 1
  },
  showActionsBar: true,
  id: 'mountDate',
  name: 'mountDate',
  todayButtonText: 'Текущая дата',
  clearButtonText: 'Очистить',
  timePrecision,
  className,
  value,
  onChange
});

export const dateIsValid = date => moment(date).get('year') === 1 ? false : true;

export const setOfflineNavigation = object => {
  const difference = moment(moment().toISOString()).diff(moment(object.dateTime));
  const duration = moment.duration(difference);
  
  if(duration.hours() >= 1 || (duration.hours() === 0 && duration.minutes() >= 15)) {
    return true;
  } 
    
  return false;
};

export const offset = new Date().getTimezoneOffset() * 60000;

export const getISOStringTimezoneDate = date => new Date((new Date(date) - offset)).toISOString().slice(0, -5);