import styled from 'styled-components'

const Indent = styled.div`
    ${props => props.marginX && `margin-left: ${props.marginX}px; margin-right: ${props.marginX}px`};
    ${props => props.marginY && `margin-top: ${props.marginY}px; margin-bottom: ${props.marginY}px`};
    ${props => props.marginTop && `margin-top: ${props.marginTop}px`};
    ${props => props.marginBottom && `margin-bottom: ${props.marginBottom}px`};
    ${props => props.marginLeft && `margin-left: ${props.marginLeft}px`};
    ${props => props.marginRight && `margin-right: ${props.marginRight}px`};
    
    ${props => props.paddingX && `padding-left: ${props.paddingX}px; padding-right: ${props.paddingX}px`};
    ${props => props.paddingY && `padding-top: ${props.paddingY}px; padding-bottom: ${props.paddingY}px`};
    ${props => props.paddingTop && `padding-top: ${props.paddingTop}px`};
    ${props => props.paddingBottom && `padding-bottom: ${props.paddingBottom}px`};
    ${props => props.paddingLeft && `padding-left: ${props.paddingLeft}px`};
    ${props => props.paddingRight && `padding-right: ${props.paddingRight}px`};
`;

export default Indent