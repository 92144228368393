import { makeAutoObservable } from 'mobx';

class AppStore {
  isFullScreen = false
  isLeftPanelActive = true

  constructor() {
    makeAutoObservable(this);
  }

  updateFullScreen = isFullScreen => {
    this.isFullScreen = isFullScreen;
  }

  updateLeftPanelActive = () => {
    this.isLeftPanelActive = !this.isLeftPanelActive
  }
}

export default new AppStore()
