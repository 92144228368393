import React, { useMemo, useCallback, useEffect } from "react";
import { observer } from 'mobx-react-lite'
import { MenuItem, Button, Spinner, Icon } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";

import sensorTypesStore from 'stores/sensorTypesStore'


// searches for an element that has the specified id, or returns the first element if no id is specified
function findActiveItemById(items, id) {
  if (id === null) return items[0];
  return items?.find(item => item.id === id);
}

function renderSensorTypes(sensorType, { handleClick, modifiers }) {
  if (!modifiers.matchesPredicate) return null;

  return (
    <MenuItem key={sensorType.id}
      active={modifiers.active}
      onClick={handleClick}
      text={sensorType.displayName}
    />
  );
};

function filterSensorTypes(query, sensorType) {
  const _query = query.toLowerCase();
  return sensorType.lowerCaseName.includes(_query);
};

function SensorTypesSelectView({ value, onChange, fetchSensorTypes, allSensorTypes, isFetched, isLoading, error }) {

  useEffect(() => {
    if (isFetched || isLoading) return
    fetchSensorTypes()
  })

  const activeItem = useMemo(() => findActiveItemById(allSensorTypes, value), [allSensorTypes, value]);

  const onItemSelect = useCallback(item => onChange && onChange(item.id), [onChange]);

  return (
    <Select className="select"
      filterable={true}
      itemRenderer={renderSensorTypes}
      itemPredicate={filterSensorTypes}
      onItemSelect={onItemSelect}
      items={allSensorTypes}
      activeItem={activeItem}
    >
      <Button disabled={Boolean(isLoading || error)} rightIcon="caret-down">

        {isLoading && <div className="spinner-container">
          <Spinner size={20} /> идет загрузка
        </div>}

        {error && <div className="error-container">
          <Icon icon="error" /> ошибка
        </div>}

        {activeItem?.displayName}

      </Button>
    </Select>
  );
};

const SensorTypesSelect = observer(props => {
  return (
    <SensorTypesSelectView
      fetchSensorTypes={sensorTypesStore.fetchSensorTypes}
      allSensorTypes={sensorTypesStore.allSensorTypes}
      isFetched={sensorTypesStore.isFetched}
      isLoading={sensorTypesStore.isLoading}
      error={sensorTypesStore.error}
      {...props}
    />
  )
})

export default SensorTypesSelect;
