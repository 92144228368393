import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Polyline, CircleMarker, Marker } from 'react-leaflet'
import { divIcon } from "leaflet";

// Import tools
import { appendChildToMap } from "../tools/appendChild";

// Import components
import EditPopup from './EditPopup';
import CompleteCreateZone from "../CompleteCreateZone";

// Import constants
import { ADDZONECORRIDOR } from "constants/typeButtons";

const Ruler = ({
  positions,
  distances,
  setRulerPositions,
  setDistances,
  setMouseMovePositions,
  createZoneCorridor = false,
  map,
  setActiveCreateZone,
  setActiveButton,
  setCreateCompletePopup,
  createCompleteZonePopup,
  activeButton,
  createInitialZone,
  editedPopupIsHidden,
}) => {
  const [width, setWidth] = useState(2);
  const [zoneColor, setZoneColor] = useState({});

  // The reference belongs EditPopup
  const editPopupRef = useRef();

  useEffect(() => {
    if(createInitialZone.hasOwnProperty('color')) {
      setZoneColor({
        r: createInitialZone.color[0],
        g: createInitialZone.color[1],
        b: createInitialZone.color[2],
        a: +(createInitialZone.color[3] / 255).toFixed(2),
      });
    }
  }, [createInitialZone])

  const clearEffectsCallback = useCallback(() => {
    appendChildToMap(map._container, editPopupRef.current);
    setRulerPositions([]);
    setDistances([0]);
    setMouseMovePositions({});
    setActiveCreateZone(false);
    setCreateCompletePopup(false);
  }, [
    setRulerPositions,
    setDistances,
    setMouseMovePositions,
    setActiveCreateZone,
    setCreateCompletePopup,
    map,
    editPopupRef
  ]);

  useEffect(() => {
    return () => {
      clearEffectsCallback()
    }
  }, [clearEffectsCallback]);

  const handleSetTogglePopup = () => {
    clearEffectsCallback();
    setActiveButton('');
  }

  return (
    <>
      {
        createCompleteZonePopup &&
        <CompleteCreateZone
          map={map}
          setActiveCreateZone={setActiveCreateZone}
          setCreateCompletePopup={setCreateCompletePopup}
          createInitialZone={createInitialZone}
          width={width}
          editPopupRef={editPopupRef}
          points={positions}
          zoneColor={zoneColor}
          handleSetTogglePopup={handleSetTogglePopup}
          editedPopupIsHidden={editedPopupIsHidden}
          className="map"
        />
      }
      {
        activeButton === ADDZONECORRIDOR &&
        positions[0] &&
        !createCompleteZonePopup &&
        <EditPopup
          handleSetTogglePopup={handleSetTogglePopup}
          color={zoneColor}
          setColor={setZoneColor}
          width={width}
          setWidth={setWidth}
          editPopupRef={editPopupRef}
          className="map"
          map={map}
          setActiveCreateZone={setActiveCreateZone}
          setCreateCompletePopup={setCreateCompletePopup}
          positions={positions}
          editedPopupIsHidden={editedPopupIsHidden}
        />
      }
      {
        positions.map((latlng, idx) => {
          console.log(distances)
          const text = idx === 0 ? 'Старт' : 
            String(distances[idx]).length > 3 ? 
            `${(distances[idx] / 1000).toFixed(2)} км` : 
            `${distances[idx]} м`

          return (
            <div key={idx}>
                <CircleMarker
                  center={latlng}
                  radius={2}
                  fillOpacity={1}
                  color="red"
                  pane="markerPane"
                  className="circle-marker"
                  onmouseover={() => {

                  }}
                />
              {
                distances.length > 1 &&
                !createZoneCorridor &&
                <Marker
                  position={latlng}
                  icon={
                    divIcon({
                      className: '',
                      iconSize: [80, 50],
                      html: `<div class="number-marker"><span>${text}</span></div>`
                    })
                  }
                />
              }
            </div>
          )
        })
      }
      {
        positions.length > 1 &&
        <Polyline
          positions={positions}
          color={!createZoneCorridor ? "#000" : `rgba(${zoneColor.r}, ${zoneColor.g}, ${zoneColor.b}, ${zoneColor.a})`}
          weight={width}
        />
      }
    </>
  )
};

export default Ruler;
