import React, { Component } from 'react';
import {
  MenuItem,
  Button,
  Label,
  NumericInput,
  FormGroup
} from '@blueprintjs/core';
import { Select } from '@blueprintjs/select';
import { TimePicker, TimePrecision } from '@blueprintjs/datetime';

const dummyPanels = [
  {
    id: 1,
    name: 'speed',
    text: 'Скорости'
  },
  {
    id: 2,
    name: 'controller',
    text: 'Датчику'
  }
];

export default class HaltEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      panels: dummyPanels,
      activePanel: {}
    };

    this._handleSelectClick = this._handleSelectClick.bind(this);
  }

  componentDidMount() {
    this.setState({ panels: dummyPanels, activePanel: dummyPanels[0] });
  }

  _handleSelectClick(activePanel) {
    this.setState({ activePanel });
  }

  renderModel(panel, { handleClick }) {
    return (
      <MenuItem
        key={panel.id}
        label={panel.text}
        onClick={handleClick}
        shouldDismissPopover={true}
      />
    );
  }

  render() {
    const { panels, activePanel } = this.state;
    return (
      <>
        <Label className='bp3-inline'>
          Считать стоянки по
          <Select
            disabled={!this.props.contentDisabled}
            id='panelSelect'
            items={panels}
            filterable={false}
            itemRenderer={this.renderModel}
            noResults={<MenuItem disabled={true} text='No results.' />}
            onItemSelect={this._handleSelectClick}
          >
            <Button
              text={activePanel.text ? activePanel.text : panels[0].text}
              rightIcon='caret-down'
            />
          </Select>
        </Label>
        <FormGroup
          label='Скорость для перехода на стоянку, км\ч'
          labelFor='toStop'
          inline={true}
          helperText='Укажите значение от 1 до 1000'
        >
          <NumericInput
            disabled={!this.props.contentDisabled}
            id='toStop'
            buttonPosition='none'
            min={0}
            max={10}
            placeholder='Введите число'
          ></NumericInput>
        </FormGroup>
        <FormGroup
          label='Скорость для выхода из стоянки, км\ч'
          labelFor='toMove'
          inline={true}
          helperText='Укажите значение от 1 до 1000'
        >
          <NumericInput
            disabled={!this.props.contentDisabled}
            id='toMove'
            buttonPosition='none'
            min={1}
            max={10}
            placeholder='Введите число'
          ></NumericInput>
        </FormGroup>
        <FormGroup
          label='Расстояние для выхода из стоянки, м'
          labelFor='toMoveDist'
          inline={true}
          helperText='Укажите значение от 1 до 1000'
        >
          <NumericInput
            disabled={!this.props.contentDisabled}
            id='toMoveDist'
            buttonPosition='none'
            min={1}
            max={100}
            placeholder='Введите число'
          ></NumericInput>
        </FormGroup>
        <FormGroup label='Минимальное время движения' inline={true}>
          <TimePicker
            disabled={!this.props.contentDisabled}
            precision={TimePrecision.SECOND}
          />
        </FormGroup>
      </>
    );
  }
}
